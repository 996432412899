/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { CustomerStatus } from './CustomerStatus';
import {
    CustomerStatusFromJSON,
    CustomerStatusFromJSONTyped,
    CustomerStatusToJSON,
} from './CustomerStatus';

/**
 * 
 * @export
 * @interface CustomerListDto
 */
export interface CustomerListDto {
    /**
     * Id
     * @type {number}
     * @memberof CustomerListDto
     */
    id?: number;
    /**
     * Tenant Id
     * @type {number}
     * @memberof CustomerListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerListDto
     */
    kokpitCustomerId?: number | null;
    /**
     * Kod
     * @type {string}
     * @memberof CustomerListDto
     */
    code?: string | null;
    /**
     * İsim
     * @type {string}
     * @memberof CustomerListDto
     */
    name?: string | null;
    /**
     * Blokeli
     * @type {boolean}
     * @memberof CustomerListDto
     */
    isBlocked?: boolean | null;
    /**
     * İletişim İsmi
     * @type {string}
     * @memberof CustomerListDto
     */
    contactName?: string | null;
    /**
     * Telefon No
     * @type {string}
     * @memberof CustomerListDto
     */
    phone?: string | null;
    /**
     * Vergi İsmi
     * @type {string}
     * @memberof CustomerListDto
     */
    taxName?: string | null;
    /**
     * Vergi Numarası
     * @type {string}
     * @memberof CustomerListDto
     */
    taxNumber?: string | null;
    /**
     * Faks
     * @type {string}
     * @memberof CustomerListDto
     */
    fax?: string | null;
    /**
     * E-posta
     * @type {string}
     * @memberof CustomerListDto
     */
    email?: string | null;
    /**
     * Adres
     * @type {string}
     * @memberof CustomerListDto
     */
    address?: string | null;
    /**
     * Şehir
     * @type {string}
     * @memberof CustomerListDto
     */
    city?: string | null;
    /**
     * Ülke
     * @type {string}
     * @memberof CustomerListDto
     */
    country?: string | null;
    /**
     * Borç
     * @type {number}
     * @memberof CustomerListDto
     */
    debt?: number | null;
    /**
     * 
     * @type {CustomerStatus}
     * @memberof CustomerListDto
     */
    status?: CustomerStatus;
    /**
     * Not
     * @type {string}
     * @memberof CustomerListDto
     */
    note?: string | null;
    /**
     * 2. Email
     * @type {string}
     * @memberof CustomerListDto
     */
    email2?: string | null;
    /**
     * Gsm No
     * @type {string}
     * @memberof CustomerListDto
     */
    msisdn?: string | null;
    /**
     * Logo
     * @type {string}
     * @memberof CustomerListDto
     */
    logo?: string | null;
    /**
     * Ortam Adı
     * @type {string}
     * @memberof CustomerListDto
     */
    kokpitEnvironmentName?: string | null;
    /**
     * Ortam Id
     * @type {number}
     * @memberof CustomerListDto
     */
    kokpitEnvironmentId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerListDto
     */
    isPrivate?: boolean;
    /**
     * Müşteri Kanalı Id
     * @type {number}
     * @memberof CustomerListDto
     */
    customerChannelId?: number | null;
    /**
     * Müşteri Kanalı Adı
     * @type {string}
     * @memberof CustomerListDto
     */
    customerChannelName?: string | null;
    /**
     * Ekleyenin Tam İsmi
     * @type {string}
     * @memberof CustomerListDto
     */
    createdByFullName?: string | null;
    /**
     * Güncelleyenin Tam İsmi
     * @type {string}
     * @memberof CustomerListDto
     */
    modifiedByFullName?: string | null;
    /**
     * Eklenme Tarihi
     * @type {Date}
     * @memberof CustomerListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * Güncelleme Tarihi
     * @type {Date}
     * @memberof CustomerListDto
     */
    modifiedAt?: dayjs.Dayjs;
}

/**
 * Check if a given object implements the CustomerListDto interface.
 */
export function instanceOfCustomerListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerListDtoFromJSON(json: any): CustomerListDto {
    return CustomerListDtoFromJSONTyped(json, false);
}

export function CustomerListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'kokpitCustomerId': !exists(json, 'kokpitCustomerId') ? undefined : json['kokpitCustomerId'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isBlocked': !exists(json, 'isBlocked') ? undefined : json['isBlocked'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'taxName': !exists(json, 'taxName') ? undefined : json['taxName'],
        'taxNumber': !exists(json, 'taxNumber') ? undefined : json['taxNumber'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'debt': !exists(json, 'debt') ? undefined : json['debt'],
        'status': !exists(json, 'status') ? undefined : CustomerStatusFromJSON(json['status']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'kokpitEnvironmentName': !exists(json, 'kokpitEnvironmentName') ? undefined : json['kokpitEnvironmentName'],
        'kokpitEnvironmentId': !exists(json, 'kokpitEnvironmentId') ? undefined : json['kokpitEnvironmentId'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'customerChannelId': !exists(json, 'customerChannelId') ? undefined : json['customerChannelId'],
        'customerChannelName': !exists(json, 'customerChannelName') ? undefined : json['customerChannelName'],
        'createdByFullName': !exists(json, 'createdByFullName') ? undefined : json['createdByFullName'],
        'modifiedByFullName': !exists(json, 'modifiedByFullName') ? undefined : json['modifiedByFullName'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

    };
}

export function CustomerListDtoToJSON(value?: CustomerListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'kokpitCustomerId': value.kokpitCustomerId,
        'code': value.code,
        'name': value.name,
        'isBlocked': value.isBlocked,
        'contactName': value.contactName,
        'phone': value.phone,
        'taxName': value.taxName,
        'taxNumber': value.taxNumber,
        'fax': value.fax,
        'email': value.email,
        'address': value.address,
        'city': value.city,
        'country': value.country,
        'debt': value.debt,
        'status': CustomerStatusToJSON(value.status),
        'note': value.note,
        'email2': value.email2,
        'msisdn': value.msisdn,
        'logo': value.logo,
        'kokpitEnvironmentName': value.kokpitEnvironmentName,
        'kokpitEnvironmentId': value.kokpitEnvironmentId,
        'isPrivate': value.isPrivate,
        'customerChannelId': value.customerChannelId,
        'customerChannelName': value.customerChannelName,
        'createdByFullName': value.createdByFullName,
        'modifiedByFullName': value.modifiedByFullName,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
    };
}

