/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { UserNotificationState } from './UserNotificationState';
import {
    UserNotificationStateFromJSON,
    UserNotificationStateFromJSONTyped,
    UserNotificationStateToJSON,
} from './UserNotificationState';

/**
 * 
 * @export
 * @interface UserNotificationListDto
 */
export interface UserNotificationListDto {
    /**
     * 
     * @type {number}
     * @memberof UserNotificationListDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationListDto
     */
    userFullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationListDto
     */
    userImg?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationListDto
     */
    id?: string;
    /**
     * Tenant Id.
     * @type {number}
     * @memberof UserNotificationListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {UserNotificationState}
     * @memberof UserNotificationListDto
     */
    state?: UserNotificationState;
    /**
     * 
     * @type {Date}
     * @memberof UserNotificationListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationListDto
     */
    notificationNotificationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationListDto
     */
    notificationDataObjTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationListDto
     */
    notificationDataObjMessage?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserNotificationListDto
     */
    targetNotifiersList?: Array<string> | null;
}

/**
 * Check if a given object implements the UserNotificationListDto interface.
 */
export function instanceOfUserNotificationListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserNotificationListDtoFromJSON(json: any): UserNotificationListDto {
    return UserNotificationListDtoFromJSONTyped(json, false);
}

export function UserNotificationListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userFullName': !exists(json, 'userFullName') ? undefined : json['userFullName'],
        'userImg': !exists(json, 'userImg') ? undefined : json['userImg'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'state': !exists(json, 'state') ? undefined : UserNotificationStateFromJSON(json['state']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'notificationNotificationName': !exists(json, 'notificationNotificationName') ? undefined : json['notificationNotificationName'],
        'notificationDataObjTitle': !exists(json, 'notificationDataObjTitle') ? undefined : json['notificationDataObjTitle'],
        'notificationDataObjMessage': !exists(json, 'notificationDataObjMessage') ? undefined : json['notificationDataObjMessage'],
        'targetNotifiersList': !exists(json, 'targetNotifiersList') ? undefined : json['targetNotifiersList'],
    };
}

export function UserNotificationListDtoToJSON(value?: UserNotificationListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'userFullName': value.userFullName,
        'userImg': value.userImg,
        'id': value.id,
        'tenantId': value.tenantId,
        'state': UserNotificationStateToJSON(value.state),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'notificationNotificationName': value.notificationNotificationName,
        'notificationDataObjTitle': value.notificationDataObjTitle,
        'notificationDataObjMessage': value.notificationDataObjMessage,
        'targetNotifiersList': value.targetNotifiersList,
    };
}

