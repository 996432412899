/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { SmsProviderEnum } from './SmsProviderEnum';
import {
    SmsProviderEnumFromJSON,
    SmsProviderEnumFromJSONTyped,
    SmsProviderEnumToJSON,
} from './SmsProviderEnum';

/**
 * 
 * @export
 * @interface SmsProviderListDto
 */
export interface SmsProviderListDto {
    /**
     * 
     * @type {number}
     * @memberof SmsProviderListDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof SmsProviderListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * 
     * @type {Date}
     * @memberof SmsProviderListDto
     */
    modifiedAt?: dayjs.Dayjs;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderListDto
     */
    creatorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderListDto
     */
    updaterName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SmsProviderListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof SmsProviderListDto
     */
    createdById?: number;
    /**
     * 
     * @type {number}
     * @memberof SmsProviderListDto
     */
    modifiedById?: number;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderListDto
     */
    userCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderListDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderListDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderListDto
     */
    origin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderListDto
     */
    customerCode?: string | null;
    /**
     * 
     * @type {SmsProviderEnum}
     * @memberof SmsProviderListDto
     */
    provider?: SmsProviderEnum;
}

/**
 * Check if a given object implements the SmsProviderListDto interface.
 */
export function instanceOfSmsProviderListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SmsProviderListDtoFromJSON(json: any): SmsProviderListDto {
    return SmsProviderListDtoFromJSONTyped(json, false);
}

export function SmsProviderListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsProviderListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

        'creatorName': !exists(json, 'creatorName') ? undefined : json['creatorName'],
        'updaterName': !exists(json, 'updaterName') ? undefined : json['updaterName'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'modifiedById': !exists(json, 'modifiedById') ? undefined : json['modifiedById'],
        'userCode': !exists(json, 'userCode') ? undefined : json['userCode'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'customerCode': !exists(json, 'customerCode') ? undefined : json['customerCode'],
        'provider': !exists(json, 'provider') ? undefined : SmsProviderEnumFromJSON(json['provider']),
    };
}

export function SmsProviderListDtoToJSON(value?: SmsProviderListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'creatorName': value.creatorName,
        'updaterName': value.updaterName,
        'tenantId': value.tenantId,
        'createdById': value.createdById,
        'modifiedById': value.modifiedById,
        'userCode': value.userCode,
        'userName': value.userName,
        'password': value.password,
        'origin': value.origin,
        'customerCode': value.customerCode,
        'provider': SmsProviderEnumToJSON(value.provider),
    };
}

