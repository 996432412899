import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
    CustomerListDto,
    CustomerStatusCountDto,
    GetCustomerNamesRequest,
    GetCustomerRepresentativesRequest,
    GetCustomerRequest,
    GetCustomersRequest,
    GetCustomerStatusCountRequest,
    NameDTO,
    UnassignRepresentativeRequest,
    useCustomerApi,
    UserListDto,
} from "api"
import { StoreUseQueryOptions } from "types"

export const customerKeys = {
    all: ["customer"] as const,
    lists: () => [...customerKeys.all, "list"] as const,
    list: (param: GetCustomersRequest) => [...customerKeys.lists(), param] as const,
    details: () => [...customerKeys.all, "detail"] as const,
    detail: (param: GetCustomerRequest) => [...customerKeys.details(), param] as const,

    namelists: () => [...customerKeys.all, "namelist"] as const,
    namelist: (param: GetCustomerNamesRequest) => [...customerKeys.namelists(), param] as const,

    customerStatusCounts: () => [...customerKeys.all, "customerStatusCounts"] as const,
    customerStatusCount: (param: GetCustomerStatusCountRequest) =>
        [...customerKeys.customerStatusCounts(), param] as const,

    representativeLists: () => [...customerKeys.all, "representatives"] as const,
    representativeList: (param: GetCustomerRepresentativesRequest) =>
        [...customerKeys.representativeLists(), param] as const,
}

export const useCustomerNameList = (param: GetCustomerNamesRequest, options?: StoreUseQueryOptions<NameDTO[]>) => {
    const api = useCustomerApi()

    return useQuery({
        queryKey: customerKeys.namelist(param),
        queryFn: () => api.getCustomerNames(param),
        ...options,
    })
}

export const useCustomer = (
    id: number,
    options: StoreUseQueryOptions<CustomerListDto> = {
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const api = useCustomerApi()

    const param: GetCustomerRequest = { id }
    return useQuery({
        queryKey: customerKeys.detail(param),
        queryFn: () => api.getCustomer(param),
        ...options,
    })
}
export const useCustomerList = (param: GetCustomersRequest, options?: StoreUseQueryOptions<CustomerListDto[]>) => {
    const api = useCustomerApi()

    return useQuery({
        queryKey: customerKeys.list(param),
        queryFn: () => api.getCustomers(param),
        ...options,
    })
}

export const useAddCustomerMutation = () => {
    const api = useCustomerApi()
    const queryClient = useQueryClient()

    return useMutation<any, any, any, any>({
        mutationFn: (d) => api.postCustomer({ customerDto: d }),

        onSettled: (_data, error, _variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: customerKeys.lists() })
            }
        },
    })
}

export const useUpdateCustomerMutation = () => {
    const api = useCustomerApi()
    const queryClient = useQueryClient()

    return useMutation<any, any, any, any>({
        mutationFn: (d) => api.putCustomer({ id: d.id!, customerUpdateDto: d }),

        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: customerKeys.detail({ id: variables.id! }) })
                queryClient.invalidateQueries({ queryKey: customerKeys.lists() })
            }
        },
    })
}

export const useCustomerStatusCount = (
    param: GetCustomerStatusCountRequest,
    options: StoreUseQueryOptions<CustomerStatusCountDto> = {
        initialData: { active: 0, passive: 0, potential: 0 },
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const api = useCustomerApi()

    return useQuery({
        queryKey: customerKeys.customerStatusCount(param),
        queryFn: () => api.getCustomerStatusCount(param),
        ...options,
    })
}

export const useAssignRepresentativesMutation = () => {
    const api = useCustomerApi()
    const queryClient = useQueryClient()

    return useMutation<any, any, any, any>({
        mutationFn: (d) => api.assignRepresentatives(d),

        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: customerKeys.representativeLists() })
            }
        },
    })
}

export const useUnassignRepresentativesMutation = () => {
    const api = useCustomerApi()
    const queryClient = useQueryClient()

    return useMutation<any, any, any, any>({
        mutationFn: (d: UnassignRepresentativeRequest) => api.unassignRepresentative(d),

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: customerKeys.representativeLists() })
        },
    })
}

export const useCustomerRepresetativeList = (
    param: GetCustomerRepresentativesRequest,
    options?: StoreUseQueryOptions<UserListDto[]>
) => {
    const api = useCustomerApi()

    return useQuery({
        queryKey: customerKeys.representativeList(param),
        queryFn: () => api.getCustomerRepresentatives(param),
        ...options,
    })
}
