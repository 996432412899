import { NameDTO } from "api"
import { useCustomerNameList } from "modules/base/stores/CustomerStore"
import { useTranslation } from "react-i18next"
import { Autocomplete } from "./Autocomplete"

interface ITestSelectProps {
    required?: boolean
    disabled?: boolean
    label?: string
    name?: string
    multiple?: boolean
}

const CustomerSelect = (props: ITestSelectProps) => {
    const { t } = useTranslation()
    const { required = false, disabled = false, name = "testId", multiple = false, label = t("formInput.customers") } = props

    const { isInitialLoading, data = [] } = useCustomerNameList({})
    return (
        <Autocomplete
            label={label!}
            name={name}
            options={data!}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => (option as NameDTO).name ?? t("common.loading")}
            required={required}
            loadingText={t("common.loading")}
            loading={isInitialLoading}
            disabled={disabled}
            multiple={multiple}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {option.name}
                    </li>
                )
            }}
        />
    )
}

export default CustomerSelect