import * as Sentry from "@sentry/react"
import { useOwnUser } from "stores/UserStore"

/**
 * Kullanıcın verilen yetkisi sahip olduğunu kontrol eder
 * Yetki olarak "" verilirse yetki kontrolü yapmaz
 * @param permission Kontrol edilecek yetki
 * @returns
 */
export const useHasPermission = function useHasPermission(permission: string) {
    const { isFetching, data } = useOwnUser({
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 15,
    })
    if (permission === "") {
        return { isFetching: false, result: true }
    }
    Sentry.addBreadcrumb({
        category: "permission",
        message: `permission check ${permission}:${!!(
            data?.grantedPermissions && data?.grantedPermissions.includes(permission)
        )}`,
        level: "debug",
    })
    return { isFetching, result: !!(data?.grantedPermissions && data?.grantedPermissions.includes(permission)) }
}
