import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { LinearProgress } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import ErrorBoundary from "ErrorBoundary"
import I18nOverrides from "I18nOverrides"
import ThemeContext from "ThemeContext"
import { UserIdentifier } from "components/UserIdentifier"
import config from "config"
import { SnackbarProvider } from "notistack"
import { StrictMode, Suspense, useEffect } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import {
    RouterProvider,
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom"
import routes from "routes"
import "simplebar-react/dist/simplebar.min.css"
import { loginRawNoCheck } from "stores/AuthStore"
console.log(`App version: ${import.meta.env.VITE_VERSION}`)

if (import.meta.env.PROD) {
    Sentry.init({
        tunnel: "https://sentunnel.iot.lemonsoft.com.tr/sentunnel",
        dsn: "https://7ac1b76d3e76f7dc10261aa83e35453d@sentry.lemonsoft.com.tr/33",
        release: import.meta.env.VITE_VERSION,
        environment: `${import.meta.env.MODE}-${window.__RUNTIME_CONFIG__.app ?? "prod"}`,
        tracePropagationTargets: [
            "localhost:3000",
            new RegExp("^" + config.api_url),
            new RegExp("^" + config.api_report_url),
        ],
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        tracesSampleRate: 0.01,
        denyUrls: [/extensions\//i, /^chrome:\/\//i],
    })
} else {
    localStorage.debug = "msr:*"
}

Sentry.setTag("page_locale", "tr")

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false, //Login ile sorun yapıyor loopa giriyor.
            //TODO cachekey düzenlemesini bekle, staleTime: 1000 * 60 * 5, // 5 minutes
        },
    },
})

const emotionCache = createCache({ key: "css", prepend: true })
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(routes)

const ExternalLogin = () => {
    useEffect(() => {
        try {
            const searchParams = new URLSearchParams(document.location.search)

            if (searchParams.get("token")) {
                loginRawNoCheck(searchParams.get("token")!)
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }, [])
    return null
}

const App = Sentry.withProfiler(() => {
    return (
        <StrictMode>
            <HelmetProvider>
                <CacheProvider value={emotionCache}>
                    <ThemeContext>
                        <Suspense fallback={<LinearProgress />}>
                            <ErrorBoundary>
                                <SnackbarProvider>
                                    <QueryClientProvider client={queryClient}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
                                            <ExternalLogin />
                                            <UserIdentifier />
                                            <I18nOverrides />
                                            <Helmet titleTemplate="%s - TNB Panel" defaultTitle="Panel">
                                                <meta name="language" content="Turkish" />
                                                <meta charSet="utf-8" />
                                                <meta name="description" content="Panel" />
                                            </Helmet>
                                            <RouterProvider router={router} />
                                        </LocalizationProvider>
                                    </QueryClientProvider>
                                </SnackbarProvider>
                            </ErrorBoundary>
                        </Suspense>
                    </ThemeContext>
                </CacheProvider>
            </HelmetProvider>
        </StrictMode>
    )
})

export default App
