export const enum Features {
    Kokpit = "Kokpit",
    Filo = "Fys",
    Sds = "Sds",
    Ats = "Ats",
    /** Personel Bilgi Sistemi */
    Pys = "Pys",
    /** Personel İzin Yönetimi*/
    PysIzin = "PysIzin",
    Pdks = "Pdks",
    /** Personel belge Yönetimi*/
    PdksIk = "PdksIk",
    Zys = "Zys",
    TestFeature = "TestFeature",
    Mys = "Mys",
    Sbs = "Sbs",
    /** Araç Bilgi Sistemi */
    Abs = "Abs",
    /** araç rezarvasyon  */
    AbsRezervasyon = "AbsRezervasyon",
    /** araç müdürlük transfer */
    AbsTransfer = "AbsTransfer",
    Gas = "Gas",
    Eys = "Eys",
    Bms = "Bms",
    Zfs = "Zfs",
    Yys = "Yys",
    Tts = "Tts",
    Tds = "Tds",
    Gbs = "Gbs",
    Ybs = "Ybs",
    Dorse = "Dorse",
    Cekip = "Cekip",
    YolcuCard = "Yolcucard",
    /** Bilgi Teknolojileri sistemi */
    Bts = "Bts",
    /** Bina Yönetim sistemi */
    Bys = "Bys",
    Iis = "Iis",
    Sob = "Sob",
    Trafikceza = "Trafikceza",
    PuantajBelka = "PuantajBelka",
    FysOzmal = "FysOzmal",
    FysAriza = "FysAriza",
    FysFatura = "FysFatura",
    Asy = "Asy",
    Muhur = "Muhur",
    YakitKiti = "Yakitkiti",
    Nfc = "Nfc",
    C3cx = "C3cx",
    Siber = "Siber",
    Gbsilyos = "Gbsilyos",
    Puantaj = "Puantaj",
    Kbs = "Kbs",
    Muhtar = "Muhtar",
    Pts = "Pts",
    Gms = "Gms",
    Dilek = "Dilek",
    GmsFuel = "GmsFuel",
    TtsCipYonetimi = "TtsCipYonetimi",
    Anket = "Anket",
    ScheduledNotification = "ScheduledNotification",
    Eklenecek = "Eklenecek",
    /** Kamu */
    PublicSector = "PublicSector",
    PuantajBariyer = "PuantajBariyer",
    Gbsbelpa = "Gbsbelpa",
    /** SMS bildirim entegrasyonu */
    SmsNotification = "SmsNotification",
    Akademi = "Akademi",
    Prime = "Prime",
    Isgucu = "Isgucu",
    KokpitMap = "KokpitMap"

}
