/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  KokpitEnviromentType,
  KokpitEnvironmentDto,
  KokpitEnvironmentListDto,
  KokpitEnvironmentListDtoOffsetPaginationResult,
  KokpitEnvironmentUpdateDto,
  NameDTO,
  ValidationProblemDetails,
} from '../models';
import {
    KokpitEnviromentTypeFromJSON,
    KokpitEnviromentTypeToJSON,
    KokpitEnvironmentDtoFromJSON,
    KokpitEnvironmentDtoToJSON,
    KokpitEnvironmentListDtoFromJSON,
    KokpitEnvironmentListDtoToJSON,
    KokpitEnvironmentListDtoOffsetPaginationResultFromJSON,
    KokpitEnvironmentListDtoOffsetPaginationResultToJSON,
    KokpitEnvironmentUpdateDtoFromJSON,
    KokpitEnvironmentUpdateDtoToJSON,
    NameDTOFromJSON,
    NameDTOToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetKokpitEnvironmentRequest {
    id: number;
}

export interface GetKokpitEnvironmentNamesRequest {
    tenantId?: number;
}

export interface GetKokpitEnvironmentsRequest {
    type?: KokpitEnviromentType;
    tenantId?: number;
}

export interface GetKokpitEnvironmentsPaginationRequest {
    type?: KokpitEnviromentType;
    tenantId?: number;
    pageSize?: number;
    page?: number;
}

export interface PostKokpitEnvironmentRequest {
    kokpitEnvironmentDto?: KokpitEnvironmentDto;
}

export interface PutKokpitEnvironmentRequest {
    id: number;
    kokpitEnvironmentUpdateDto?: KokpitEnvironmentUpdateDto;
}

export interface UpdateCustomersFromKokpitRequest {
    id: number;
}

/**
 * KokpitEnvironmentApi - interface
 * 
 * @export
 * @interface KokpitEnvironmentApiInterface
 */
export interface KokpitEnvironmentApiInterface {
    /**
     * 
     * @summary KokpitEnvironment (Login AuthType: Developer)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitEnvironmentApiInterface
     */
    getKokpitEnvironmentRaw(requestParameters: GetKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitEnvironmentListDto>>;

    /**
     * KokpitEnvironment (Login AuthType: Developer)
     */
    getKokpitEnvironment(requestParameters: GetKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitEnvironmentListDto>;

    /**
     * 
     * @summary Ortam isimlerini Listeleme (Login AuthType: Developer)
     * @param {number} [tenantId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitEnvironmentApiInterface
     */
    getKokpitEnvironmentNamesRaw(requestParameters: GetKokpitEnvironmentNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>>;

    /**
     * Ortam isimlerini Listeleme (Login AuthType: Developer)
     */
    getKokpitEnvironmentNames(requestParameters: GetKokpitEnvironmentNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>>;

    /**
     * 
     * @summary KokpitEnvironment Listeleme (Login AuthType: Developer)
     * @param {KokpitEnviromentType} [type] Tip
     * @param {number} [tenantId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitEnvironmentApiInterface
     */
    getKokpitEnvironmentsRaw(requestParameters: GetKokpitEnvironmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitEnvironmentListDto>>>;

    /**
     * KokpitEnvironment Listeleme (Login AuthType: Developer)
     */
    getKokpitEnvironments(requestParameters: GetKokpitEnvironmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitEnvironmentListDto>>;

    /**
     * 
     * @summary KokpitEnvironment sayfalama yaparak Listeleme (Login AuthType: Developer)
     * @param {KokpitEnviromentType} [type] Tip
     * @param {number} [tenantId] opsiyonel alt müşteri
     * @param {number} [pageSize] Bir sayfada gösterilecek kayır sayısı
     * @param {number} [page] The page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitEnvironmentApiInterface
     */
    getKokpitEnvironmentsPaginationRaw(requestParameters: GetKokpitEnvironmentsPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitEnvironmentListDtoOffsetPaginationResult>>;

    /**
     * KokpitEnvironment sayfalama yaparak Listeleme (Login AuthType: Developer)
     */
    getKokpitEnvironmentsPagination(requestParameters: GetKokpitEnvironmentsPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitEnvironmentListDtoOffsetPaginationResult>;

    /**
     * 
     * @summary KokpitEnvironment Ekleme (Login AuthType: Developer)
     * @param {KokpitEnvironmentDto} [kokpitEnvironmentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitEnvironmentApiInterface
     */
    postKokpitEnvironmentRaw(requestParameters: PostKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitEnvironmentListDto>>;

    /**
     * KokpitEnvironment Ekleme (Login AuthType: Developer)
     */
    postKokpitEnvironment(requestParameters: PostKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitEnvironmentListDto>;

    /**
     * 
     * @summary KokpitEnvironment güncelleme (Login AuthType: Developer)
     * @param {number} id 
     * @param {KokpitEnvironmentUpdateDto} [kokpitEnvironmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitEnvironmentApiInterface
     */
    putKokpitEnvironmentRaw(requestParameters: PutKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * KokpitEnvironment güncelleme (Login AuthType: Developer)
     */
    putKokpitEnvironment(requestParameters: PutKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary verilen ortam için kokpitten tanımlı müşterileri sisteme çeker (Login AuthType: Admin)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitEnvironmentApiInterface
     */
    updateCustomersFromKokpitRaw(requestParameters: UpdateCustomersFromKokpitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>>;

    /**
     * verilen ortam için kokpitten tanımlı müşterileri sisteme çeker (Login AuthType: Admin)
     */
    updateCustomersFromKokpit(requestParameters: UpdateCustomersFromKokpitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>>;

}

/**
 * 
 */
export class KokpitEnvironmentApi extends runtime.BaseAPI implements KokpitEnvironmentApiInterface {

    /**
     * KokpitEnvironment (Login AuthType: Developer)
     */
    async getKokpitEnvironmentRaw(requestParameters: GetKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitEnvironmentListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getKokpitEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitEnvironment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitEnvironmentListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitEnvironment (Login AuthType: Developer)
     */
    async getKokpitEnvironment(requestParameters: GetKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitEnvironmentListDto> {
        const response = await this.getKokpitEnvironmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ortam isimlerini Listeleme (Login AuthType: Developer)
     */
    async getKokpitEnvironmentNamesRaw(requestParameters: GetKokpitEnvironmentNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitEnvironment/KokpitEnvironmentNames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NameDTOFromJSON));
    }

    /**
     * Ortam isimlerini Listeleme (Login AuthType: Developer)
     */
    async getKokpitEnvironmentNames(requestParameters: GetKokpitEnvironmentNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>> {
        const response = await this.getKokpitEnvironmentNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitEnvironment Listeleme (Login AuthType: Developer)
     */
    async getKokpitEnvironmentsRaw(requestParameters: GetKokpitEnvironmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitEnvironmentListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitEnvironment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KokpitEnvironmentListDtoFromJSON));
    }

    /**
     * KokpitEnvironment Listeleme (Login AuthType: Developer)
     */
    async getKokpitEnvironments(requestParameters: GetKokpitEnvironmentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitEnvironmentListDto>> {
        const response = await this.getKokpitEnvironmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitEnvironment sayfalama yaparak Listeleme (Login AuthType: Developer)
     */
    async getKokpitEnvironmentsPaginationRaw(requestParameters: GetKokpitEnvironmentsPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitEnvironmentListDtoOffsetPaginationResult>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitEnvironment/GetKokpitEnvironmentsPagination`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitEnvironmentListDtoOffsetPaginationResultFromJSON(jsonValue));
    }

    /**
     * KokpitEnvironment sayfalama yaparak Listeleme (Login AuthType: Developer)
     */
    async getKokpitEnvironmentsPagination(requestParameters: GetKokpitEnvironmentsPaginationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitEnvironmentListDtoOffsetPaginationResult> {
        const response = await this.getKokpitEnvironmentsPaginationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitEnvironment Ekleme (Login AuthType: Developer)
     */
    async postKokpitEnvironmentRaw(requestParameters: PostKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitEnvironmentListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitEnvironment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitEnvironmentDtoToJSON(requestParameters.kokpitEnvironmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitEnvironmentListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitEnvironment Ekleme (Login AuthType: Developer)
     */
    async postKokpitEnvironment(requestParameters: PostKokpitEnvironmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitEnvironmentListDto> {
        const response = await this.postKokpitEnvironmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitEnvironment güncelleme (Login AuthType: Developer)
     */
    async putKokpitEnvironmentRaw(requestParameters: PutKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putKokpitEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitEnvironment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitEnvironmentUpdateDtoToJSON(requestParameters.kokpitEnvironmentUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * KokpitEnvironment güncelleme (Login AuthType: Developer)
     */
    async putKokpitEnvironment(requestParameters: PutKokpitEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putKokpitEnvironmentRaw(requestParameters, initOverrides);
    }

    /**
     * verilen ortam için kokpitten tanımlı müşterileri sisteme çeker (Login AuthType: Admin)
     */
    async updateCustomersFromKokpitRaw(requestParameters: UpdateCustomersFromKokpitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomersFromKokpit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitEnvironment/{id}/UpdateCustomersFromKokpit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NameDTOFromJSON));
    }

    /**
     * verilen ortam için kokpitten tanımlı müşterileri sisteme çeker (Login AuthType: Admin)
     */
    async updateCustomersFromKokpit(requestParameters: UpdateCustomersFromKokpitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>> {
        const response = await this.updateCustomersFromKokpitRaw(requestParameters, initOverrides);
        return await response.value();
    }

}


export function useKokpitEnvironmentApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new KokpitEnvironmentApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new KokpitEnvironmentApi(apiConfig["ETL-API"])
}
