import { Box, CircularProgress } from "@mui/material"
import { Features } from "features/Features"
import { useHasFeature } from "features/useHasFeature"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const LoginRouter = () => {
    const { result: hasKokpitMap, isFetching: isFetchingKokpitMap } = useHasFeature(Features.KokpitMap)
    const { result: hasAts, isFetching: isFetchingAts } = useHasFeature(Features.Ats)
    const navigate = useNavigate()

    useEffect(() => {
        if (!isFetchingKokpitMap && !isFetchingAts) {
            if (hasAts && hasKokpitMap) {
                navigate("/app/unit/map")
            } else {
                navigate("/app/home")
            }
        }
    }, [hasKokpitMap, hasAts, isFetchingAts, isFetchingKokpitMap, navigate])
    return (
        <Box sx={{ display: "flex", height: 500, alignItems: "center" }}>
            <CircularProgress sx={{ margin: "auto" }} size={50} />
        </Box>
    )
}
export default LoginRouter