import { Features } from "features/Features"
import { useHasFeature } from "features/useHasFeature"
import { useTranslation } from "react-i18next"
import { useAuthStore } from "stores/AuthStore"

const I18nOverrides = () => {
    const auth = useAuthStore((state) => state.auth)
    const { i18n } = useTranslation()
    const { result: hasPuantajBelka } = useHasFeature(Features.PuantajBelka, auth)

    if (hasPuantajBelka) {
        i18n.addResource("tr", "main", "personel.fields.personeldepartmentId", "Dahil Olduğu Hizmet Alım Müdürlüğü")
        i18n.addResource(
            "tr",
            "main",
            "personel.fields.personelDepartmentParentId",
            "Dahil Olduğu Hizmet Alım Başkanlığı"
        )
    }
    return null
}

export default I18nOverrides
