import { alpha, createTheme, responsiveFontSizes } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { trTRCore, trTRPickers, trTRgrid } from "components/DataGrid/locale"
import baseTheme from "./base"

const lightTheme = createTheme(
    deepmerge(baseTheme, {
        components: {
            MuiDialog: {
                styleOverrides: {
                    root: {
                        "& .MuiDialog-paper	": {
                            boxShadow: `0px 4px 20px ${alpha("#1e1e1e", 0.72)}`,
                        },
                        "& .MuiDialog-paperFullScreen": {
                            boxShadow: "none",
                        },
                    },
                },
                defaultProps: {
                    hideBackdrop: true,
                },
            },
        },
        palette: {
            mode: "dark",
            primaryDark: {
                "50": "#eff7ff",
                "100": "#e5eefb",
                "200": "#9da4ae",
                "300": "#c6cedb",
                "400": "#a1a9b5",
                "500": "#808894",
                "600": "#59616c",
                "700": "#474e59",
                "800": "rgba(255, 255, 255, 0.04)",
                "900": "#07101a",
                main: "#0B0F19",
                A100: "#e9e9ff",
                A200: "#dcdcf0",
                A400: "#a2a2b6",
                A700: "#474758",
                light: "#383f47",
                dark: "#040b12",
                contrastText: "#fff",
            },
            common: {
                black: "#000",
                white: "#fff",
            },
            primary: {
                main: "#660066",
                light: "#843384",
                dark: "#470047",
                contrastText: "#FFFFFF",
                lightest: "#F5F8FF",
                darkest: "#00359E",
                alpha4: "rgba(41, 112, 255, 0.04)",
                alpha8: "rgba(41, 112, 255, 0.08)",
                alpha12: "rgba(41, 112, 255, 0.12)",
                alpha30: "rgba(41, 112, 255, 0.3)",
                alpha50: "rgba(41, 112, 255, 0.5)",
            },
            secondary: {
                main: "#d9d9d9",
            },
            error: {
                main: "#F04438",
                light: "#FEE4E2",
                dark: "#B42318",
                contrastText: "#FFFFFF",
                lightest: "#FEF3F2",
                darkest: "#7A271A",
                alpha4: "rgba(240, 68, 56, 0.04)",
                alpha8: "rgba(240, 68, 56, 0.08)",
                alpha12: "rgba(240, 68, 56, 0.12)",
                alpha30: "rgba(240, 68, 56, 0.3)",
                alpha50: "rgba(240, 68, 56, 0.5)",
            },
            warning: {
                main: "#F79009",
                light: "#FEF0C7",
                dark: "#B54708",
                contrastText: "#FFFFFF",
                lightest: "#FFFAEB",
                darkest: "#7A2E0E",
                alpha4: "rgba(247, 144, 9, 0.04)",
                alpha8: "rgba(247, 144, 9, 0.08)",
                alpha12: "rgba(247, 144, 9, 0.12)",
                alpha30: "rgba(247, 144, 9, 0.3)",
                alpha50: "rgba(247, 144, 9, 0.5)",
            },
            info: {
                main: "#06AED4",
                light: "#CFF9FE",
                dark: "#0E7090",
                contrastText: "#FFFFFF",
                lightest: "#ECFDFF",
                darkest: "#164C63",
                alpha4: "rgba(6, 174, 212, 0.04)",
                alpha8: "rgba(6, 174, 212, 0.08)",
                alpha12: "rgba(6, 174, 212, 0.12)",
                alpha30: "rgba(6, 174, 212, 0.3)",
                alpha50: "rgba(6, 174, 212, 0.5)",
            },
            success: {
                main: "#10B981",
                light: "#3FC79A",
                dark: "#0B815A",
                contrastText: "#FFFFFF",
                lightest: "#F0FDF9",
                darkest: "#134E48",
                alpha4: "rgba(16, 185, 129, 0.04)",
                alpha8: "rgba(16, 185, 129, 0.08)",
                alpha12: "rgba(16, 185, 129, 0.12)",
                alpha30: "rgba(16, 185, 129, 0.3)",
                alpha50: "rgba(16, 185, 129, 0.5)",
            },
            grey: {
                "50": "#fafafa",
                "100": "#f5f5f5",
                "200": "#eeeeee",
                "300": "#e0e0e0",
                "400": "#bdbdbd",
                "500": "#9e9e9e",
                "600": "#757575",
                "700": "#616161",
                "800": "#424242",
                "900": "#212121",
                A100: "#f5f5f5",
                A200: "#eeeeee",
                A400: "#bdbdbd",
                A700: "#616161",
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            text: {
                primary: "#EDF2F7",
                secondary: "#A0AEC0",
                disabled: "rgba(255, 255, 255, 0.48)",
            },
            divider: "rgba(255, 255, 255, 0.05)",
            background: {
                paper: "#111927",
                default: "#0B0F19",
            },
            action: {
                active: "#6C737F",
                hover: "rgba(243, 244, 246, 0.04)",
                hoverOpacity: 0.04,
                selected: "rgba(243, 244, 246, 0.12)",
                selectedOpacity: 0.08,
                disabled: "rgba(243, 244, 246, 0.38)",
                disabledBackground: "rgba(243, 244, 246, 0.12)",
                disabledOpacity: 0.38,
                focus: "rgba(243, 244, 246, 0.16)",
                focusOpacity: 0.12,
                activatedOpacity: 0.12,
            },
        },
    }),
    trTRgrid, // x-data-grid translations
    trTRPickers, // x-data-grid translations
    trTRCore // core translations
)

export default responsiveFontSizes(lightTheme)
