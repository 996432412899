import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import auth_tr from "modules/auth/locales/tr/auth.json"
import customer_tr from "modules/base/locales/tr/Customer.json"
import customerChannel_tr from "modules/base/locales/tr/CustomerChannel.json"
import kokpitEnvironment_tr from "modules/base/locales/tr/KokpitEnvironment.json"
import kokpitFeature_tr from "modules/base/locales/tr/KokpitFeature.json"
import notification_tr from "modules/notification/locales/tr/notification.json"
import ticketSystem_tr from "modules/ticketSystem/locales/tr/ticketSystem.json"
import { initReactI18next } from "react-i18next"
import main_tr from "./locales/tr/main.json"
import route_tr from "./locales/tr/route.json"

export const resources = {
    tr: {
        main: main_tr,
        route: route_tr,
        auth: auth_tr,
        notification: notification_tr,
        ticketSystem: ticketSystem_tr,
        kokpitEnvironment: kokpitEnvironment_tr,
        customer: customer_tr,
        customerChannel: customerChannel_tr,
        kokpitFeature: kokpitFeature_tr,
    },
}
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "tr",
        // have a common namespace used around the full app
        ns: [
            "main",
            "route",
            "auth",
            "notification",
            "ticketSystem",
            "kokpitEnvironment",
            "customer",
            "customerChannel",
            "kokpitFeature",
        ],
        defaultNS: "main",
        supportedLngs: ["tr", "en"],
        debug: !import.meta.env.PROD,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        returnObjects: true,
        load: "currentOnly",
    })

export default i18n
