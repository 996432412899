/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UserAddDto
 */
export interface UserAddDto {
    /**
     * 
     * @type {string}
     * @memberof UserAddDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserAddDto
     */
    tenantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAddDto
     */
    roleNames?: Array<string> | null;
}

/**
 * Check if a given object implements the UserAddDto interface.
 */
export function instanceOfUserAddDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserAddDtoFromJSON(json: any): UserAddDto {
    return UserAddDtoFromJSONTyped(json, false);
}

export function UserAddDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAddDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'roleNames': !exists(json, 'roleNames') ? undefined : json['roleNames'],
    };
}

export function UserAddDtoToJSON(value?: UserAddDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'phoneNumber': value.phoneNumber,
        'tenantId': value.tenantId,
        'fullName': value.fullName,
        'roleNames': value.roleNames,
    };
}

