import { Grid, TextFieldProps as MuiTextFieldProps } from "@mui/material"
import { Field } from "react-final-form"
import ReactQuill from "react-quill"
// import ImageResize  from 'quill-image-resize-module';
import "react-quill/dist/quill.snow.css"
import CustomToolbar from "./CustomToolbar"

export type QuillEditorProps = Partial<Omit<MuiTextFieldProps, "type" | "onChange">> & {
    disabled: boolean
    type: string
    name: string
}

// Quill.register('modules/ImageResize',ImageResize);
const Editor = ({ input: { onChange, value }, ...rest }) => {
    const modules = {
        toolbar: {
            container: "#toolbar",
        },
    }
    const formats = [
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "color",
        "background",
        "script",
        "header",
        "blockquote",
        "code-block",
        "indent",
        "list",
        "direction",
        "align",
        "link",
        "image",
        "video",
        "formula",
    ]

    return (
        <>
            <Grid item xs={12}>
                <CustomToolbar />
            </Grid>
            <Grid item xs={12}>
                <ReactQuill
                    value={value}
                    onChange={onChange}
                    modules={modules}
                    formats={formats}
                    placeholder={"Mesajınızı yazınız..."}
                    style={{ height: "300px" }}
                />
            </Grid>
        </>
    )
}

export function QuillEditor(props: QuillEditorProps) {
    const { name, type, label, disabled } = props

    return <Field name={name} type={type} label={label} fullWidth component={Editor} disabled={disabled} />
}

export default QuillEditor
