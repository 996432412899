/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Represents state of a UserNotification.
 * @export
 * @enum {string}
 */
export enum UserNotificationState {
    Unread = 'Unread',
    Read = 'Read'
}


export function UserNotificationStateFromJSON(json: any): UserNotificationState {
    return UserNotificationStateFromJSONTyped(json, false);
}

export function UserNotificationStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationState {
    return json as UserNotificationState;
}

export function UserNotificationStateToJSON(value?: UserNotificationState | null): any {
    return value as any;
}

