import RequireAuth from "components/router/RequireAuth"
import i18n from "i18n"
import { lazy } from "react"
import { RouteExtension } from "routeExtension"
import { RoutesGroupEnum } from "types/RoutesGroupEnum"

const KokpitEnvironment = lazy(
    () => import("./views/kokpitEnvironment/KokpitEnvironment" /* webpackChunkName: 'Base' */)
)
const Customer = lazy(() => import("./views/customer/Customer" /* webpackChunkName: 'Base' */))
const CustomerChannel = lazy(() => import("./views/customerChannel/CustomerChannel" /* webpackChunkName: 'Base' */))
const KokpitFeature = lazy(() => import("./views/kokpitFeature/KokpitFeature" /* webpackChunkName: 'Base' */))
const KokpitModule = lazy(() => import("./views/kokpitModule/KokpitModule" /* webpackChunkName: 'Base' */))
const KokpitPackage = lazy(() => import("./views/kokpitPackage/KokpitPackage" /* webpackChunkName: 'Base' */))

const baseRoutes: RouteExtension[] = [
    {
        group: RoutesGroupEnum.Base,
        title: i18n.t("route:pageName.base"),
        breadcrumbDisable: true,
        children: [
            {
                path: "base/kokpitPackage",
                group: RoutesGroupEnum.Base,
                title: i18n.t("route:pageName.kokpitPackage"),
                description: i18n.t("route:description.kokpitModule"),
                element: (
                    <RequireAuth>
                        <KokpitPackage />
                    </RequireAuth>
                ),
            },
            {
                path: "base/kokpitModule",
                group: RoutesGroupEnum.Base,
                title: i18n.t("route:pageName.kokpitModule"),
                description: i18n.t("route:description.kokpitModule"),
                element: (
                    <RequireAuth>
                        <KokpitModule />
                    </RequireAuth>
                ),
            },
            {
                path: "base/kokpitFeature",
                group: RoutesGroupEnum.Base,
                title: i18n.t("route:pageName.kokpitFeature"),
                description: i18n.t("route:description.kokpitFeature"),
                element: (
                    <RequireAuth>
                        <KokpitFeature />
                    </RequireAuth>
                ),
            },
            {
                path: "base/kokpitEnvironment",
                group: RoutesGroupEnum.Base,
                title: i18n.t("route:pageName.kokpitEnvironment"),
                description: i18n.t("route:description.kokpitEnvironment"),
                element: (
                    <RequireAuth>
                        <KokpitEnvironment />
                    </RequireAuth>
                ),
            },
            {
                path: "customer",
                group: RoutesGroupEnum.Base,
                title: i18n.t("route:pageName.customer"),
                description: i18n.t("route:description.customer"),
                element: (
                    <RequireAuth>
                        <Customer />
                    </RequireAuth>
                ),
            },
            {
                path: "base/customerChannel",
                group: RoutesGroupEnum.Base,
                title: i18n.t("route:pageName.customerChannel"),
                description: i18n.t("route:description.customerChannel"),
                element: (
                    <RequireAuth>
                        <CustomerChannel />
                    </RequireAuth>
                ),
            },
        ],
    },
]
export default baseRoutes
