/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  TicketMessageDto,
  TicketMessageListDto,
  TicketStatus,
  ValidationProblemDetails,
} from '../models';
import {
    TicketMessageDtoFromJSON,
    TicketMessageDtoToJSON,
    TicketMessageListDtoFromJSON,
    TicketMessageListDtoToJSON,
    TicketStatusFromJSON,
    TicketStatusToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetTicketMessageRequest {
    id: number;
}

export interface GetTicketMessagesRequest {
    status?: TicketStatus;
    ticketId?: number;
    tenantId?: number;
}

export interface PostTicketMessageRequest {
    ticketMessageDto?: TicketMessageDto;
}

/**
 * TicketMessageApi - interface
 * 
 * @export
 * @interface TicketMessageApiInterface
 */
export interface TicketMessageApiInterface {
    /**
     * 
     * @summary  (Login)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketMessageApiInterface
     */
    getTicketMessageRaw(requestParameters: GetTicketMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketMessageListDto>>;

    /**
     *  (Login)
     */
    getTicketMessage(requestParameters: GetTicketMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketMessageListDto>;

    /**
     * 
     * @summary  (Login)
     * @param {TicketStatus} [status] 
     * @param {number} [ticketId] 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketMessageApiInterface
     */
    getTicketMessagesRaw(requestParameters: GetTicketMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TicketMessageListDto>>>;

    /**
     *  (Login)
     */
    getTicketMessages(requestParameters: GetTicketMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TicketMessageListDto>>;

    /**
     * 
     * @summary  (Login)
     * @param {TicketMessageDto} [ticketMessageDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketMessageApiInterface
     */
    postTicketMessageRaw(requestParameters: PostTicketMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketMessageListDto>>;

    /**
     *  (Login)
     */
    postTicketMessage(requestParameters: PostTicketMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketMessageListDto>;

}

/**
 * 
 */
export class TicketMessageApi extends runtime.BaseAPI implements TicketMessageApiInterface {

    /**
     *  (Login)
     */
    async getTicketMessageRaw(requestParameters: GetTicketMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketMessageListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTicketMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/TicketMessage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketMessageListDtoFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async getTicketMessage(requestParameters: GetTicketMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketMessageListDto> {
        const response = await this.getTicketMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async getTicketMessagesRaw(requestParameters: GetTicketMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TicketMessageListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.ticketId !== undefined) {
            queryParameters['ticketId'] = requestParameters.ticketId;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/TicketMessage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TicketMessageListDtoFromJSON));
    }

    /**
     *  (Login)
     */
    async getTicketMessages(requestParameters: GetTicketMessagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TicketMessageListDto>> {
        const response = await this.getTicketMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async postTicketMessageRaw(requestParameters: PostTicketMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketMessageListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/TicketMessage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TicketMessageDtoToJSON(requestParameters.ticketMessageDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketMessageListDtoFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async postTicketMessage(requestParameters: PostTicketMessageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketMessageListDto> {
        const response = await this.postTicketMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}


export function useTicketMessageApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new TicketMessageApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new TicketMessageApi(apiConfig["ETL-API"])
}
