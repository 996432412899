/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UserResetPasswordDto
 */
export interface UserResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordDto
     */
    newPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordDto
     */
    repeatNewPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordDto
     */
    pin?: string | null;
}

/**
 * Check if a given object implements the UserResetPasswordDto interface.
 */
export function instanceOfUserResetPasswordDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserResetPasswordDtoFromJSON(json: any): UserResetPasswordDto {
    return UserResetPasswordDtoFromJSONTyped(json, false);
}

export function UserResetPasswordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResetPasswordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'repeatNewPassword': !exists(json, 'repeatNewPassword') ? undefined : json['repeatNewPassword'],
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
    };
}

export function UserResetPasswordDtoToJSON(value?: UserResetPasswordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'newPassword': value.newPassword,
        'repeatNewPassword': value.repeatNewPassword,
        'pin': value.pin,
    };
}

