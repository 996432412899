import { Configuration, Middleware } from "api"
import { DefaultApi as DevtakipApi, Configuration as DevtakipConfiguration } from "api-devtakip"
import config from "config"
import i18n from "i18n"
import localForage from "localforage"
import { enqueueSnackbar } from "notistack"
import { AuthApi } from "./AuthApi"
import { logout, useAuthStore } from "./AuthStore"
const getToken = () => "Bearer " + useAuthStore.getState().token

export const apiConfig = {
    "ETL-API": new Configuration({
        basePath: config.api_url,
        apiKey: getToken,
    }),
    defaultTitle: new DevtakipConfiguration({
        basePath: config.devtakip_api_url,
    }),
}

export const localForageApi = localForage.createInstance({
    name: "yhsde",
})

export const apiMiddleware: Middleware = {
    pre: async (e) => {
        const { url, init } = e
        let newUrl = url
        /*
        if (this.rootStore.user.ImpersonationTenantId) {
            if (!init.headers) {
                init.headers = {}
            }
            init.headers["X-Tenant-Id"] = this.rootStore.user.ImpersonationTenantId
        }
        */
        return { url: newUrl, init }
    },
    post: async (e) => {
        const { response } = e
        if (response) {
            if (response.status === 401) {
                enqueueSnackbar(i18n.t("common.errors.sessionDeath"), {
                    variant: "error",
                    preventDuplicate: true,
                })
                logout(false)
                return
            } else if (response.status === 500) {
                enqueueSnackbar(i18n.t("common.errors.serverError"), {
                    variant: "error",
                    preventDuplicate: true,
                })
                return e.response
            } else if (response.status === 409) {
                let text = await response.text()
                enqueueSnackbar(i18n.t("common.errors.deleteError", { res: text }), {
                    variant: "warning",
                    preventDuplicate: true,
                })
                return e.response
            } else if (response.status === 403) {
                enqueueSnackbar(i18n.t("common.errors.permissionError"), {
                    variant: "warning",
                    preventDuplicate: true,
                })
                return e.response
            } else if (response.status === 501) {
                enqueueSnackbar(i18n.t("common.errors.notImplemented"), {
                    variant: "error",
                    preventDuplicate: true,
                })
                return e.response
            } else if (response.status === 599) {
                enqueueSnackbar(i18n.t("common.errors.connectionError"), {
                    variant: "error",
                    preventDuplicate: true,
                })
                return e.response
            } else if (response.status === 230) {
                enqueueSnackbar(i18n.t("common.errors.trailerPairError"), {
                    variant: "error",
                    preventDuplicate: true,
                })
                return e.response
            }
        }
        return e.response
    },
}

export const devtakipApi = new DevtakipApi(apiConfig.defaultTitle)

export const authApi = new AuthApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
