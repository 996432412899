import * as Sentry from "@sentry/browser"
import { queryClient } from "App"
import { useEffect } from "react"
import { useAuthStore } from "stores/AuthStore"
import { useOwnTenantWithFeatures, useOwnUser } from "stores/UserStore"

export const UserIdentifier = () => {
    const auth = useAuthStore((state) => state.auth)

    const { data } = useOwnUser({ refetchOnWindowFocus: false, enabled: auth, staleTime: 1000 * 60 * 15 }, false)

    // kullanıcın modullerinide çek
    useOwnTenantWithFeatures(
        {
            enabled: auth,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 15,
        },
        false
    )

    useEffect(() => {
        if (!auth) {
            console.log("clear sentry")
            Sentry.setUser(null)
            queryClient.removeQueries({
                queryKey: ["ownUser"],
            })
        } else if (!data) {
            // maybe do something?
        } else {
            const { tenantId, id, timeZone, email } = data
            console.log("set sentry")
            Sentry.setUser({ id: id?.toString(), email: email || undefined })
            Sentry.setTag("tenantId", tenantId)
            Sentry.setTag("timeZone", timeZone)
        }
    }, [data, auth, queryClient])

    return null
}
