/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { SmsProviderEnum } from './SmsProviderEnum';
import {
    SmsProviderEnumFromJSON,
    SmsProviderEnumFromJSONTyped,
    SmsProviderEnumToJSON,
} from './SmsProviderEnum';

/**
 * 
 * @export
 * @interface SmsProviderUpdateDto
 */
export interface SmsProviderUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SmsProviderUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderUpdateDto
     */
    userCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderUpdateDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderUpdateDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmsProviderUpdateDto
     */
    origin?: string | null;
    /**
     * 
     * @type {SmsProviderEnum}
     * @memberof SmsProviderUpdateDto
     */
    provider?: SmsProviderEnum;
}

/**
 * Check if a given object implements the SmsProviderUpdateDto interface.
 */
export function instanceOfSmsProviderUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SmsProviderUpdateDtoFromJSON(json: any): SmsProviderUpdateDto {
    return SmsProviderUpdateDtoFromJSONTyped(json, false);
}

export function SmsProviderUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsProviderUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userCode': !exists(json, 'userCode') ? undefined : json['userCode'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'provider': !exists(json, 'provider') ? undefined : SmsProviderEnumFromJSON(json['provider']),
    };
}

export function SmsProviderUpdateDtoToJSON(value?: SmsProviderUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userCode': value.userCode,
        'userName': value.userName,
        'password': value.password,
        'origin': value.origin,
        'provider': SmsProviderEnumToJSON(value.provider),
    };
}

