/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { KokpitEnviromentType } from './KokpitEnviromentType';
import {
    KokpitEnviromentTypeFromJSON,
    KokpitEnviromentTypeFromJSONTyped,
    KokpitEnviromentTypeToJSON,
} from './KokpitEnviromentType';

/**
 * 
 * @export
 * @interface KokpitEnvironmentUpdateDto
 */
export interface KokpitEnvironmentUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof KokpitEnvironmentUpdateDto
     */
    id?: number;
    /**
     * İsim
     * @type {string}
     * @memberof KokpitEnvironmentUpdateDto
     */
    name?: string | null;
    /**
     * Kokpit arayüz Urlsi
     * @type {string}
     * @memberof KokpitEnvironmentUpdateDto
     */
    url?: string | null;
    /**
     * Kokpit Api Urlsi
     * @type {string}
     * @memberof KokpitEnvironmentUpdateDto
     */
    apiUrl?: string | null;
    /**
     * Kokpit Api için kullanılacak token (Kokpit customerId 1 olan (tüm müşterileir görecek))
     * @type {string}
     * @memberof KokpitEnvironmentUpdateDto
     */
    token?: string | null;
    /**
     * 
     * @type {KokpitEnviromentType}
     * @memberof KokpitEnvironmentUpdateDto
     */
    type?: KokpitEnviromentType;
    /**
     * Kokpit lisans anahtarı
     * @type {string}
     * @memberof KokpitEnvironmentUpdateDto
     */
    licenseKey?: string | null;
}

/**
 * Check if a given object implements the KokpitEnvironmentUpdateDto interface.
 */
export function instanceOfKokpitEnvironmentUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KokpitEnvironmentUpdateDtoFromJSON(json: any): KokpitEnvironmentUpdateDto {
    return KokpitEnvironmentUpdateDtoFromJSONTyped(json, false);
}

export function KokpitEnvironmentUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KokpitEnvironmentUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'apiUrl': !exists(json, 'apiUrl') ? undefined : json['apiUrl'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'type': !exists(json, 'type') ? undefined : KokpitEnviromentTypeFromJSON(json['type']),
        'licenseKey': !exists(json, 'licenseKey') ? undefined : json['licenseKey'],
    };
}

export function KokpitEnvironmentUpdateDtoToJSON(value?: KokpitEnvironmentUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'url': value.url,
        'apiUrl': value.apiUrl,
        'token': value.token,
        'type': KokpitEnviromentTypeToJSON(value.type),
        'licenseKey': value.licenseKey,
    };
}

