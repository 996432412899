/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  ScheduledNotificationDto,
  ScheduledNotificationListDto,
  ScheduledNotificationStatusCountDto,
  ScheduledNotificationStatusEnum,
  ScheduledNotificationUpdateDto,
  ValidationProblemDetails,
} from '../models';
import {
    ScheduledNotificationDtoFromJSON,
    ScheduledNotificationDtoToJSON,
    ScheduledNotificationListDtoFromJSON,
    ScheduledNotificationListDtoToJSON,
    ScheduledNotificationStatusCountDtoFromJSON,
    ScheduledNotificationStatusCountDtoToJSON,
    ScheduledNotificationStatusEnumFromJSON,
    ScheduledNotificationStatusEnumToJSON,
    ScheduledNotificationUpdateDtoFromJSON,
    ScheduledNotificationUpdateDtoToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetScheduledNotificationRequest {
    id: number;
}

export interface GetScheduledNotificationStatusCountRequest {
    customerId?: number;
}

export interface GetScheduledNotificationsRequest {
    customerId?: number;
    status?: ScheduledNotificationStatusEnum;
}

export interface PostScheduledNotificationRequest {
    scheduledNotificationDto?: ScheduledNotificationDto;
}

export interface PutScheduledNotificationRequest {
    id: number;
    scheduledNotificationUpdateDto?: ScheduledNotificationUpdateDto;
}

export interface ScheduledNotificationCronJobRequest {
    customerId?: number;
}

/**
 * ScheduledNotificationApi - interface
 * 
 * @export
 * @interface ScheduledNotificationApiInterface
 */
export interface ScheduledNotificationApiInterface {
    /**
     * 
     * @summary İleri Tarihli Bildirim Cezası (Login AuthType: CustomerAdmin)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledNotificationApiInterface
     */
    getScheduledNotificationRaw(requestParameters: GetScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduledNotificationListDto>>;

    /**
     * İleri Tarihli Bildirim Cezası (Login AuthType: CustomerAdmin)
     */
    getScheduledNotification(requestParameters: GetScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduledNotificationListDto>;

    /**
     * 
     * @summary ScheduledNotification status sayısı (\"\") (Login)
     * @param {number} [customerId] Girilmezse tokendaki customerId alınıyor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledNotificationApiInterface
     */
    getScheduledNotificationStatusCountRaw(requestParameters: GetScheduledNotificationStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduledNotificationStatusCountDto>>;

    /**
     * ScheduledNotification status sayısı (\"\") (Login)
     */
    getScheduledNotificationStatusCount(requestParameters: GetScheduledNotificationStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduledNotificationStatusCountDto>;

    /**
     * 
     * @summary İleri Tarihli Bildirim Listeleme (Login AuthType: CustomerAdmin)
     * @param {number} [customerId] opsiyonel alt müşteri
     * @param {ScheduledNotificationStatusEnum} [status] status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledNotificationApiInterface
     */
    getScheduledNotificationsRaw(requestParameters: GetScheduledNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScheduledNotificationListDto>>>;

    /**
     * İleri Tarihli Bildirim Listeleme (Login AuthType: CustomerAdmin)
     */
    getScheduledNotifications(requestParameters: GetScheduledNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScheduledNotificationListDto>>;

    /**
     * 
     * @summary İleri Tarihli Bildirim Ekleme (Login AuthType: CustomerAdmin)
     * @param {ScheduledNotificationDto} [scheduledNotificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledNotificationApiInterface
     */
    postScheduledNotificationRaw(requestParameters: PostScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduledNotificationListDto>>;

    /**
     * İleri Tarihli Bildirim Ekleme (Login AuthType: CustomerAdmin)
     */
    postScheduledNotification(requestParameters: PostScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduledNotificationListDto>;

    /**
     * 
     * @summary İleri Tarihli Bildirim güncelleme (Login AuthType: CustomerAdmin)
     * @param {number} id 
     * @param {ScheduledNotificationUpdateDto} [scheduledNotificationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledNotificationApiInterface
     */
    putScheduledNotificationRaw(requestParameters: PutScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * İleri Tarihli Bildirim güncelleme (Login AuthType: CustomerAdmin)
     */
    putScheduledNotification(requestParameters: PutScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary İleri Tarihli Bildirim CronJob\'ını çalıştırır. (Login AuthType: Crm)
     * @param {number} [customerId] Girilmezse tokendaki customerId alınıyor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledNotificationApiInterface
     */
    scheduledNotificationCronJobRaw(requestParameters: ScheduledNotificationCronJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * İleri Tarihli Bildirim CronJob\'ını çalıştırır. (Login AuthType: Crm)
     */
    scheduledNotificationCronJob(requestParameters: ScheduledNotificationCronJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ScheduledNotificationApi extends runtime.BaseAPI implements ScheduledNotificationApiInterface {

    /**
     * İleri Tarihli Bildirim Cezası (Login AuthType: CustomerAdmin)
     */
    async getScheduledNotificationRaw(requestParameters: GetScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduledNotificationListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getScheduledNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/ScheduledNotification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduledNotificationListDtoFromJSON(jsonValue));
    }

    /**
     * İleri Tarihli Bildirim Cezası (Login AuthType: CustomerAdmin)
     */
    async getScheduledNotification(requestParameters: GetScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduledNotificationListDto> {
        const response = await this.getScheduledNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ScheduledNotification status sayısı (\"\") (Login)
     */
    async getScheduledNotificationStatusCountRaw(requestParameters: GetScheduledNotificationStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduledNotificationStatusCountDto>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/ScheduledNotification/ScheduledNotificationStatusCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduledNotificationStatusCountDtoFromJSON(jsonValue));
    }

    /**
     * ScheduledNotification status sayısı (\"\") (Login)
     */
    async getScheduledNotificationStatusCount(requestParameters: GetScheduledNotificationStatusCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduledNotificationStatusCountDto> {
        const response = await this.getScheduledNotificationStatusCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * İleri Tarihli Bildirim Listeleme (Login AuthType: CustomerAdmin)
     */
    async getScheduledNotificationsRaw(requestParameters: GetScheduledNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScheduledNotificationListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/ScheduledNotification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScheduledNotificationListDtoFromJSON));
    }

    /**
     * İleri Tarihli Bildirim Listeleme (Login AuthType: CustomerAdmin)
     */
    async getScheduledNotifications(requestParameters: GetScheduledNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScheduledNotificationListDto>> {
        const response = await this.getScheduledNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * İleri Tarihli Bildirim Ekleme (Login AuthType: CustomerAdmin)
     */
    async postScheduledNotificationRaw(requestParameters: PostScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduledNotificationListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/ScheduledNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduledNotificationDtoToJSON(requestParameters.scheduledNotificationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduledNotificationListDtoFromJSON(jsonValue));
    }

    /**
     * İleri Tarihli Bildirim Ekleme (Login AuthType: CustomerAdmin)
     */
    async postScheduledNotification(requestParameters: PostScheduledNotificationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduledNotificationListDto> {
        const response = await this.postScheduledNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * İleri Tarihli Bildirim güncelleme (Login AuthType: CustomerAdmin)
     */
    async putScheduledNotificationRaw(requestParameters: PutScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putScheduledNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/ScheduledNotification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduledNotificationUpdateDtoToJSON(requestParameters.scheduledNotificationUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * İleri Tarihli Bildirim güncelleme (Login AuthType: CustomerAdmin)
     */
    async putScheduledNotification(requestParameters: PutScheduledNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putScheduledNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * İleri Tarihli Bildirim CronJob\'ını çalıştırır. (Login AuthType: Crm)
     */
    async scheduledNotificationCronJobRaw(requestParameters: ScheduledNotificationCronJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/ScheduledNotification/CronJob`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * İleri Tarihli Bildirim CronJob\'ını çalıştırır. (Login AuthType: Crm)
     */
    async scheduledNotificationCronJob(requestParameters: ScheduledNotificationCronJobRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.scheduledNotificationCronJobRaw(requestParameters, initOverrides);
    }

}


export function useScheduledNotificationApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new ScheduledNotificationApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new ScheduledNotificationApi(apiConfig["ETL-API"])
}
