/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface NotificationMessage
 */
export interface NotificationMessage {
    /**
     * 
     * @type {string}
     * @memberof NotificationMessage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMessage
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationMessage
     */
    messageHtml?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationMessage
     */
    title?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof NotificationMessage
     */
    createdAt?: dayjs.Dayjs;
}

/**
 * Check if a given object implements the NotificationMessage interface.
 */
export function instanceOfNotificationMessage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationMessageFromJSON(json: any): NotificationMessage {
    return NotificationMessageFromJSONTyped(json, false);
}

export function NotificationMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'messageHtml': !exists(json, 'messageHtml') ? undefined : json['messageHtml'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

    };
}

export function NotificationMessageToJSON(value?: NotificationMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
        'messageHtml': value.messageHtml,
        'title': value.title,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

