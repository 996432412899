/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KokpitEnviromentType {
    Cloud = 'Cloud',
    OnPrem = 'OnPrem'
}


export function KokpitEnviromentTypeFromJSON(json: any): KokpitEnviromentType {
    return KokpitEnviromentTypeFromJSONTyped(json, false);
}

export function KokpitEnviromentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KokpitEnviromentType {
    return json as KokpitEnviromentType;
}

export function KokpitEnviromentTypeToJSON(value?: KokpitEnviromentType | null): any {
    return value as any;
}

