/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  SmsProviderDto,
  SmsProviderListDto,
  SmsProviderUpdateDto,
  ValidationProblemDetails,
} from '../models';
import {
    SmsProviderDtoFromJSON,
    SmsProviderDtoToJSON,
    SmsProviderListDtoFromJSON,
    SmsProviderListDtoToJSON,
    SmsProviderUpdateDtoFromJSON,
    SmsProviderUpdateDtoToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteSmsProviderRequest {
    customerId?: number;
}

export interface GetFirstSmsProviderOfCustomerRequest {
    customerId?: number;
}

export interface GetSmsProviderRequest {
    id: number;
}

export interface GetSmsProviderListRequest {
    customerId?: number;
}

export interface PostSmsProviderRequest {
    smsProviderDto?: SmsProviderDto;
}

export interface PutSmsProviderRequest {
    id: number;
    smsProviderUpdateDto?: SmsProviderUpdateDto;
}

/**
 * SmsProviderApi - interface
 * 
 * @export
 * @interface SmsProviderApiInterface
 */
export interface SmsProviderApiInterface {
    /**
     * 
     * @summary Müşterinin Sms Provider\'ını Silme (Login)
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsProviderApiInterface
     */
    deleteSmsProviderRaw(requestParameters: DeleteSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>>;

    /**
     * Müşterinin Sms Provider\'ını Silme (Login)
     */
    deleteSmsProvider(requestParameters: DeleteSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto>;

    /**
     * 
     * @summary Müşterinin Sms Sağlayıcısını Getirme (Login)
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsProviderApiInterface
     */
    getFirstSmsProviderOfCustomerRaw(requestParameters: GetFirstSmsProviderOfCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>>;

    /**
     * Müşterinin Sms Sağlayıcısını Getirme (Login)
     */
    getFirstSmsProviderOfCustomer(requestParameters: GetFirstSmsProviderOfCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto>;

    /**
     * 
     * @summary Spesifik bir Sms Provider\'ı Getirme (Login)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsProviderApiInterface
     */
    getSmsProviderRaw(requestParameters: GetSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>>;

    /**
     * Spesifik bir Sms Provider\'ı Getirme (Login)
     */
    getSmsProvider(requestParameters: GetSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto>;

    /**
     * 
     * @summary Sms Provider Listeleme (Login)
     * @param {number} [customerId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsProviderApiInterface
     */
    getSmsProviderListRaw(requestParameters: GetSmsProviderListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SmsProviderListDto>>>;

    /**
     * Sms Provider Listeleme (Login)
     */
    getSmsProviderList(requestParameters: GetSmsProviderListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SmsProviderListDto>>;

    /**
     * 
     * @summary Sms Provider Ekleme (Login)
     * @param {SmsProviderDto} [smsProviderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsProviderApiInterface
     */
    postSmsProviderRaw(requestParameters: PostSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>>;

    /**
     * Sms Provider Ekleme (Login)
     */
    postSmsProvider(requestParameters: PostSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto>;

    /**
     * 
     * @summary Sms Provider Güncelleme (Login)
     * @param {number} id 
     * @param {SmsProviderUpdateDto} [smsProviderUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsProviderApiInterface
     */
    putSmsProviderRaw(requestParameters: PutSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Sms Provider Güncelleme (Login)
     */
    putSmsProvider(requestParameters: PutSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class SmsProviderApi extends runtime.BaseAPI implements SmsProviderApiInterface {

    /**
     * Müşterinin Sms Provider\'ını Silme (Login)
     */
    async deleteSmsProviderRaw(requestParameters: DeleteSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/SmsProvider`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmsProviderListDtoFromJSON(jsonValue));
    }

    /**
     * Müşterinin Sms Provider\'ını Silme (Login)
     */
    async deleteSmsProvider(requestParameters: DeleteSmsProviderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto> {
        const response = await this.deleteSmsProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Müşterinin Sms Sağlayıcısını Getirme (Login)
     */
    async getFirstSmsProviderOfCustomerRaw(requestParameters: GetFirstSmsProviderOfCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/SmsProvider/smsProviderOfCustomer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmsProviderListDtoFromJSON(jsonValue));
    }

    /**
     * Müşterinin Sms Sağlayıcısını Getirme (Login)
     */
    async getFirstSmsProviderOfCustomer(requestParameters: GetFirstSmsProviderOfCustomerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto> {
        const response = await this.getFirstSmsProviderOfCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Spesifik bir Sms Provider\'ı Getirme (Login)
     */
    async getSmsProviderRaw(requestParameters: GetSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSmsProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/SmsProvider/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmsProviderListDtoFromJSON(jsonValue));
    }

    /**
     * Spesifik bir Sms Provider\'ı Getirme (Login)
     */
    async getSmsProvider(requestParameters: GetSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto> {
        const response = await this.getSmsProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sms Provider Listeleme (Login)
     */
    async getSmsProviderListRaw(requestParameters: GetSmsProviderListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SmsProviderListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/SmsProvider`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SmsProviderListDtoFromJSON));
    }

    /**
     * Sms Provider Listeleme (Login)
     */
    async getSmsProviderList(requestParameters: GetSmsProviderListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SmsProviderListDto>> {
        const response = await this.getSmsProviderListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sms Provider Ekleme (Login)
     */
    async postSmsProviderRaw(requestParameters: PostSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmsProviderListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/SmsProvider`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmsProviderDtoToJSON(requestParameters.smsProviderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmsProviderListDtoFromJSON(jsonValue));
    }

    /**
     * Sms Provider Ekleme (Login)
     */
    async postSmsProvider(requestParameters: PostSmsProviderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmsProviderListDto> {
        const response = await this.postSmsProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sms Provider Güncelleme (Login)
     */
    async putSmsProviderRaw(requestParameters: PutSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putSmsProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/SmsProvider/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SmsProviderUpdateDtoToJSON(requestParameters.smsProviderUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sms Provider Güncelleme (Login)
     */
    async putSmsProvider(requestParameters: PutSmsProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putSmsProviderRaw(requestParameters, initOverrides);
    }

}


export function useSmsProviderApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new SmsProviderApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new SmsProviderApi(apiConfig["ETL-API"])
}
