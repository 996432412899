/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerStatus {
    Active = 'Active',
    Passive = 'Passive',
    Potential = 'Potential'
}


export function CustomerStatusFromJSON(json: any): CustomerStatus {
    return CustomerStatusFromJSONTyped(json, false);
}

export function CustomerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerStatus {
    return json as CustomerStatus;
}

export function CustomerStatusToJSON(value?: CustomerStatus | null): any {
    return value as any;
}

