/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { TicketStatus } from './TicketStatus';
import {
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';

/**
 * 
 * @export
 * @interface TicketListDto
 */
export interface TicketListDto {
    /**
     * 
     * @type {number}
     * @memberof TicketListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketListDto
     */
    customerId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TicketListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * 
     * @type {number}
     * @memberof TicketListDto
     */
    createdById?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketListDto
     */
    modifiedById?: number;
    /**
     * 
     * @type {Date}
     * @memberof TicketListDto
     */
    modifiedAt?: dayjs.Dayjs;
    /**
     * 
     * @type {string}
     * @memberof TicketListDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketListDto
     */
    message?: string | null;
    /**
     * 
     * @type {TicketStatus}
     * @memberof TicketListDto
     */
    status?: TicketStatus;
    /**
     * 
     * @type {string}
     * @memberof TicketListDto
     */
    gitIssue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketListDto
     */
    contactInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketListDto
     */
    createdByFullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketListDto
     */
    modifiedByFullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketListDto
     */
    customerName?: string | null;
}

/**
 * Check if a given object implements the TicketListDto interface.
 */
export function instanceOfTicketListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketListDtoFromJSON(json: any): TicketListDto {
    return TicketListDtoFromJSONTyped(json, false);
}

export function TicketListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'modifiedById': !exists(json, 'modifiedById') ? undefined : json['modifiedById'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
        'gitIssue': !exists(json, 'gitIssue') ? undefined : json['gitIssue'],
        'contactInfo': !exists(json, 'contactInfo') ? undefined : json['contactInfo'],
        'createdByFullName': !exists(json, 'createdByFullName') ? undefined : json['createdByFullName'],
        'modifiedByFullName': !exists(json, 'modifiedByFullName') ? undefined : json['modifiedByFullName'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
    };
}

export function TicketListDtoToJSON(value?: TicketListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'customerId': value.customerId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdById': value.createdById,
        'modifiedById': value.modifiedById,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'subject': value.subject,
        'message': value.message,
        'status': TicketStatusToJSON(value.status),
        'gitIssue': value.gitIssue,
        'contactInfo': value.contactInfo,
        'createdByFullName': value.createdByFullName,
        'modifiedByFullName': value.modifiedByFullName,
        'customerName': value.customerName,
    };
}

