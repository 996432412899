/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  TicketDto,
  TicketListDto,
  TicketListDtoOffsetPaginationResult,
  TicketStatus,
  TicketStatusCountDto,
  TicketUpdateDto,
  ValidationProblemDetails,
} from '../models';
import {
    TicketDtoFromJSON,
    TicketDtoToJSON,
    TicketListDtoFromJSON,
    TicketListDtoToJSON,
    TicketListDtoOffsetPaginationResultFromJSON,
    TicketListDtoOffsetPaginationResultToJSON,
    TicketStatusFromJSON,
    TicketStatusToJSON,
    TicketStatusCountDtoFromJSON,
    TicketStatusCountDtoToJSON,
    TicketUpdateDtoFromJSON,
    TicketUpdateDtoToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteTicketRequest {
    id: number;
    tenantId?: number;
}

export interface GetTicketRequest {
    id: number;
}

export interface GetTicketStatusCountRequest {
    tenantId?: number;
}

export interface GetTicketsRequest {
    status?: TicketStatus;
    tenantId?: number;
}

export interface GetTicketsPaginationRequest {
    status?: TicketStatus;
    tenantId?: number;
    pageSize?: number;
    page?: number;
}

export interface PostTicketRequest {
    ticketDto?: TicketDto;
}

export interface PutTicketRequest {
    id: number;
    ticketUpdateDto?: TicketUpdateDto;
}

/**
 * TicketApi - interface
 * 
 * @export
 * @interface TicketApiInterface
 */
export interface TicketApiInterface {
    /**
     * 
     * @summary  (Login)
     * @param {number} id 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiInterface
     */
    deleteTicketRaw(requestParameters: DeleteTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDto>>;

    /**
     *  (Login)
     */
    deleteTicket(requestParameters: DeleteTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDto>;

    /**
     * 
     * @summary  (Login)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiInterface
     */
    getTicketRaw(requestParameters: GetTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDto>>;

    /**
     *  (Login)
     */
    getTicket(requestParameters: GetTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDto>;

    /**
     * 
     * @summary  (Login)
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiInterface
     */
    getTicketStatusCountRaw(requestParameters: GetTicketStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketStatusCountDto>>;

    /**
     *  (Login)
     */
    getTicketStatusCount(requestParameters: GetTicketStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketStatusCountDto>;

    /**
     * 
     * @summary  (Login)
     * @param {TicketStatus} [status] 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiInterface
     */
    getTicketsRaw(requestParameters: GetTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TicketListDto>>>;

    /**
     *  (Login)
     */
    getTickets(requestParameters: GetTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TicketListDto>>;

    /**
     * 
     * @summary  (Login)
     * @param {TicketStatus} [status] 
     * @param {number} [tenantId] 
     * @param {number} [pageSize] 
     * @param {number} [page] The page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiInterface
     */
    getTicketsPaginationRaw(requestParameters: GetTicketsPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDtoOffsetPaginationResult>>;

    /**
     *  (Login)
     */
    getTicketsPagination(requestParameters: GetTicketsPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDtoOffsetPaginationResult>;

    /**
     * 
     * @summary  (Login)
     * @param {TicketDto} [ticketDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiInterface
     */
    postTicketRaw(requestParameters: PostTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDto>>;

    /**
     *  (Login)
     */
    postTicket(requestParameters: PostTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDto>;

    /**
     * 
     * @summary  (Login)
     * @param {number} id 
     * @param {TicketUpdateDto} [ticketUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiInterface
     */
    putTicketRaw(requestParameters: PutTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     *  (Login)
     */
    putTicket(requestParameters: PutTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class TicketApi extends runtime.BaseAPI implements TicketApiInterface {

    /**
     *  (Login)
     */
    async deleteTicketRaw(requestParameters: DeleteTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTicket.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/Ticket/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketListDtoFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async deleteTicket(requestParameters: DeleteTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDto> {
        const response = await this.deleteTicketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async getTicketRaw(requestParameters: GetTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTicket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/Ticket/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketListDtoFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async getTicket(requestParameters: GetTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDto> {
        const response = await this.getTicketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async getTicketStatusCountRaw(requestParameters: GetTicketStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketStatusCountDto>> {
        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/Ticket/StatusCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketStatusCountDtoFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async getTicketStatusCount(requestParameters: GetTicketStatusCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketStatusCountDto> {
        const response = await this.getTicketStatusCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async getTicketsRaw(requestParameters: GetTicketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TicketListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/Ticket`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TicketListDtoFromJSON));
    }

    /**
     *  (Login)
     */
    async getTickets(requestParameters: GetTicketsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TicketListDto>> {
        const response = await this.getTicketsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async getTicketsPaginationRaw(requestParameters: GetTicketsPaginationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDtoOffsetPaginationResult>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/Ticket/GetTicketsPagination`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketListDtoOffsetPaginationResultFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async getTicketsPagination(requestParameters: GetTicketsPaginationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDtoOffsetPaginationResult> {
        const response = await this.getTicketsPaginationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async postTicketRaw(requestParameters: PostTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TicketListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/Ticket`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TicketDtoToJSON(requestParameters.ticketDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketListDtoFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async postTicket(requestParameters: PostTicketRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TicketListDto> {
        const response = await this.postTicketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async putTicketRaw(requestParameters: PutTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putTicket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ticketSystem/Ticket/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TicketUpdateDtoToJSON(requestParameters.ticketUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  (Login)
     */
    async putTicket(requestParameters: PutTicketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putTicketRaw(requestParameters, initOverrides);
    }

}


export function useTicketApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new TicketApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new TicketApi(apiConfig["ETL-API"])
}
