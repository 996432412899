/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { CustomerStatus } from './CustomerStatus';
import {
    CustomerStatusFromJSON,
    CustomerStatusFromJSONTyped,
    CustomerStatusToJSON,
} from './CustomerStatus';

/**
 * 
 * @export
 * @interface CustomerUpdateDto
 */
export interface CustomerUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerUpdateDto
     */
    kokpitCustomerId?: number | null;
    /**
     * Kod
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    code?: string | null;
    /**
     * İsim
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    name?: string | null;
    /**
     * Blokeli
     * @type {boolean}
     * @memberof CustomerUpdateDto
     */
    isBlocked?: boolean | null;
    /**
     * İletişim İsmi
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    contactName?: string | null;
    /**
     * Telefon No
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    phone?: string | null;
    /**
     * Vergi İsmi
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    taxName?: string | null;
    /**
     * Vergi Numarası
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    taxNumber?: string | null;
    /**
     * Faks
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    fax?: string | null;
    /**
     * E-posta
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    email?: string | null;
    /**
     * Adres
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    address?: string | null;
    /**
     * Şehir
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    city?: string | null;
    /**
     * Ülke
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    country?: string | null;
    /**
     * Borç
     * @type {number}
     * @memberof CustomerUpdateDto
     */
    debt?: number | null;
    /**
     * 
     * @type {CustomerStatus}
     * @memberof CustomerUpdateDto
     */
    status?: CustomerStatus;
    /**
     * Not
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    note?: string | null;
    /**
     * 2. Email
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    email2?: string | null;
    /**
     * Gsm No
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    msisdn?: string | null;
    /**
     * Logo
     * @type {string}
     * @memberof CustomerUpdateDto
     */
    logo?: string | null;
    /**
     * Ortam Id
     * @type {number}
     * @memberof CustomerUpdateDto
     */
    kokpitEnvironmentId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerUpdateDto
     */
    isPrivate?: boolean;
    /**
     * Müşteri Kanalı Id
     * @type {number}
     * @memberof CustomerUpdateDto
     */
    customerChannelId?: number | null;
}

/**
 * Check if a given object implements the CustomerUpdateDto interface.
 */
export function instanceOfCustomerUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerUpdateDtoFromJSON(json: any): CustomerUpdateDto {
    return CustomerUpdateDtoFromJSONTyped(json, false);
}

export function CustomerUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'kokpitCustomerId': !exists(json, 'kokpitCustomerId') ? undefined : json['kokpitCustomerId'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isBlocked': !exists(json, 'isBlocked') ? undefined : json['isBlocked'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'taxName': !exists(json, 'taxName') ? undefined : json['taxName'],
        'taxNumber': !exists(json, 'taxNumber') ? undefined : json['taxNumber'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'debt': !exists(json, 'debt') ? undefined : json['debt'],
        'status': !exists(json, 'status') ? undefined : CustomerStatusFromJSON(json['status']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'kokpitEnvironmentId': !exists(json, 'kokpitEnvironmentId') ? undefined : json['kokpitEnvironmentId'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'customerChannelId': !exists(json, 'customerChannelId') ? undefined : json['customerChannelId'],
    };
}

export function CustomerUpdateDtoToJSON(value?: CustomerUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'kokpitCustomerId': value.kokpitCustomerId,
        'code': value.code,
        'name': value.name,
        'isBlocked': value.isBlocked,
        'contactName': value.contactName,
        'phone': value.phone,
        'taxName': value.taxName,
        'taxNumber': value.taxNumber,
        'fax': value.fax,
        'email': value.email,
        'address': value.address,
        'city': value.city,
        'country': value.country,
        'debt': value.debt,
        'status': CustomerStatusToJSON(value.status),
        'note': value.note,
        'email2': value.email2,
        'msisdn': value.msisdn,
        'logo': value.logo,
        'kokpitEnvironmentId': value.kokpitEnvironmentId,
        'isPrivate': value.isPrivate,
        'customerChannelId': value.customerChannelId,
    };
}

