import { Button, Container, Paper } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router-dom"
import { IBaseProps } from "./types"

interface ContentProps {
    eventId: any
}

const Content = (props: ContentProps) => {
    const { t } = useTranslation()

    return (
        <Container
            component="main"
            sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                width: "auto",
                display: "block", // Fix IE 11 issue.
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
                [theme.breakpoints.up(400 + 24 * 2)]: {
                    width: 400,
                    marginLeft: "auto",
                    marginRight: "auto",
                },
            })}
        >
            <Paper
                sx={(theme) => ({
                    marginTop: theme.spacing(8),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
                })}
            >
                <h4 className="pt-3">{t("somethingBadHappened")}</h4>
                {/* <Button
                onClick={() => {
                    window.location.href = '/'
                }}
            >
                {t('goHomePage')}
            </Button>
            */}
                <Button onClick={() => Sentry.showReportDialog({ eventId: props.eventId, lang: "tr" })}>
                    Hatayı bildir
                </Button>
            </Paper>
        </Container>
    )
}

export const ErrorBoundaryRoute = (props) => {
    const [eventId, seteventId] = useState("")
    let error = useRouteError()
    useEffect(() => {
        Sentry.withScope((scope) => {
            scope.setExtras(error as any)
            const eventId = Sentry.captureException(error)
            seteventId(eventId)
        })
    }, [error, seteventId])

    return <Content eventId={eventId} />
}

class ErrorBoundary extends React.Component<IBaseProps, { hasError: boolean; eventId: any }> {
    constructor(props) {
        super(props)
        this.state = { hasError: false, eventId: null }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true })
        // You can also log the error to an error reporting service
        console.log(error, info)
        Sentry.withScope((scope) => {
            scope.setExtras(info)
            const eventId = Sentry.captureException(error)
            this.setState({ eventId })
        })
    }

    render() {
        const t = this.props.t!
        if (this.state.hasError) {
            return <Content eventId={this.state.eventId} />
        }
        return this.props.children
    }
}
//@ts-ignore
export default ErrorBoundary
