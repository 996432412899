/* eslint-disable no-prototype-builtins */
import i18n from "../i18n"
import { lowerCaseFirstLetter } from "./string"

const localizeErrors = (error: string | undefined) => {
    if (error === undefined) {
        return i18n.t(`common.errors.unkownError`)
    } else if (error.startsWith("Max")) {
        const count = error.slice(3)
        return i18n.t(`common.errors.maxError`, { cc: count })
    } else if (error.startsWith("@")) {
        return error.slice(1)
    } else {
        return i18n.t(`common.errors.${error}`)
    }
}

const formatErrors = (ValidationProblemDetails): any => {
    if (ValidationProblemDetails && ValidationProblemDetails.errors) {
        const errors = {}
        for (const key in ValidationProblemDetails.errors) {
            if (ValidationProblemDetails.errors.hasOwnProperty(key)) {
                const value = ValidationProblemDetails.errors[key]
                errors[lowerCaseFirstLetter(key)] = localizeErrors(value[0])
            } else {
                // do nothing
            }
        }

        for (const key in errors) {
            if (key.includes("].")) {
                const names = []
                const title = key.split("[")[0]
                const value = key.split("].")[1]

                const index = key.substring(key.indexOf("[") + 1, key.lastIndexOf("]"))
                names[index] = { [`${value}`]: errors[key] }
                errors[title] = names
            }
        }
        return errors
    } else {
        return {}
    }
}
export default formatErrors

export const formatIdentityErrors = (identityResult) => {
    if (identityResult && identityResult.errors) {
        const errors = {}
        identityResult.errors.forEach((element) => {
            if (element.code === "DuplicateUserName") {
                errors["userName"] = i18n.t(`common.errors.AlreadyExist`)
            }
        })
        return errors
    } else {
        return {}
    }
}

export const formatAllErrors = (ValidationProblemDetails) => {
    if (ValidationProblemDetails && ValidationProblemDetails.errors) {
        const errors = {}
        for (const key in ValidationProblemDetails.errors) {
            if (ValidationProblemDetails.errors.hasOwnProperty(key)) {
                const value = ValidationProblemDetails.errors[key]
                errors[lowerCaseFirstLetter(key)] = value.map((v) => localizeErrors(v))
            } else {
                // do nothing
            }
        }
        return errors
    } else {
        return {}
    }
}
