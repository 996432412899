/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UploadResultDTO
 */
export interface UploadResultDTO {
    /**
     * 
     * @type {string}
     * @memberof UploadResultDTO
     */
    filePath?: string | null;
}

/**
 * Check if a given object implements the UploadResultDTO interface.
 */
export function instanceOfUploadResultDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadResultDTOFromJSON(json: any): UploadResultDTO {
    return UploadResultDTOFromJSONTyped(json, false);
}

export function UploadResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filePath': !exists(json, 'filePath') ? undefined : json['filePath'],
    };
}

export function UploadResultDTOToJSON(value?: UploadResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filePath': value.filePath,
    };
}

