/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  BooleanResultDto,
  NotificationDevice,
  NotificationMessage,
  ValidationProblemDetails,
} from '../models';
import {
    BooleanResultDtoFromJSON,
    BooleanResultDtoToJSON,
    NotificationDeviceFromJSON,
    NotificationDeviceToJSON,
    NotificationMessageFromJSON,
    NotificationMessageToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface KokpitSendAllRequest {
    customerId?: string;
    message?: string;
}

export interface KokpitSendUserRequest {
    userID?: string;
    message?: string;
}

export interface NotificationMessageGetRequest {
    id: string;
}

export interface RegisterRequest {
    app?: string;
    token?: string;
    deviceType?: NotificationDevice;
}

export interface TekerSendAllRequest {
    message?: string;
}

export interface TekerSendUserRequest {
    userID?: string;
    message?: string;
}

export interface UnregisterRequest {
    token?: string;
}

export interface UserHasNotificationRegistrationRequest {
    token?: string;
}

/**
 * PushApi - interface
 * 
 * @export
 * @interface PushApiInterface
 */
export interface PushApiInterface {
    /**
     * 
     * @summary  (Login)
     * @param {string} [customerId] 
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    kokpitSendAllRaw(requestParameters: KokpitSendAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     *  (Login)
     */
    kokpitSendAll(requestParameters: KokpitSendAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary  (Login)
     * @param {string} [userID] 
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    kokpitSendUserRaw(requestParameters: KokpitSendUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     *  (Login)
     */
    kokpitSendUser(requestParameters: KokpitSendUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    notificationMessageGetRaw(requestParameters: NotificationMessageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationMessage>>;

    /**
     */
    notificationMessageGet(requestParameters: NotificationMessageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationMessage>;

    /**
     * 
     * @summary  (Login)
     * @param {string} [app] 
     * @param {string} [token] 
     * @param {NotificationDevice} [deviceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    registerRaw(requestParameters: RegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     *  (Login)
     */
    register(requestParameters: RegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary  (Login)
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    tekerSendAllRaw(requestParameters: TekerSendAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     *  (Login)
     */
    tekerSendAll(requestParameters: TekerSendAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary  (Login)
     * @param {string} [userID] 
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    tekerSendUserRaw(requestParameters: TekerSendUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     *  (Login)
     */
    tekerSendUser(requestParameters: TekerSendUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary  (Login)
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    unregisterRaw(requestParameters: UnregisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     *  (Login)
     */
    unregister(requestParameters: UnregisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary  (Login)
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApiInterface
     */
    userHasNotificationRegistrationRaw(requestParameters: UserHasNotificationRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResultDto>>;

    /**
     *  (Login)
     */
    userHasNotificationRegistration(requestParameters: UserHasNotificationRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResultDto>;

}

/**
 * 
 */
export class PushApi extends runtime.BaseAPI implements PushApiInterface {

    /**
     *  (Login)
     */
    async kokpitSendAllRaw(requestParameters: KokpitSendAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/kokpit/sendAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  (Login)
     */
    async kokpitSendAll(requestParameters: KokpitSendAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.kokpitSendAllRaw(requestParameters, initOverrides);
    }

    /**
     *  (Login)
     */
    async kokpitSendUserRaw(requestParameters: KokpitSendUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.userID !== undefined) {
            queryParameters['userID'] = requestParameters.userID;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/kokpit/sendUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  (Login)
     */
    async kokpitSendUser(requestParameters: KokpitSendUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.kokpitSendUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notificationMessageGetRaw(requestParameters: NotificationMessageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationMessageGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/notificationmessage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationMessageFromJSON(jsonValue));
    }

    /**
     */
    async notificationMessageGet(requestParameters: NotificationMessageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationMessage> {
        const response = await this.notificationMessageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *  (Login)
     */
    async registerRaw(requestParameters: RegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.app !== undefined) {
            queryParameters['app'] = requestParameters.app;
        }

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.deviceType !== undefined) {
            queryParameters['deviceType'] = requestParameters.deviceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  (Login)
     */
    async register(requestParameters: RegisterRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerRaw(requestParameters, initOverrides);
    }

    /**
     *  (Login)
     */
    async tekerSendAllRaw(requestParameters: TekerSendAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/teker/sendAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  (Login)
     */
    async tekerSendAll(requestParameters: TekerSendAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tekerSendAllRaw(requestParameters, initOverrides);
    }

    /**
     *  (Login)
     */
    async tekerSendUserRaw(requestParameters: TekerSendUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.userID !== undefined) {
            queryParameters['userID'] = requestParameters.userID;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/teker/sendUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  (Login)
     */
    async tekerSendUser(requestParameters: TekerSendUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tekerSendUserRaw(requestParameters, initOverrides);
    }

    /**
     *  (Login)
     */
    async unregisterRaw(requestParameters: UnregisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/unregister`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  (Login)
     */
    async unregister(requestParameters: UnregisterRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unregisterRaw(requestParameters, initOverrides);
    }

    /**
     *  (Login)
     */
    async userHasNotificationRegistrationRaw(requestParameters: UserHasNotificationRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/Push/UserHasNotificationRegistration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResultDtoFromJSON(jsonValue));
    }

    /**
     *  (Login)
     */
    async userHasNotificationRegistration(requestParameters: UserHasNotificationRegistrationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResultDto> {
        const response = await this.userHasNotificationRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}


export function usePushApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new PushApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new PushApi(apiConfig["ETL-API"])
}
