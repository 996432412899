/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { TicketStatus } from './TicketStatus';
import {
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';

/**
 * 
 * @export
 * @interface TicketMessageListDto
 */
export interface TicketMessageListDto {
    /**
     * 
     * @type {number}
     * @memberof TicketMessageListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketMessageListDto
     */
    modifiedById?: number;
    /**
     * 
     * @type {Date}
     * @memberof TicketMessageListDto
     */
    modifiedAt?: dayjs.Dayjs;
    /**
     * 
     * @type {number}
     * @memberof TicketMessageListDto
     */
    createdById?: number;
    /**
     * 
     * @type {Date}
     * @memberof TicketMessageListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * 
     * @type {number}
     * @memberof TicketMessageListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketMessageListDto
     */
    ticketId?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketMessageListDto
     */
    message?: string | null;
    /**
     * 
     * @type {TicketStatus}
     * @memberof TicketMessageListDto
     */
    ticketStatus?: TicketStatus;
    /**
     * 
     * @type {boolean}
     * @memberof TicketMessageListDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TicketMessageListDto
     */
    createdByFullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketMessageListDto
     */
    modifiedByFullName?: string | null;
}

/**
 * Check if a given object implements the TicketMessageListDto interface.
 */
export function instanceOfTicketMessageListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketMessageListDtoFromJSON(json: any): TicketMessageListDto {
    return TicketMessageListDtoFromJSONTyped(json, false);
}

export function TicketMessageListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketMessageListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'modifiedById': !exists(json, 'modifiedById') ? undefined : json['modifiedById'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'ticketStatus': !exists(json, 'ticketStatus') ? undefined : TicketStatusFromJSON(json['ticketStatus']),
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'createdByFullName': !exists(json, 'createdByFullName') ? undefined : json['createdByFullName'],
        'modifiedByFullName': !exists(json, 'modifiedByFullName') ? undefined : json['modifiedByFullName'],
    };
}

export function TicketMessageListDtoToJSON(value?: TicketMessageListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'modifiedById': value.modifiedById,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'createdById': value.createdById,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'tenantId': value.tenantId,
        'ticketId': value.ticketId,
        'message': value.message,
        'ticketStatus': TicketStatusToJSON(value.ticketStatus),
        'isDeleted': value.isDeleted,
        'createdByFullName': value.createdByFullName,
        'modifiedByFullName': value.modifiedByFullName,
    };
}

