if (!window.__RUNTIME_CONFIG__) {
    try {
        window.__RUNTIME_CONFIG__ = JSON.parse(localStorage.getItem("runtime") ?? "{}")
    } catch {
        //@ts-ignore
        window.__RUNTIME_CONFIG__ = {}
        localStorage.removeItem("runtime")
    }
} else {
    localStorage.setItem("runtime", JSON.stringify(window.__RUNTIME_CONFIG__))
}

const config = {
    base_url: window.__RUNTIME_CONFIG__.base_url,
    api_url: window.__RUNTIME_CONFIG__.api_url,
    api_report_url: window.__RUNTIME_CONFIG__.api_report_url,
    //api_url: "https://panel.tnbmobil.com.tr",
    //api_report_url: "https://panel.tnbmobil.com.tr",
    devtakip_api_url: window.__RUNTIME_CONFIG__.devtakip_api_url,
    staticFile_url: window.__RUNTIME_CONFIG__.staticFile_url,
    //staticFile_url: "http://localhost:5050",
    tokenKey: window.__RUNTIME_CONFIG__.tokenKey ?? "lwqunvh",
    devtakip_ui_url: window.__RUNTIME_CONFIG__.devtakip_ui_url,
    sds_url: window.__RUNTIME_CONFIG__.sds_url,
}

export default config
