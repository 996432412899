import { ThemeOptions, alpha, lighten } from "@mui/material"

const baseTheme: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1440,
            xxl: 1650,
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                root: {
                    "& .MuiDialog-paperFullScreen": {
                        boxShadow: "none",
                    },
                },
            },
            defaultProps: {
                hideBackdrop: true,
            },
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
                sizeSmall: {
                    padding: "6px 16px",
                },
                sizeMedium: {
                    padding: "8px 20px",
                },
                sizeLarge: {
                    padding: "11px 24px",
                },
                textSizeSmall: {
                    padding: "7px 12px",
                },
                textSizeMedium: {
                    padding: "9px 16px",
                },
                textSizeLarge: {
                    padding: "12px 16px",
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "32px 24px",
                    "&:last-child": {
                        paddingBottom: "32px",
                    },
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: "h6",
                },
                subheaderTypographyProps: {
                    variant: "body2",
                },
            },
            styleOverrides: {
                root: {
                    padding: "32px 24px",
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: 8,

                    "& .MuiSwitch-track": {
                        borderRadius: 22 / 2,
                        "&:before, &:after": {
                            content: '""',
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: 16,
                            height: 16,
                        },
                        "&:before": {
                            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                                theme.palette.getContrastText(theme.palette.primary.main)
                            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                            left: 12,
                        },

                        "& .MuiSwitch-thumb": {
                            boxShadow: "none",
                            width: 16,
                            height: 16,
                            margin: 2,
                        },
                    },
                }),
            },
        },
        MuiCssBaseline: {
            defaultProps: {
                enableColorScheme: true,
            },
            styleOverrides: (theme) => ({
                "*": {
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                },
                [theme.breakpoints.up("md")]: {
                    html: {
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar ": {
                            width: "8px",
                            height: "8px",
                            backgroundColor: "rgba(0, 0, 0, 0)",
                        },

                        "&::-webkit-scrollbar:hover, & *::-webkit-scrollbar:hover": {
                            width: "8px",
                            height: "8px",
                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                        },

                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                            border: "2px solid transparent",
                            borderRadius: "20px",
                        },

                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                            borderRadius: "20px",
                        },
                    },
                },
                html: {
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        boxShadow: `inset 0 0 0 20px ${
                            theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.24)" : "rgba(255, 255, 255, 0.24)"
                        }`,
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        boxShadow: `inset 0 0 0 20px ${
                            theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.37)" : "rgba(255, 255, 255, 0.37)"
                        }`,
                    },
                },
                body: {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%",
                },
                "#root": {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                },
                "leaflet-container": {
                    height: "100%",
                    width: "100%",
                },
            }),
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: ({ theme }) => ({
                    borderColor:
                        theme.palette.mode === "light" ? lighten(alpha(theme.palette.divider, 1), 0.88) : "#2d3748",
                }),
            },
        },
    },
    shape: {
        borderRadius: 4,
    },
    shadows: [
        "none",
        "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
        "0px 1px 2px rgba(100, 116, 139, 0.12)",
        "0px 1px 4px rgba(100, 116, 139, 0.12)",
        "0px 1px 5px rgba(100, 116, 139, 0.12)",
        "0px 1px 6px rgba(100, 116, 139, 0.12)",
        "0px 2px 6px rgba(100, 116, 139, 0.12)",
        "0px 3px 6px rgba(100, 116, 139, 0.12)",
        "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
        "0px 5px 12px rgba(100, 116, 139, 0.12)",
        "0px 5px 14px rgba(100, 116, 139, 0.12)",
        "0px 5px 15px rgba(100, 116, 139, 0.12)",
        "0px 6px 15px rgba(100, 116, 139, 0.12)",
        "0px 7px 15px rgba(100, 116, 139, 0.12)",
        "0px 8px 15px rgba(100, 116, 139, 0.12)",
        "0px 9px 15px rgba(100, 116, 139, 0.12)",
        "0px 10px 15px rgba(100, 116, 139, 0.12)",
        "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
        "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
        "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
        "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
    ],
    typography: {
        fontFamily:
            '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: 1.57,
        },
        button: {
            fontWeight: 600,
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 500,
            lineHeight: 1.66,
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: 1.57,
        },
        subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: 1.57,
        },
        overline: {
            fontSize: "0.75rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: 2.5,
            textTransform: "uppercase",
        },
        h1: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: "3.5rem",
            lineHeight: 1.2,
        },
        h2: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: "3rem",
            lineHeight: 1.2,
        },
        h3: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: "2.25rem",
            lineHeight: 1.2,
        },
        h4: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: "2rem",
            lineHeight: 1.2,
        },
        h5: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: "1.5rem",
            lineHeight: 1.2,
        },
        h6: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 700,
            fontSize: "1.125rem",
            lineHeight: 1.2,
        },
    },
}

export default baseTheme
