/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { KokpitEnviromentType } from './KokpitEnviromentType';
import {
    KokpitEnviromentTypeFromJSON,
    KokpitEnviromentTypeFromJSONTyped,
    KokpitEnviromentTypeToJSON,
} from './KokpitEnviromentType';

/**
 * 
 * @export
 * @interface KokpitEnvironmentListDto
 */
export interface KokpitEnvironmentListDto {
    /**
     * Id
     * @type {number}
     * @memberof KokpitEnvironmentListDto
     */
    id?: number;
    /**
     * Tenant Id
     * @type {number}
     * @memberof KokpitEnvironmentListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {string}
     * @memberof KokpitEnvironmentListDto
     */
    name?: string | null;
    /**
     * Kokpit arayüz Urlsi
     * @type {string}
     * @memberof KokpitEnvironmentListDto
     */
    url?: string | null;
    /**
     * Kokpit Api Urlsi
     * @type {string}
     * @memberof KokpitEnvironmentListDto
     */
    apiUrl?: string | null;
    /**
     * Kokpit Api için kullanılacak token (Kokpit customerId 1 olan (tüm müşterileir görecek))
     * @type {string}
     * @memberof KokpitEnvironmentListDto
     */
    token?: string | null;
    /**
     * 
     * @type {KokpitEnviromentType}
     * @memberof KokpitEnvironmentListDto
     */
    type?: KokpitEnviromentType;
    /**
     * Kokpit lisans anahtarı
     * @type {string}
     * @memberof KokpitEnvironmentListDto
     */
    licenseKey?: string | null;
    /**
     * Soft Delete Bit
     * @type {boolean}
     * @memberof KokpitEnvironmentListDto
     */
    isDeleted?: boolean;
    /**
     * Ekleyenin Tam İsmi
     * @type {string}
     * @memberof KokpitEnvironmentListDto
     */
    createdByFullName?: string | null;
    /**
     * Güncelleyenin Tam İsmi
     * @type {string}
     * @memberof KokpitEnvironmentListDto
     */
    modifiedByFullName?: string | null;
    /**
     * Müşteri Sayısı
     * @type {number}
     * @memberof KokpitEnvironmentListDto
     */
    customerCount?: number | null;
    /**
     * Eklenme Tarihi
     * @type {Date}
     * @memberof KokpitEnvironmentListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * Güncelleme Tarihi
     * @type {Date}
     * @memberof KokpitEnvironmentListDto
     */
    modifiedAt?: dayjs.Dayjs;
}

/**
 * Check if a given object implements the KokpitEnvironmentListDto interface.
 */
export function instanceOfKokpitEnvironmentListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KokpitEnvironmentListDtoFromJSON(json: any): KokpitEnvironmentListDto {
    return KokpitEnvironmentListDtoFromJSONTyped(json, false);
}

export function KokpitEnvironmentListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KokpitEnvironmentListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'apiUrl': !exists(json, 'apiUrl') ? undefined : json['apiUrl'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'type': !exists(json, 'type') ? undefined : KokpitEnviromentTypeFromJSON(json['type']),
        'licenseKey': !exists(json, 'licenseKey') ? undefined : json['licenseKey'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'createdByFullName': !exists(json, 'createdByFullName') ? undefined : json['createdByFullName'],
        'modifiedByFullName': !exists(json, 'modifiedByFullName') ? undefined : json['modifiedByFullName'],
        'customerCount': !exists(json, 'customerCount') ? undefined : json['customerCount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

    };
}

export function KokpitEnvironmentListDtoToJSON(value?: KokpitEnvironmentListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'name': value.name,
        'url': value.url,
        'apiUrl': value.apiUrl,
        'token': value.token,
        'type': KokpitEnviromentTypeToJSON(value.type),
        'licenseKey': value.licenseKey,
        'isDeleted': value.isDeleted,
        'createdByFullName': value.createdByFullName,
        'modifiedByFullName': value.modifiedByFullName,
        'customerCount': value.customerCount,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
    };
}

