import { Alert, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IFormUpdateSuccesAlertProps {
    label?: string
}
const FormUpdateSuccesAlert: React.FC<IFormUpdateSuccesAlertProps> = (props) => {
    const { t } = useTranslation()
    const { label = t("common.updateSucceeded") } = props

    return (
        <Grid item xs={12}>
            <Alert severity="success">{label}</Alert>
        </Grid>
    )
}

export default FormUpdateSuccesAlert
