import NiceModal from "@ebay/nice-modal-react"
import { Breakpoint, Container } from "@mui/material"
import { PropsWithChildren } from "react"

/**
 * {@link AppContent} props
 */
interface IAppContentProps extends PropsWithChildren {
    /**
     * Containerin max genişliği
     * @defaultValue `false`
     */
    maxWidth?: Breakpoint | false
    className?: string
}
/**
 * Sayfanın ana {@link Container}  elamanı
 * sayfada sadece bir tane bulunmalı
 */
function AppContent(props: IAppContentProps) {
    const { children, maxWidth } = props

    return (
        <NiceModal.Provider>
            <Container
                component="main"
                id="main-content"
                maxWidth={maxWidth}
                sx={(theme) => ({
                    paddingTop: "calc(var(--Kokpit-header-height, 0px) + 16px)",
                    paddingBottom: theme.spacing(1),
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100%",

                    [theme.breakpoints.up("xl")]: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                    },
                })}
            >
                {children}
            </Container>
        </NiceModal.Provider>
    )
}

AppContent.defaultProps = {
    maxWidth: false,
}

export default AppContent
