import * as Sentry from "@sentry/react"
import { useOwnTenantWithFeatures } from "stores/UserStore"

/**
 * Verilen özellik kullanıcının müşterisinde aktif mi kontrol eder
 * @param feature kontrol edilecek özellik
 * @returns
 */
export const useHasFeature = function useHasFeature(feature: string, enabled = true) {
    const { isFetching, data } = useOwnTenantWithFeatures({
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 15,
        enabled: enabled,
    })
    if (enabled)
        Sentry.addBreadcrumb({
            category: "feature",
            message: `feature check ${feature}:${data?.features ? data?.features.includes(feature) : false}`,
            level: "debug",
        })
    return { isFetching, result: data?.features ? data?.features.includes(feature) : false }
}
