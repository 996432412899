/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface BooleanResultDto
 */
export interface BooleanResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanResultDto
     */
    result?: boolean;
}

/**
 * Check if a given object implements the BooleanResultDto interface.
 */
export function instanceOfBooleanResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BooleanResultDtoFromJSON(json: any): BooleanResultDto {
    return BooleanResultDtoFromJSONTyped(json, false);
}

export function BooleanResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function BooleanResultDtoToJSON(value?: BooleanResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
    };
}

