/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface CustomerChannelListDto
 */
export interface CustomerChannelListDto {
    /**
     * Id
     * @type {number}
     * @memberof CustomerChannelListDto
     */
    id?: number;
    /**
     * İsim
     * @type {string}
     * @memberof CustomerChannelListDto
     */
    name?: string | null;
    /**
     * Tenant Id
     * @type {number}
     * @memberof CustomerChannelListDto
     */
    tenantId?: number;
    /**
     * Soft Delete Bit
     * @type {boolean}
     * @memberof CustomerChannelListDto
     */
    isDeleted?: boolean;
    /**
     * Ekleyenin Tam İsmi
     * @type {string}
     * @memberof CustomerChannelListDto
     */
    createdByFullName?: string | null;
    /**
     * Güncelleyenin Tam İsmi
     * @type {string}
     * @memberof CustomerChannelListDto
     */
    modifiedByFullName?: string | null;
    /**
     * Müşteri Sayısı
     * @type {number}
     * @memberof CustomerChannelListDto
     */
    customerCount?: number | null;
    /**
     * Eklenme Tarihi
     * @type {Date}
     * @memberof CustomerChannelListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * Güncelleme Tarihi
     * @type {Date}
     * @memberof CustomerChannelListDto
     */
    modifiedAt?: dayjs.Dayjs;
}

/**
 * Check if a given object implements the CustomerChannelListDto interface.
 */
export function instanceOfCustomerChannelListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerChannelListDtoFromJSON(json: any): CustomerChannelListDto {
    return CustomerChannelListDtoFromJSONTyped(json, false);
}

export function CustomerChannelListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerChannelListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'createdByFullName': !exists(json, 'createdByFullName') ? undefined : json['createdByFullName'],
        'modifiedByFullName': !exists(json, 'modifiedByFullName') ? undefined : json['modifiedByFullName'],
        'customerCount': !exists(json, 'customerCount') ? undefined : json['customerCount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

    };
}

export function CustomerChannelListDtoToJSON(value?: CustomerChannelListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'tenantId': value.tenantId,
        'isDeleted': value.isDeleted,
        'createdByFullName': value.createdByFullName,
        'modifiedByFullName': value.modifiedByFullName,
        'customerCount': value.customerCount,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
    };
}

