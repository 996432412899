/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UserNameListDto
 */
export interface UserNameListDto {
    /**
     * 
     * @type {string}
     * @memberof UserNameListDto
     */
    img?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserNameListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserNameListDto
     */
    name?: string | null;
}

/**
 * Check if a given object implements the UserNameListDto interface.
 */
export function instanceOfUserNameListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserNameListDtoFromJSON(json: any): UserNameListDto {
    return UserNameListDtoFromJSONTyped(json, false);
}

export function UserNameListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNameListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'img': !exists(json, 'img') ? undefined : json['img'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function UserNameListDtoToJSON(value?: UserNameListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'img': value.img,
        'id': value.id,
        'name': value.name,
    };
}

