import i18n from "i18n"
import dayjs, { Dayjs } from "utils/dayjs"

export const masks = {
    tc: /^\d{11}$/,
    time: /^\d\d:\d\d$/,
    phoneNumber: /^\d{11}$/,
    text: /\D/,
    number: /^\d*[.,]?\d*$/,
    plate: /^(0[1-9]|[1-7][0-9]|8[01])(?:([A-Z])(\d{4,5})|([A-Z]{2})(\d{3,4})|([A-Z]{3})(\d{2,3}))$/,
    username: /^[0-9A-Za-z]*$/,
}

//https://tr.wikipedia.org/wiki/T%C3%BCrkiye_il_plaka_kodlar%C4%B1
export const plateCheck = (value) =>
    value ? (masks.plate.test(value) ? undefined : i18n.t("common.errors.PlateFormatError")) : undefined
export const tcCheck = (value) => (value ? (masks.tc.test(value) ? undefined : i18n.t("common.errors.tc")) : undefined)
export const phoneNumberCheck = (value) =>
    value ? (value.length === 13) ? undefined : i18n.t("common.errors.phoneNumber") : undefined
export const numberCheck = (value) =>
    value ? (masks.number.test(value) ? undefined : i18n.t("common.errors.number")) : undefined
export const textCheck = (value) =>
    value ? (masks.text.test(value) ? undefined : i18n.t("common.errors.text")) : undefined
export const requiredCheck = (value) => (value ? undefined : i18n.t("common.required"))
export const requiredMomment = (value) => (value && value.isValid() ? undefined : i18n.t("common.required"))
export const mustBeTime = (value) =>
    value ? (masks.time.test(value) ? undefined : i18n.t("common.mustBeTime")) : undefined
export const requiredArray = (value) => (value && value.length > 1 ? undefined : i18n.t("common.requiredMin2"))
export const passwordCheck = (value) => (value ? undefined : i18n.t("common.errors.password")) // TODO: add identity framework password checks here
export const userNameCheck = (value) =>
    value ? (masks.username.test(value) ? undefined : i18n.t("common.errors.usernameFormat")) : undefined
export const suffixCheck = (value) =>
    value ? (masks.username.test(value) ? undefined : i18n.t("common.errors.usernameFormat")) : undefined
export const equalPasswordCheck = (value1, value2) =>
    value2 ? (value1 === value2 ? undefined : i18n.t("common.errors.NotSame")) : undefined
export const passwordLengthCheck = (value) =>
    value && value.length > 5 ? undefined : i18n.t("common.errors.InsufficientCharacter") // TODO: add identity framework password checks here
export const bitLengthCheck = (value) =>
    value && value.length < 2 ? undefined : i18n.t("common.errors.BitLengthCheck") // TODO: add identity framework password checks here

export const dateStartEndCheck = (start, end) =>
    !start || !end || start < end ? undefined : i18n.t("common.errors.EndCanNotSmallerThanStart")
export const dateRangeCheckMaxOneYear = (start: Dayjs, end: Dayjs) =>
    dayjs.duration(1, "years") > dayjs.duration(end?.diff(start))
        ? undefined
        : i18n.t("common.errors.oneYearRangeError")
export const dateRangeCheckMaxOneMonth = (start: Dayjs, end: Dayjs) =>
    dayjs.duration(1, "months") > dayjs.duration(end?.diff(start))
        ? undefined
        : i18n.t("common.errors.oneMonthRangeError")
export const textFieldLengthCheck = (value: string, length: number) =>
    !value || value.length <= length ? undefined : `Bu alan ${length} karakterden uzun olamaz`

export const jsonCheck = (value) => {
    try {
        JSON.parse(value as any as string)
    } catch {
        return i18n.t("common.jsonFormatError")
    }
}

export const composeValidators =
    (...validators) =>
        (value) =>
            validators.reduce((error, validator) => error || validator(value), undefined)
