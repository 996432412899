import RequireAuth from "components/router/RequireAuth"
import i18n from "i18n"
import { lazy } from "react"
import { RouteExtension } from "routeExtension"
import { RoutesGroupEnum } from "types/RoutesGroupEnum"

const UserOptions = lazy(() => import("./views/userOptions/UserOptions" /* webpackChunkName: 'Auth' */))
const User = lazy(() => import("./views/user/User" /* webpackChunkName: 'Auth' */))
const UserPage = lazy(() => import("./views/user/UserPage" /* webpackChunkName: 'Auth' */))

const authRoutes: RouteExtension[] = [
    {
        group: RoutesGroupEnum.User,
        title: i18n.t("route:pageName.auth"),
        breadcrumbDisable: true,
        children: [
            {
                path: "userOptions",
                group: RoutesGroupEnum.User,
                title: i18n.t("route:pageName.userOptions"),
                description: i18n.t("route:description.userOptions"),
                element: (
                    <RequireAuth>
                        <UserOptions />
                    </RequireAuth>
                ),
            },

            {
                defaultParams: { id: 0 },

                path: "userPage/:id",
                group: RoutesGroupEnum.User,
                title: i18n.t("route:pageName.userPage"),
                description: i18n.t("route:description.userPage"),
                element: (
                    <RequireAuth>
                        <UserPage />
                    </RequireAuth>
                ),
            },
            {
                path: "user",
                group: RoutesGroupEnum.User,
                title: i18n.t("route:pageName.user"),
                description: i18n.t("route:description.user"),
                element: (
                    <RequireAuth>
                        <User />
                    </RequireAuth>
                ),
            }
        ],
    }
]
export default authRoutes
