/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LoginDto, TenantWithFeaturesDto, UserDTO, UserWithTokenDTO } from "api/models"
import { LoginDtoToJSON, TenantWithFeaturesDtoFromJSON, UserDTOFromJSON, UserWithTokenDTOFromJSON } from "api/models"
import * as runtime from "api/runtime"

export interface LoginRequest {
    loginDto: LoginDto
}

/**
 * AuthApi - interface
 *
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     *
     * @summary Giriş yapan kullanıcının Tenant bilgisi ve aktif moduller listesi (Login)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getOwnTenantWithFeaturesRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<TenantWithFeaturesDto>>

    /**
     * Giriş yapan kullanıcının Tenant bilgisi ve aktif moduller listesi (Login)
     */
    getOwnTenantWithFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantWithFeaturesDto>

    /**
     *
     * @summary Giriş yapan kullanıcı bilgisi (Login)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getOwnUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>>

    /**
     * Giriş yapan kullanıcı bilgisi (Login)
     */
    getOwnUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO>

    /**
     *
     * @param {LoginDto} loginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    loginRaw(
        requestParameters: LoginRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<UserWithTokenDTO>>

    /**
     */
    login(
        requestParameters: LoginRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<UserWithTokenDTO>
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {
    /**
     * Giriş yapan kullanıcının Tenant bilgisi ve aktif moduller listesi (Login)
     */
    async getOwnTenantWithFeaturesRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<TenantWithFeaturesDto>> {
        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization") // Bearer authentication
        }

        const response = await this.request(
            {
                path: `/api/auth/Auth/tenant`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantWithFeaturesDtoFromJSON(jsonValue))
    }

    /**
     * Giriş yapan kullanıcının Tenant bilgisi ve aktif moduller listesi (Login)
     */
    async getOwnTenantWithFeatures(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<TenantWithFeaturesDto> {
        const response = await this.getOwnTenantWithFeaturesRaw(initOverrides)
        return await response.value()
    }

    /**
     * Giriş yapan kullanıcı bilgisi (Login)
     */
    async getOwnUserRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization") // Bearer authentication
        }

        const response = await this.request(
            {
                path: `/api/auth/Auth/user`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue))
    }

    /**
     * Giriş yapan kullanıcı bilgisi (Login)
     */
    async getOwnUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.getOwnUserRaw(initOverrides)
        return await response.value()
    }

    /**
     */
    async loginRaw(
        requestParameters: LoginRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<UserWithTokenDTO>> {
        if (requestParameters.loginDto === null || requestParameters.loginDto === undefined) {
            throw new runtime.RequiredError(
                "loginDto",
                "Required parameter requestParameters.loginDto was null or undefined when calling login."
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters["Content-Type"] = "application/json"

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization") // Bearer authentication
        }

        const response = await this.request(
            {
                path: `/api/auth/Auth/login`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: LoginDtoToJSON(requestParameters.loginDto),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => UserWithTokenDTOFromJSON(jsonValue))
    }

    /**
     */
    async login(
        requestParameters: LoginRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<UserWithTokenDTO> {
        const response = await this.loginRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
