/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface TokenLogListDto
 */
export interface TokenLogListDto {
    /**
     * 
     * @type {number}
     * @memberof TokenLogListDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TokenLogListDto
     */
    loginDate?: dayjs.Dayjs;
    /**
     * 
     * @type {Date}
     * @memberof TokenLogListDto
     */
    lastAccessDate?: dayjs.Dayjs;
    /**
     * 
     * @type {Date}
     * @memberof TokenLogListDto
     */
    logoutDate?: dayjs.Dayjs;
    /**
     * 
     * @type {number}
     * @memberof TokenLogListDto
     */
    userId?: number;
}

/**
 * Check if a given object implements the TokenLogListDto interface.
 */
export function instanceOfTokenLogListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TokenLogListDtoFromJSON(json: any): TokenLogListDto {
    return TokenLogListDtoFromJSONTyped(json, false);
}

export function TokenLogListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenLogListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'loginDate': !exists(json, 'loginDate') ? undefined : (dayjs(json['loginDate'])),

        'lastAccessDate': !exists(json, 'lastAccessDate') ? undefined : (dayjs(json['lastAccessDate'])),

        'logoutDate': !exists(json, 'logoutDate') ? undefined : (dayjs(json['logoutDate'])),

        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function TokenLogListDtoToJSON(value?: TokenLogListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'loginDate': value.loginDate === undefined ? undefined : (value.loginDate.toISOString()),
        'lastAccessDate': value.lastAccessDate === undefined ? undefined : (value.lastAccessDate.toISOString()),
        'logoutDate': value.logoutDate === undefined ? undefined : (value.logoutDate.toISOString()),
        'userId': value.userId,
    };
}

