/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDto
     */
    isGranted?: boolean;
}

/**
 * Check if a given object implements the PermissionDto interface.
 */
export function instanceOfPermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PermissionDtoFromJSON(json: any): PermissionDto {
    return PermissionDtoFromJSONTyped(json, false);
}

export function PermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isGranted': !exists(json, 'isGranted') ? undefined : json['isGranted'],
    };
}

export function PermissionDtoToJSON(value?: PermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'isGranted': value.isGranted,
    };
}

