/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    fullName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    tenantId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    timeZone?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDTO
     */
    timeZoneOffset?: dayjs.Dayjs;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDTO
     */
    grantedPermissions?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    personelId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    driverId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    personelPersonelDeparmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    userImg?: string | null;
}

/**
 * Check if a given object implements the UserDTO interface.
 */
export function instanceOfUserDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDTOFromJSON(json: any): UserDTO {
    return UserDTOFromJSONTyped(json, false);
}

export function UserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'timeZone': !exists(json, 'timeZone') ? undefined : json['timeZone'],
        'timeZoneOffset': !exists(json, 'timeZoneOffset') ? undefined : (dayjs.utc(json['timeZoneOffset'], "HH:mm:ss").local()),

        'grantedPermissions': !exists(json, 'grantedPermissions') ? undefined : json['grantedPermissions'],
        'personelId': !exists(json, 'personelId') ? undefined : json['personelId'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'personelPersonelDeparmentId': !exists(json, 'personelPersonelDeparmentId') ? undefined : json['personelPersonelDeparmentId'],
        'userImg': !exists(json, 'userImg') ? undefined : json['userImg'],
    };
}

export function UserDTOToJSON(value?: UserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'surname': value.surname,
        'fullName': value.fullName,
        'tenantId': value.tenantId,
        'timeZone': value.timeZone,
        'timeZoneOffset': value.timeZoneOffset === undefined ? undefined : (value.timeZoneOffset.toISOString()),
        'grantedPermissions': value.grantedPermissions,
        'personelId': value.personelId,
        'driverId': value.driverId,
        'personelPersonelDeparmentId': value.personelPersonelDeparmentId,
        'userImg': value.userImg,
    };
}

