/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface CustomerChannelDto
 */
export interface CustomerChannelDto {
    /**
     * Tenant Id
     * @type {number}
     * @memberof CustomerChannelDto
     */
    tenantId?: number | null;
    /**
     * İsim
     * @type {string}
     * @memberof CustomerChannelDto
     */
    name?: string | null;
}

/**
 * Check if a given object implements the CustomerChannelDto interface.
 */
export function instanceOfCustomerChannelDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerChannelDtoFromJSON(json: any): CustomerChannelDto {
    return CustomerChannelDtoFromJSONTyped(json, false);
}

export function CustomerChannelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerChannelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function CustomerChannelDtoToJSON(value?: CustomerChannelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'name': value.name,
    };
}

