/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { TicketStatus } from './TicketStatus';
import {
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';

/**
 * 
 * @export
 * @interface TicketUpdateDto
 */
export interface TicketUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof TicketUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketUpdateDto
     */
    tenantId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketUpdateDto
     */
    customerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TicketUpdateDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketUpdateDto
     */
    message?: string | null;
    /**
     * 
     * @type {TicketStatus}
     * @memberof TicketUpdateDto
     */
    status?: TicketStatus;
    /**
     * 
     * @type {string}
     * @memberof TicketUpdateDto
     */
    gitIssue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketUpdateDto
     */
    contactInfo?: string | null;
}

/**
 * Check if a given object implements the TicketUpdateDto interface.
 */
export function instanceOfTicketUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketUpdateDtoFromJSON(json: any): TicketUpdateDto {
    return TicketUpdateDtoFromJSONTyped(json, false);
}

export function TicketUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
        'gitIssue': !exists(json, 'gitIssue') ? undefined : json['gitIssue'],
        'contactInfo': !exists(json, 'contactInfo') ? undefined : json['contactInfo'],
    };
}

export function TicketUpdateDtoToJSON(value?: TicketUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'customerId': value.customerId,
        'subject': value.subject,
        'message': value.message,
        'status': TicketStatusToJSON(value.status),
        'gitIssue': value.gitIssue,
        'contactInfo': value.contactInfo,
    };
}

