import { Divider } from "@mui/material"
import Grid from "@mui/material/Grid"
import { TicketDto, TicketListDto, TicketStatus, TicketUpdateDto } from "api"
import Editor from "components/Editor/Editor"
import CustomerSelect from "components/FormInputs/CustomerSelect"
import EnumSelect from "components/FormInputs/EnumSelect"
import AuditDetails from "components/FormUtils/AuditDetails"
import { requiredCheck } from "components/FormUtils/Checks"
import FormUpdateSuccesAlert from "components/alerts/FormUpdateSuccesAlert"
import useAlertState from "components/alerts/useAlertState"
import ResetButton from "components/buttons/ResetButton"
import SubmitButton from "components/buttons/SubmitButton"
import { useAddTicketMutation, useTicket, useUpdateTicketMutation } from "modules/ticketSystem/stores/TicketStore"
import { TextField } from "mui-rff"
import { BasePermissions } from "permissions/BasePermission"
import { useHasPermission } from "permissions/useHasPermission"
import { Form } from "react-final-form"
import { useTranslation } from "react-i18next"
import { IFormWithDefaultSubmitProps } from "types"
import FormatErrors from "utils/FormatErrors"
import { TicketPermissions } from "./TicketPermissions"

const TicketForm = function TicketForm(
    props: IFormWithDefaultSubmitProps<TicketListDto | TicketUpdateDto | TicketDto>
) {
    const AddTicketMutation = useAddTicketMutation()
    const UpdateTicketMutation = useUpdateTicketMutation()

    const { t } = useTranslation()
    const { disabled = false, initialValues: initialValuesFromProps, onSubmit, editId, callback } = props
    const isEdit = !!editId
    const { isFetching, data = {}, isInitialLoading } = useTicket(editId!, { enabled: isEdit })

    const { result: hasCustomerPermission } = useHasPermission(BasePermissions.Customer)
    const { result: hasCustomerRepPermission } = useHasPermission(BasePermissions.CustomerRep)
    const { result: hasManagerPermission } = useHasPermission(BasePermissions.Manager)
    const { result: hasDeveloperPermission } = useHasPermission(BasePermissions.Developer)
    const { result: hasUpdatePermission } = useHasPermission(TicketPermissions.Update)

    let initialValues = initialValuesFromProps ?? data

    const canEdit = (!isEdit || !isFetching) && (!isEdit || (isEdit && hasUpdatePermission)) && (!isEdit || (isEdit && initialValues.status === TicketStatus.Waiting))

    const validate = (values) => {
        const errors: any = {}
        errors.customerId = requiredCheck(values.customerId)
        errors.subject = requiredCheck(values.subject)
        errors.message = requiredCheck(values.message)
        errors.status = requiredCheck(values.status)

        return errors
    }

    const { alertState, showAlert } = useAlertState({})
    const defaultOnSubmit = async (d: TicketUpdateDto | TicketDto) => {
        try {
            if (isEdit) {
                await UpdateTicketMutation.mutateAsync(d)
                showAlert()
            } else {
                await AddTicketMutation.mutateAsync(d as TicketDto)
                if (callback) callback()
            }

            return undefined
        } catch (e: any) {
            if (e.response?.status === 400) {
                let response = await e.response.json()
                let errors = FormatErrors(response)

                if (callback) callback(errors)
                return errors
            } else {
                throw e
            }
        }
    }
    return (
        <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12}>
                <Form
                    subscription={{ submitting: true, pristine: true }}
                    onSubmit={defaultOnSubmit}
                    validate={props.validate || validate}
                    initialValues={{ status: TicketStatus.Waiting, ...initialValues }}
                    render={({ handleSubmit, submitting, pristine, form: { reset } }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid container item xs={12} spacing={2}>


                                    {alertState && <FormUpdateSuccesAlert />}
                                    {!hasCustomerPermission && <Grid item xs={12} sm={6}>
                                        {<EnumSelect
                                            name="status"
                                            label={t("common.status")}
                                            optionsEnum={TicketStatus}
                                            tPrefix={"ticketSystem:ticket.status"}
                                            disabled={!isEdit && hasCustomerRepPermission}
                                            required
                                        />}
                                    </Grid>}
                                    <Grid item xs={12} >
                                        <CustomerSelect
                                            name="customerId"
                                            label={t("ticketSystem:ticket.fields.customer")}
                                            disabled={disabled || !canEdit}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <TextField
                                            name="subject"
                                            type="text"
                                            label={t("ticketSystem:ticket.fields.subject")}
                                            fullWidth
                                            required
                                            disabled={disabled || !canEdit}
                                        />
                                    </Grid>
                                    {hasDeveloperPermission && <Grid item xs={12} >
                                        <TextField
                                            name="gitIssue"
                                            type="text"
                                            label={t("ticketSystem:ticket.fields.gitIssue")}
                                            fullWidth
                                            disabled={disabled || !canEdit}
                                        />
                                    </Grid>}
                                    <Grid item xs={12}>
                                        <Editor
                                            name="message"
                                            type="text"
                                            label={t("ticketSystem:ticket.fields.message")}
                                            fullWidth
                                            disabled={disabled || !canEdit}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            name="contactInfo"
                                            type="text"
                                            label={t("ticketSystem:ticket.fields.contactInfo")}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            disabled={disabled || !canEdit}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider light />
                                    </Grid>

                                    <Grid container item xs={12} spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={8}>
                                            {isEdit && <AuditDetails data={initialValues} isLoading={isInitialLoading} />}
                                        </Grid>

                                        <Grid item xs={12} sm={4} container justifyContent="flex-end">
                                            <ResetButton permission={TicketPermissions.Update} />

                                            <SubmitButton disabled={submitting || !canEdit} permissions={TicketPermissions.Update} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid >
    )
}

export default TicketForm

