import useOptions from "hooks/useOptions"
import { useTranslation } from "react-i18next"
import { EnumTypeString } from "types"
import { Autocomplete } from "./Autocomplete"

interface EnumSelectProps {
    required?: boolean
    disabled?: boolean
    name: string
    optionsEnum: EnumTypeString<string>
    label: string
    tPrefix: string
    loading?: boolean
    size?: "small" | "medium" | undefined
    multiple?: boolean
    limitTags?: number
    readonly?: boolean
    helperText?: string
}

const EnumSelect = (props: EnumSelectProps) => {
    const { t } = useTranslation()
    const {
        helperText,
        readonly = false,
        required = false,
        disabled = false,
        name,
        optionsEnum,
        label,
        tPrefix,
        loading = false,
        size,
        multiple,
        limitTags,
    } = props

    const options = useOptions(optionsEnum)
    return (
        <Autocomplete
            label={label}
            name={name}
            options={options}
            getOptionLabel={(option) => (option ? t(`${tPrefix}.${option}`) : "")}
            required={required}
            disabled={disabled}
            loading={loading}
            size={size}
            helperText={helperText}
            multiple={multiple}
            limitTags={limitTags}
            readOnly={readonly}
            fullWidth
        />
    )
}

export default EnumSelect
