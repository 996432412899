/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { NotificationGroupTypeEnum } from './NotificationGroupTypeEnum';
import {
    NotificationGroupTypeEnumFromJSON,
    NotificationGroupTypeEnumFromJSONTyped,
    NotificationGroupTypeEnumToJSON,
} from './NotificationGroupTypeEnum';
import type { NotifierTypeEnum } from './NotifierTypeEnum';
import {
    NotifierTypeEnumFromJSON,
    NotifierTypeEnumFromJSONTyped,
    NotifierTypeEnumToJSON,
} from './NotifierTypeEnum';
import type { ScheduledNotificationStatusEnum } from './ScheduledNotificationStatusEnum';
import {
    ScheduledNotificationStatusEnumFromJSON,
    ScheduledNotificationStatusEnumFromJSONTyped,
    ScheduledNotificationStatusEnumToJSON,
} from './ScheduledNotificationStatusEnum';

/**
 * 
 * @export
 * @interface ScheduledNotificationUpdateDto
 */
export interface ScheduledNotificationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationUpdateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledNotificationUpdateDto
     */
    sendDate?: dayjs.Dayjs | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationUpdateDto
     */
    personelId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationUpdateDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationUpdateDto
     */
    driverId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationUpdateDto
     */
    sendAllPersonel?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationUpdateDto
     */
    sendAllUser?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationUpdateDto
     */
    sendAllDriver?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationUpdateDto
     */
    personelDepartmentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationUpdateDto
     */
    unitGroupId?: number | null;
    /**
     * 
     * @type {Array<NotifierTypeEnum>}
     * @memberof ScheduledNotificationUpdateDto
     */
    notifierTypeEnums?: Array<NotifierTypeEnum> | null;
    /**
     * 
     * @type {NotificationGroupTypeEnum}
     * @memberof ScheduledNotificationUpdateDto
     */
    groupType?: NotificationGroupTypeEnum;
    /**
     * 
     * @type {ScheduledNotificationStatusEnum}
     * @memberof ScheduledNotificationUpdateDto
     */
    status?: ScheduledNotificationStatusEnum;
}

/**
 * Check if a given object implements the ScheduledNotificationUpdateDto interface.
 */
export function instanceOfScheduledNotificationUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScheduledNotificationUpdateDtoFromJSON(json: any): ScheduledNotificationUpdateDto {
    return ScheduledNotificationUpdateDtoFromJSONTyped(json, false);
}

export function ScheduledNotificationUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledNotificationUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sendDate': !exists(json, 'sendDate') ? undefined : (json['sendDate'] === null ? null : dayjs(json['sendDate'])),

        'personelId': !exists(json, 'personelId') ? undefined : json['personelId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'sendAllPersonel': !exists(json, 'sendAllPersonel') ? undefined : json['sendAllPersonel'],
        'sendAllUser': !exists(json, 'sendAllUser') ? undefined : json['sendAllUser'],
        'sendAllDriver': !exists(json, 'sendAllDriver') ? undefined : json['sendAllDriver'],
        'personelDepartmentId': !exists(json, 'personelDepartmentId') ? undefined : json['personelDepartmentId'],
        'unitGroupId': !exists(json, 'unitGroupId') ? undefined : json['unitGroupId'],
        'notifierTypeEnums': !exists(json, 'notifierTypeEnums') ? undefined : (json['notifierTypeEnums'] === null ? null : (json['notifierTypeEnums'] as Array<any>).map(NotifierTypeEnumFromJSON)),
        'groupType': !exists(json, 'groupType') ? undefined : NotificationGroupTypeEnumFromJSON(json['groupType']),
        'status': !exists(json, 'status') ? undefined : ScheduledNotificationStatusEnumFromJSON(json['status']),
    };
}

export function ScheduledNotificationUpdateDtoToJSON(value?: ScheduledNotificationUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'sendDate': value.sendDate === undefined ? undefined : (value.sendDate === null ? null : value.sendDate.toISOString()),
        'personelId': value.personelId,
        'userId': value.userId,
        'driverId': value.driverId,
        'sendAllPersonel': value.sendAllPersonel,
        'sendAllUser': value.sendAllUser,
        'sendAllDriver': value.sendAllDriver,
        'personelDepartmentId': value.personelDepartmentId,
        'unitGroupId': value.unitGroupId,
        'notifierTypeEnums': value.notifierTypeEnums === undefined ? undefined : (value.notifierTypeEnums === null ? null : (value.notifierTypeEnums as Array<any>).map(NotifierTypeEnumToJSON)),
        'groupType': NotificationGroupTypeEnumToJSON(value.groupType),
        'status': ScheduledNotificationStatusEnumToJSON(value.status),
    };
}

