/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UserRequestPasswordDto
 */
export interface UserRequestPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UserRequestPasswordDto
     */
    email?: string | null;
}

/**
 * Check if a given object implements the UserRequestPasswordDto interface.
 */
export function instanceOfUserRequestPasswordDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserRequestPasswordDtoFromJSON(json: any): UserRequestPasswordDto {
    return UserRequestPasswordDtoFromJSONTyped(json, false);
}

export function UserRequestPasswordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRequestPasswordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function UserRequestPasswordDtoToJSON(value?: UserRequestPasswordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}

