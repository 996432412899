/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface OwnUserSettingsUpdateDto
 */
export interface OwnUserSettingsUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof OwnUserSettingsUpdateDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnUserSettingsUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnUserSettingsUpdateDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnUserSettingsUpdateDto
     */
    msisdn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OwnUserSettingsUpdateDto
     */
    tenantId?: number | null;
}

/**
 * Check if a given object implements the OwnUserSettingsUpdateDto interface.
 */
export function instanceOfOwnUserSettingsUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OwnUserSettingsUpdateDtoFromJSON(json: any): OwnUserSettingsUpdateDto {
    return OwnUserSettingsUpdateDtoFromJSONTyped(json, false);
}

export function OwnUserSettingsUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnUserSettingsUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function OwnUserSettingsUpdateDtoToJSON(value?: OwnUserSettingsUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'surname': value.surname,
        'msisdn': value.msisdn,
        'tenantId': value.tenantId,
    };
}

