/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  CustomerChannelDto,
  CustomerChannelListDto,
  CustomerChannelUpdateDto,
  NameDTO,
  ValidationProblemDetails,
} from '../models';
import {
    CustomerChannelDtoFromJSON,
    CustomerChannelDtoToJSON,
    CustomerChannelListDtoFromJSON,
    CustomerChannelListDtoToJSON,
    CustomerChannelUpdateDtoFromJSON,
    CustomerChannelUpdateDtoToJSON,
    NameDTOFromJSON,
    NameDTOToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetCustomerChannelRequest {
    id: number;
}

export interface GetCustomerChannelNamesRequest {
    tenantId?: number;
}

export interface GetCustomerChannelsRequest {
    tenantId?: number;
}

export interface PostCustomerChannelRequest {
    customerChannelDto?: CustomerChannelDto;
}

export interface PutCustomerChannelRequest {
    id: number;
    customerChannelUpdateDto?: CustomerChannelUpdateDto;
}

/**
 * CustomerChannelApi - interface
 * 
 * @export
 * @interface CustomerChannelApiInterface
 */
export interface CustomerChannelApiInterface {
    /**
     * 
     * @summary CustomerChannel (Login)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerChannelApiInterface
     */
    getCustomerChannelRaw(requestParameters: GetCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerChannelListDto>>;

    /**
     * CustomerChannel (Login)
     */
    getCustomerChannel(requestParameters: GetCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerChannelListDto>;

    /**
     * 
     * @summary Ortam isimlerini Listeleme (Login)
     * @param {number} [tenantId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerChannelApiInterface
     */
    getCustomerChannelNamesRaw(requestParameters: GetCustomerChannelNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>>;

    /**
     * Ortam isimlerini Listeleme (Login)
     */
    getCustomerChannelNames(requestParameters: GetCustomerChannelNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>>;

    /**
     * 
     * @summary CustomerChannel Listeleme (Login)
     * @param {number} [tenantId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerChannelApiInterface
     */
    getCustomerChannelsRaw(requestParameters: GetCustomerChannelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerChannelListDto>>>;

    /**
     * CustomerChannel Listeleme (Login)
     */
    getCustomerChannels(requestParameters: GetCustomerChannelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerChannelListDto>>;

    /**
     * 
     * @summary CustomerChannel Ekleme (Login)
     * @param {CustomerChannelDto} [customerChannelDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerChannelApiInterface
     */
    postCustomerChannelRaw(requestParameters: PostCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerChannelListDto>>;

    /**
     * CustomerChannel Ekleme (Login)
     */
    postCustomerChannel(requestParameters: PostCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerChannelListDto>;

    /**
     * 
     * @summary CustomerChannel güncelleme (Login)
     * @param {number} id 
     * @param {CustomerChannelUpdateDto} [customerChannelUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerChannelApiInterface
     */
    putCustomerChannelRaw(requestParameters: PutCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * CustomerChannel güncelleme (Login)
     */
    putCustomerChannel(requestParameters: PutCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class CustomerChannelApi extends runtime.BaseAPI implements CustomerChannelApiInterface {

    /**
     * CustomerChannel (Login)
     */
    async getCustomerChannelRaw(requestParameters: GetCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerChannelListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomerChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/CustomerChannel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerChannelListDtoFromJSON(jsonValue));
    }

    /**
     * CustomerChannel (Login)
     */
    async getCustomerChannel(requestParameters: GetCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerChannelListDto> {
        const response = await this.getCustomerChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ortam isimlerini Listeleme (Login)
     */
    async getCustomerChannelNamesRaw(requestParameters: GetCustomerChannelNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/CustomerChannel/CustomerChannelNames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NameDTOFromJSON));
    }

    /**
     * Ortam isimlerini Listeleme (Login)
     */
    async getCustomerChannelNames(requestParameters: GetCustomerChannelNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>> {
        const response = await this.getCustomerChannelNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CustomerChannel Listeleme (Login)
     */
    async getCustomerChannelsRaw(requestParameters: GetCustomerChannelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerChannelListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/CustomerChannel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerChannelListDtoFromJSON));
    }

    /**
     * CustomerChannel Listeleme (Login)
     */
    async getCustomerChannels(requestParameters: GetCustomerChannelsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerChannelListDto>> {
        const response = await this.getCustomerChannelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CustomerChannel Ekleme (Login)
     */
    async postCustomerChannelRaw(requestParameters: PostCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerChannelListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/CustomerChannel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerChannelDtoToJSON(requestParameters.customerChannelDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerChannelListDtoFromJSON(jsonValue));
    }

    /**
     * CustomerChannel Ekleme (Login)
     */
    async postCustomerChannel(requestParameters: PostCustomerChannelRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerChannelListDto> {
        const response = await this.postCustomerChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * CustomerChannel güncelleme (Login)
     */
    async putCustomerChannelRaw(requestParameters: PutCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putCustomerChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/CustomerChannel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerChannelUpdateDtoToJSON(requestParameters.customerChannelUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * CustomerChannel güncelleme (Login)
     */
    async putCustomerChannel(requestParameters: PutCustomerChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putCustomerChannelRaw(requestParameters, initOverrides);
    }

}


export function useCustomerChannelApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new CustomerChannelApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new CustomerChannelApi(apiConfig["ETL-API"])
}
