/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  KokpitFeatureDto,
  KokpitFeatureListDto,
  KokpitFeatureUpdateDto,
  KokpitModuleDto,
  KokpitModuleListDto,
  KokpitModuleUpdateDto,
  KokpitPackageDto,
  KokpitPackageListDto,
  KokpitPackageUpdateDto,
  ValidationProblemDetails,
} from '../models';
import {
    KokpitFeatureDtoFromJSON,
    KokpitFeatureDtoToJSON,
    KokpitFeatureListDtoFromJSON,
    KokpitFeatureListDtoToJSON,
    KokpitFeatureUpdateDtoFromJSON,
    KokpitFeatureUpdateDtoToJSON,
    KokpitModuleDtoFromJSON,
    KokpitModuleDtoToJSON,
    KokpitModuleListDtoFromJSON,
    KokpitModuleListDtoToJSON,
    KokpitModuleUpdateDtoFromJSON,
    KokpitModuleUpdateDtoToJSON,
    KokpitPackageDtoFromJSON,
    KokpitPackageDtoToJSON,
    KokpitPackageListDtoFromJSON,
    KokpitPackageListDtoToJSON,
    KokpitPackageUpdateDtoFromJSON,
    KokpitPackageUpdateDtoToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetKokpitFeatureRequest {
    id: number;
}

export interface GetKokpitModuleRequest {
    id: number;
}

export interface GetKokpitPackageRequest {
    id: number;
}

export interface PostKokpitFeatureRequest {
    kokpitFeatureDto?: KokpitFeatureDto;
}

export interface PostKokpitModuleRequest {
    kokpitModuleDto?: KokpitModuleDto;
}

export interface PostKokpitPackageRequest {
    kokpitPackageDto?: KokpitPackageDto;
}

export interface PutKokpitFeatureRequest {
    id: number;
    kokpitFeatureUpdateDto?: KokpitFeatureUpdateDto;
}

export interface PutKokpitModuleRequest {
    id: number;
    kokpitModuleUpdateDto?: KokpitModuleUpdateDto;
}

export interface PutKokpitPackageRequest {
    id: number;
    kokpitPackageUpdateDto?: KokpitPackageUpdateDto;
}

/**
 * KokpitFeatureApi - interface
 * 
 * @export
 * @interface KokpitFeatureApiInterface
 */
export interface KokpitFeatureApiInterface {
    /**
     * 
     * @summary KokpitFeature Detay (Login AuthType: CustomerGet)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    getKokpitFeatureRaw(requestParameters: GetKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitFeatureListDto>>;

    /**
     * KokpitFeature Detay (Login AuthType: CustomerGet)
     */
    getKokpitFeature(requestParameters: GetKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitFeatureListDto>;

    /**
     * 
     * @summary KokpitFeature Listeleme (Login AuthType: CustomerList)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    getKokpitFeaturesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitFeatureListDto>>>;

    /**
     * KokpitFeature Listeleme (Login AuthType: CustomerList)
     */
    getKokpitFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitFeatureListDto>>;

    /**
     * 
     * @summary KokpitFeature Detay (Login AuthType: CustomerGet)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    getKokpitModuleRaw(requestParameters: GetKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitModuleListDto>>;

    /**
     * KokpitFeature Detay (Login AuthType: CustomerGet)
     */
    getKokpitModule(requestParameters: GetKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitModuleListDto>;

    /**
     * 
     * @summary KokpitFeature Listeleme (Login AuthType: CustomerList)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    getKokpitModulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitModuleListDto>>>;

    /**
     * KokpitFeature Listeleme (Login AuthType: CustomerList)
     */
    getKokpitModules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitModuleListDto>>;

    /**
     * 
     * @summary KokpitPackage Detay (Login AuthType: CustomerGet)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    getKokpitPackageRaw(requestParameters: GetKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitPackageListDto>>;

    /**
     * KokpitPackage Detay (Login AuthType: CustomerGet)
     */
    getKokpitPackage(requestParameters: GetKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitPackageListDto>;

    /**
     * 
     * @summary KokpitPackage Listeleme (Login AuthType: CustomerList)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    getKokpitPackagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitPackageListDto>>>;

    /**
     * KokpitPackage Listeleme (Login AuthType: CustomerList)
     */
    getKokpitPackages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitPackageListDto>>;

    /**
     * 
     * @summary KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     * @param {KokpitFeatureDto} [kokpitFeatureDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    postKokpitFeatureRaw(requestParameters: PostKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitFeatureListDto>>;

    /**
     * KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     */
    postKokpitFeature(requestParameters: PostKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitFeatureListDto>;

    /**
     * 
     * @summary KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     * @param {KokpitModuleDto} [kokpitModuleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    postKokpitModuleRaw(requestParameters: PostKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitModuleListDto>>;

    /**
     * KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     */
    postKokpitModule(requestParameters: PostKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitModuleListDto>;

    /**
     * 
     * @summary KokpitPackage Ekleme (Login AuthType: CustomerAdd)
     * @param {KokpitPackageDto} [kokpitPackageDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    postKokpitPackageRaw(requestParameters: PostKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitPackageListDto>>;

    /**
     * KokpitPackage Ekleme (Login AuthType: CustomerAdd)
     */
    postKokpitPackage(requestParameters: PostKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitPackageListDto>;

    /**
     * 
     * @summary KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     * @param {number} id 
     * @param {KokpitFeatureUpdateDto} [kokpitFeatureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    putKokpitFeatureRaw(requestParameters: PutKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     */
    putKokpitFeature(requestParameters: PutKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     * @param {number} id 
     * @param {KokpitModuleUpdateDto} [kokpitModuleUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    putKokpitModuleRaw(requestParameters: PutKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     */
    putKokpitModule(requestParameters: PutKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary KokpitPackage güncelleme (Login AuthType: CustomerUpdate)
     * @param {number} id 
     * @param {KokpitPackageUpdateDto} [kokpitPackageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KokpitFeatureApiInterface
     */
    putKokpitPackageRaw(requestParameters: PutKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * KokpitPackage güncelleme (Login AuthType: CustomerUpdate)
     */
    putKokpitPackage(requestParameters: PutKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class KokpitFeatureApi extends runtime.BaseAPI implements KokpitFeatureApiInterface {

    /**
     * KokpitFeature Detay (Login AuthType: CustomerGet)
     */
    async getKokpitFeatureRaw(requestParameters: GetKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitFeatureListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getKokpitFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitFeatureListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitFeature Detay (Login AuthType: CustomerGet)
     */
    async getKokpitFeature(requestParameters: GetKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitFeatureListDto> {
        const response = await this.getKokpitFeatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitFeature Listeleme (Login AuthType: CustomerList)
     */
    async getKokpitFeaturesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitFeatureListDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KokpitFeatureListDtoFromJSON));
    }

    /**
     * KokpitFeature Listeleme (Login AuthType: CustomerList)
     */
    async getKokpitFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitFeatureListDto>> {
        const response = await this.getKokpitFeaturesRaw(initOverrides);
        return await response.value();
    }

    /**
     * KokpitFeature Detay (Login AuthType: CustomerGet)
     */
    async getKokpitModuleRaw(requestParameters: GetKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitModuleListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getKokpitModule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitmodule/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitModuleListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitFeature Detay (Login AuthType: CustomerGet)
     */
    async getKokpitModule(requestParameters: GetKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitModuleListDto> {
        const response = await this.getKokpitModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitFeature Listeleme (Login AuthType: CustomerList)
     */
    async getKokpitModulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitModuleListDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitmodule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KokpitModuleListDtoFromJSON));
    }

    /**
     * KokpitFeature Listeleme (Login AuthType: CustomerList)
     */
    async getKokpitModules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitModuleListDto>> {
        const response = await this.getKokpitModulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * KokpitPackage Detay (Login AuthType: CustomerGet)
     */
    async getKokpitPackageRaw(requestParameters: GetKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitPackageListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getKokpitPackage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitPackage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitPackageListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitPackage Detay (Login AuthType: CustomerGet)
     */
    async getKokpitPackage(requestParameters: GetKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitPackageListDto> {
        const response = await this.getKokpitPackageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitPackage Listeleme (Login AuthType: CustomerList)
     */
    async getKokpitPackagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KokpitPackageListDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitPackage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KokpitPackageListDtoFromJSON));
    }

    /**
     * KokpitPackage Listeleme (Login AuthType: CustomerList)
     */
    async getKokpitPackages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KokpitPackageListDto>> {
        const response = await this.getKokpitPackagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     */
    async postKokpitFeatureRaw(requestParameters: PostKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitFeatureListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitFeatureDtoToJSON(requestParameters.kokpitFeatureDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitFeatureListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     */
    async postKokpitFeature(requestParameters: PostKokpitFeatureRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitFeatureListDto> {
        const response = await this.postKokpitFeatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     */
    async postKokpitModuleRaw(requestParameters: PostKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitModuleListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitmodule`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitModuleDtoToJSON(requestParameters.kokpitModuleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitModuleListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitFeature Ekleme (Login AuthType: CustomerAdd)
     */
    async postKokpitModule(requestParameters: PostKokpitModuleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitModuleListDto> {
        const response = await this.postKokpitModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitPackage Ekleme (Login AuthType: CustomerAdd)
     */
    async postKokpitPackageRaw(requestParameters: PostKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KokpitPackageListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitPackage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitPackageDtoToJSON(requestParameters.kokpitPackageDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KokpitPackageListDtoFromJSON(jsonValue));
    }

    /**
     * KokpitPackage Ekleme (Login AuthType: CustomerAdd)
     */
    async postKokpitPackage(requestParameters: PostKokpitPackageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KokpitPackageListDto> {
        const response = await this.postKokpitPackageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     */
    async putKokpitFeatureRaw(requestParameters: PutKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putKokpitFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitFeatureUpdateDtoToJSON(requestParameters.kokpitFeatureUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     */
    async putKokpitFeature(requestParameters: PutKokpitFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putKokpitFeatureRaw(requestParameters, initOverrides);
    }

    /**
     * KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     */
    async putKokpitModuleRaw(requestParameters: PutKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putKokpitModule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitmodule/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitModuleUpdateDtoToJSON(requestParameters.kokpitModuleUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * KokpitFeature güncelleme (Login AuthType: CustomerUpdate)
     */
    async putKokpitModule(requestParameters: PutKokpitModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putKokpitModuleRaw(requestParameters, initOverrides);
    }

    /**
     * KokpitPackage güncelleme (Login AuthType: CustomerUpdate)
     */
    async putKokpitPackageRaw(requestParameters: PutKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putKokpitPackage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/KokpitFeature/kokpitPackage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KokpitPackageUpdateDtoToJSON(requestParameters.kokpitPackageUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * KokpitPackage güncelleme (Login AuthType: CustomerUpdate)
     */
    async putKokpitPackage(requestParameters: PutKokpitPackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putKokpitPackageRaw(requestParameters, initOverrides);
    }

}


export function useKokpitFeatureApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new KokpitFeatureApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new KokpitFeatureApi(apiConfig["ETL-API"])
}
