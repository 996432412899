import { Grid } from "@mui/material"
import { FormSpy, useForm } from "react-final-form"
import { useTranslation } from "react-i18next"
import ButtonWithPermission from "./ButtonWithPermission"

interface IResetButtonProps {
    permission: string
}
const ResetButton: React.FC<IResetButtonProps> = (props) => {
    const { t } = useTranslation()
    const { permission } = props
    const { reset } = useForm()

    return (
        <FormSpy subscription={{ submitting: true, pristine: true }}>
            {({ submitting, initialValues, pristine }) => (
                <Grid item>
                    <ButtonWithPermission
                        permission={permission}
                        type="button"
                        variant="text"
                        disabled={submitting || pristine}
                        onClick={() => {
                            reset(initialValues) // This function reset the values to the initial state.
                        }}
                    >
                        {t("common.reset")}
                    </ButtonWithPermission>
                </Grid>
            )}
        </FormSpy>
    )
}

export default ResetButton
