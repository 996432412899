import RequireAuth from "components/router/RequireAuth"
import i18n from "i18n"
import { lazy } from "react"
import { RouteExtension } from "routeExtension"
import { RoutesGroupEnum } from "types/RoutesGroupEnum"

const SmsProvider = lazy(() => import("./views/smsProvider/SmsProvider" /* webpackChunkName: 'Notification' */))
const Notifications = lazy(() => import("./views/notifications/Notifications" /* webpackChunkName: 'Notification' */))
const OwnNotifications = lazy(
    () => import("./views/ownNotifications/OwnNotifications" /* webpackChunkName: 'Notification' */)
)
const RegisterNotification = lazy(
    () => import("./views/registerNotification/RegisterNotification" /* webpackChunkName: 'Notification' */)
)
const ScheduledNotification = lazy(
    () => import("./views/scheduledNotification/ScheduledNotification" /* webpackChunkName: 'Notification' */)
)

const notificationRoutes: RouteExtension[] = [
    {
        group: RoutesGroupEnum.Notification,
        title: i18n.t("route:pageName.notificationModule"),
        breadcrumbDisable: true,
        children: [
            {
                path: "registerNotification",
                group: RoutesGroupEnum.Notification,
                title: i18n.t("route:pageName.registerNotification"),
                description: i18n.t("route:description.registerNotification"),
                element: (
                    <RequireAuth>
                        <RegisterNotification />
                    </RequireAuth>
                ),
            },
            {
                path: "smsProvider",
                group: RoutesGroupEnum.Base,
                title: i18n.t("route:pageName.smsProvider"),
                description: i18n.t("route:description.smsProvider"),
                element: (
                    <RequireAuth>
                        <SmsProvider />
                    </RequireAuth>
                ),
            },
            {
                path: "notifications",
                group: RoutesGroupEnum.Notification,
                title: i18n.t("route:pageName.systemNotification"),
                description: i18n.t("route:description.notification"),
                element: (
                    <RequireAuth>
                        <Notifications />
                    </RequireAuth>
                ),
            },
            {
                path: "ownNotifications",
                group: RoutesGroupEnum.Notification,
                title: i18n.t("route:pageName.notification"),
                description: i18n.t("route:description.notification"),
                element: (
                    <RequireAuth>
                        <OwnNotifications />
                    </RequireAuth>
                ),
            },
            {
                path: "scheduledNotification",
                group: RoutesGroupEnum.Notification,
                title: i18n.t("route:pageName.scheduledNotification"),
                description: i18n.t("route:description.scheduledNotification"),
                element: (
                    <RequireAuth>
                        <ScheduledNotification />
                    </RequireAuth>
                ),
            },
        ],
    },
]
export default notificationRoutes
