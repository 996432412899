/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  OwnNotificationListDto,
  OwnNotificationListDtoOffsetPaginationResult,
  OwnNotificationStateCountDto,
  UserNotificationListDto,
  UserNotificationState,
  ValidationProblemDetails,
} from '../models';
import {
    OwnNotificationListDtoFromJSON,
    OwnNotificationListDtoToJSON,
    OwnNotificationListDtoOffsetPaginationResultFromJSON,
    OwnNotificationListDtoOffsetPaginationResultToJSON,
    OwnNotificationStateCountDtoFromJSON,
    OwnNotificationStateCountDtoToJSON,
    UserNotificationListDtoFromJSON,
    UserNotificationListDtoToJSON,
    UserNotificationStateFromJSON,
    UserNotificationStateToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetOwnNotificationPaginationsRequest {
    state?: UserNotificationState;
    notificationName?: string;
    targetNotifier?: string;
    customerId?: number;
    pageSize?: number;
    page?: number;
}

export interface GetOwnNotificationStateCountRequest {
    customerId?: number;
}

export interface GetOwnNotificationsRequest {
    state?: UserNotificationState;
    customerId?: number;
}

export interface GetUserNotificationsRequest {
    startDate?: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
    notificationName?: string;
    customerId?: number;
}

export interface MarkReadOrUnReadRequest {
    id: string;
    state?: UserNotificationState;
    customerId?: number;
}

/**
 * UserNotificationApi - interface
 * 
 * @export
 * @interface UserNotificationApiInterface
 */
export interface UserNotificationApiInterface {
    /**
     * 
     * @summary Kullanıcı kendi bildirimlerini listeler (sayfalama yapılır) (Login)
     * @param {UserNotificationState} [state] okundu - okunmadı
     * @param {string} [notificationName] bildirim adı
     * @param {string} [targetNotifier] bildirim yolları
     * @param {number} [customerId] opsiyonel alt müşteri
     * @param {number} [pageSize] pageSize
     * @param {number} [page] The page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationApiInterface
     */
    getOwnNotificationPaginationsRaw(requestParameters: GetOwnNotificationPaginationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnNotificationListDtoOffsetPaginationResult>>;

    /**
     * Kullanıcı kendi bildirimlerini listeler (sayfalama yapılır) (Login)
     */
    getOwnNotificationPaginations(requestParameters: GetOwnNotificationPaginationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnNotificationListDtoOffsetPaginationResult>;

    /**
     * 
     * @summary Okunmuş ve okunmamış bildirim sayıları (Login)
     * @param {number} [customerId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationApiInterface
     */
    getOwnNotificationStateCountRaw(requestParameters: GetOwnNotificationStateCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnNotificationStateCountDto>>;

    /**
     * Okunmuş ve okunmamış bildirim sayıları (Login)
     */
    getOwnNotificationStateCount(requestParameters: GetOwnNotificationStateCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnNotificationStateCountDto>;

    /**
     * 
     * @summary Kullanıcı kendi bildirimlerini listeler (Login)
     * @param {UserNotificationState} [state] okundu - okunmadı
     * @param {number} [customerId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationApiInterface
     */
    getOwnNotificationsRaw(requestParameters: GetOwnNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OwnNotificationListDto>>>;

    /**
     * Kullanıcı kendi bildirimlerini listeler (Login)
     */
    getOwnNotifications(requestParameters: GetOwnNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OwnNotificationListDto>>;

    /**
     * 
     * @summary Sürücü bildirimlier listeleme (Login)
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [notificationName] 
     * @param {number} [customerId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationApiInterface
     */
    getUserNotificationsRaw(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserNotificationListDto>>>;

    /**
     * Sürücü bildirimlier listeleme (Login)
     */
    getUserNotifications(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserNotificationListDto>>;

    /**
     * 
     * @summary Kullanıcı kendi bildirimlerini Okundu veya okunmadı olarak işaretler (Login)
     * @param {string} id id
     * @param {UserNotificationState} [state] okundu - okunmadı
     * @param {number} [customerId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotificationApiInterface
     */
    markReadOrUnReadRaw(requestParameters: MarkReadOrUnReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Kullanıcı kendi bildirimlerini Okundu veya okunmadı olarak işaretler (Login)
     */
    markReadOrUnRead(requestParameters: MarkReadOrUnReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserNotificationApi extends runtime.BaseAPI implements UserNotificationApiInterface {

    /**
     * Kullanıcı kendi bildirimlerini listeler (sayfalama yapılır) (Login)
     */
    async getOwnNotificationPaginationsRaw(requestParameters: GetOwnNotificationPaginationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnNotificationListDtoOffsetPaginationResult>> {
        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.notificationName !== undefined) {
            queryParameters['notificationName'] = requestParameters.notificationName;
        }

        if (requestParameters.targetNotifier !== undefined) {
            queryParameters['targetNotifier'] = requestParameters.targetNotifier;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/UserNotification/OwnNotificationPaginations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnNotificationListDtoOffsetPaginationResultFromJSON(jsonValue));
    }

    /**
     * Kullanıcı kendi bildirimlerini listeler (sayfalama yapılır) (Login)
     */
    async getOwnNotificationPaginations(requestParameters: GetOwnNotificationPaginationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnNotificationListDtoOffsetPaginationResult> {
        const response = await this.getOwnNotificationPaginationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Okunmuş ve okunmamış bildirim sayıları (Login)
     */
    async getOwnNotificationStateCountRaw(requestParameters: GetOwnNotificationStateCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnNotificationStateCountDto>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/UserNotification/GetOwnNotificationStateCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnNotificationStateCountDtoFromJSON(jsonValue));
    }

    /**
     * Okunmuş ve okunmamış bildirim sayıları (Login)
     */
    async getOwnNotificationStateCount(requestParameters: GetOwnNotificationStateCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnNotificationStateCountDto> {
        const response = await this.getOwnNotificationStateCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Kullanıcı kendi bildirimlerini listeler (Login)
     */
    async getOwnNotificationsRaw(requestParameters: GetOwnNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OwnNotificationListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/UserNotification/OwnNotifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnNotificationListDtoFromJSON));
    }

    /**
     * Kullanıcı kendi bildirimlerini listeler (Login)
     */
    async getOwnNotifications(requestParameters: GetOwnNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OwnNotificationListDto>> {
        const response = await this.getOwnNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sürücü bildirimlier listeleme (Login)
     */
    async getUserNotificationsRaw(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserNotificationListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.notificationName !== undefined) {
            queryParameters['notificationName'] = requestParameters.notificationName;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/UserNotification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserNotificationListDtoFromJSON));
    }

    /**
     * Sürücü bildirimlier listeleme (Login)
     */
    async getUserNotifications(requestParameters: GetUserNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserNotificationListDto>> {
        const response = await this.getUserNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Kullanıcı kendi bildirimlerini Okundu veya okunmadı olarak işaretler (Login)
     */
    async markReadOrUnReadRaw(requestParameters: MarkReadOrUnReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markReadOrUnRead.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/notification/UserNotification/{id}/MarkReadOrUnRead`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Kullanıcı kendi bildirimlerini Okundu veya okunmadı olarak işaretler (Login)
     */
    async markReadOrUnRead(requestParameters: MarkReadOrUnReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markReadOrUnReadRaw(requestParameters, initOverrides);
    }

}


export function useUserNotificationApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new UserNotificationApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new UserNotificationApi(apiConfig["ETL-API"])
}
