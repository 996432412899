export enum RoutesGroupEnum {
    Login = "Z-Login",
    Page = "Z-Page",
    Expense = "Expense",
    ExpenseConfirm = "Expense Confirm",
    ExpenseType = "Expense Type",
    Inventory = "Inventory",
    InventoryGroup = "Inventory Group",
    InventorySubGroup = "Inventory Sub Group",
    Building = "Building",
    Interactive = "Interactive",
    Puantaj = "Puantaj",
    Reports = "Reports",
    Unit = "Unit",
    UnitReport = "Unit Report",
    ExternalExpense = "External Expense",
    PersonelVacation = "PersonelVacation",
    Fuel = "Fuel",
    Filo = "Filo",
    User = "User",
    YakitGuvenlik = "Yakit Güvenlik",
    Driver = "Driver",
    Base = "Base",
    Personel = "Personel",
    CardPage = "Kart Sayfaları",
    External = "Diğer Sayfalar",
    Definitions = "Tanımlamalar",
    Device = "Cihaz Sayfaları",
    Notification = "Notification",
    Pdks = "Personel Devam Kontrol Sistemi",
    Hakedis = "Hakediş",
    Home = "Home",
    Hizmet = "Hizmet",
    Akademi = "Akademi",
    Prime = "Prime",
    DirectLogin = "DirectLogin",
    TicketSystem = "Ticket Sistem"
}
