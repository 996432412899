/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { TicketStatus } from './TicketStatus';
import {
    TicketStatusFromJSON,
    TicketStatusFromJSONTyped,
    TicketStatusToJSON,
} from './TicketStatus';

/**
 * 
 * @export
 * @interface TicketDto
 */
export interface TicketDto {
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    tenantId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    customerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    message?: string | null;
    /**
     * 
     * @type {TicketStatus}
     * @memberof TicketDto
     */
    status?: TicketStatus;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    gitIssue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    contactInfo?: string | null;
}

/**
 * Check if a given object implements the TicketDto interface.
 */
export function instanceOfTicketDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketDtoFromJSON(json: any): TicketDto {
    return TicketDtoFromJSONTyped(json, false);
}

export function TicketDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'status': !exists(json, 'status') ? undefined : TicketStatusFromJSON(json['status']),
        'gitIssue': !exists(json, 'gitIssue') ? undefined : json['gitIssue'],
        'contactInfo': !exists(json, 'contactInfo') ? undefined : json['contactInfo'],
    };
}

export function TicketDtoToJSON(value?: TicketDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'customerId': value.customerId,
        'subject': value.subject,
        'message': value.message,
        'status': TicketStatusToJSON(value.status),
        'gitIssue': value.gitIssue,
        'contactInfo': value.contactInfo,
    };
}

