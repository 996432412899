import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// @ts-ignore
import 'dayjs/locale/tr';
import i18n from '../i18n';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);



export function updateLocale() {

    if (i18n.language === 'en') {
        dayjs.locale('en')
    } else {
        dayjs.locale('tr')
    }
}

export function formatDate(date: Date) {
    const now = dayjs()
    const start = dayjs(date)
    const diff = now.isSame(start, 'day')
    if (diff) {
        return start.format('H:mm')
    } if (start.year() === now.year()) {
        return start.format('D MMM')
    }
    return start.format('D.MM.Y')
}

export function formatDuration(diff: any) {
    return dayjs.utc(diff).format("HH:mm:ss")
}

export default dayjs
export type Dayjs = dayjs.Dayjs