/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { TicketListDto } from './TicketListDto';
import {
    TicketListDtoFromJSON,
    TicketListDtoFromJSONTyped,
    TicketListDtoToJSON,
} from './TicketListDto';

/**
 * 
 * @export
 * @interface TicketListDtoOffsetPaginationResult
 */
export interface TicketListDtoOffsetPaginationResult {
    /**
     * 
     * @type {Array<TicketListDto>}
     * @memberof TicketListDtoOffsetPaginationResult
     */
    data?: Array<TicketListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TicketListDtoOffsetPaginationResult
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketListDtoOffsetPaginationResult
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketListDtoOffsetPaginationResult
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketListDtoOffsetPaginationResult
     */
    pageCount?: number;
}

/**
 * Check if a given object implements the TicketListDtoOffsetPaginationResult interface.
 */
export function instanceOfTicketListDtoOffsetPaginationResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketListDtoOffsetPaginationResultFromJSON(json: any): TicketListDtoOffsetPaginationResult {
    return TicketListDtoOffsetPaginationResultFromJSONTyped(json, false);
}

export function TicketListDtoOffsetPaginationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketListDtoOffsetPaginationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(TicketListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
    };
}

export function TicketListDtoOffsetPaginationResultToJSON(value?: TicketListDtoOffsetPaginationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(TicketListDtoToJSON)),
        'totalCount': value.totalCount,
        'pageSize': value.pageSize,
        'page': value.page,
        'pageCount': value.pageCount,
    };
}

