/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { NotificationGroupTypeEnum } from './NotificationGroupTypeEnum';
import {
    NotificationGroupTypeEnumFromJSON,
    NotificationGroupTypeEnumFromJSONTyped,
    NotificationGroupTypeEnumToJSON,
} from './NotificationGroupTypeEnum';
import type { NotifierTypeEnum } from './NotifierTypeEnum';
import {
    NotifierTypeEnumFromJSON,
    NotifierTypeEnumFromJSONTyped,
    NotifierTypeEnumToJSON,
} from './NotifierTypeEnum';

/**
 * 
 * @export
 * @interface ScheduledNotificationDto
 */
export interface ScheduledNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationDto
     */
    tenantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledNotificationDto
     */
    sendDate: dayjs.Dayjs;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationDto
     */
    personelId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationDto
     */
    driverId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationDto
     */
    sendAllPersonel?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationDto
     */
    sendAllUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationDto
     */
    sendAllDriver?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationDto
     */
    personelDepartmentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationDto
     */
    unitGroupId?: number | null;
    /**
     * 
     * @type {Array<NotifierTypeEnum>}
     * @memberof ScheduledNotificationDto
     */
    notifierTypeEnums?: Array<NotifierTypeEnum> | null;
    /**
     * 
     * @type {NotificationGroupTypeEnum}
     * @memberof ScheduledNotificationDto
     */
    groupType?: NotificationGroupTypeEnum;
}

/**
 * Check if a given object implements the ScheduledNotificationDto interface.
 */
export function instanceOfScheduledNotificationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "sendDate" in value;

    return isInstance;
}

export function ScheduledNotificationDtoFromJSON(json: any): ScheduledNotificationDto {
    return ScheduledNotificationDtoFromJSONTyped(json, false);
}

export function ScheduledNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledNotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sendDate': (dayjs(json['sendDate'])),

        'personelId': !exists(json, 'personelId') ? undefined : json['personelId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'sendAllPersonel': !exists(json, 'sendAllPersonel') ? undefined : json['sendAllPersonel'],
        'sendAllUser': !exists(json, 'sendAllUser') ? undefined : json['sendAllUser'],
        'sendAllDriver': !exists(json, 'sendAllDriver') ? undefined : json['sendAllDriver'],
        'personelDepartmentId': !exists(json, 'personelDepartmentId') ? undefined : json['personelDepartmentId'],
        'unitGroupId': !exists(json, 'unitGroupId') ? undefined : json['unitGroupId'],
        'notifierTypeEnums': !exists(json, 'notifierTypeEnums') ? undefined : (json['notifierTypeEnums'] === null ? null : (json['notifierTypeEnums'] as Array<any>).map(NotifierTypeEnumFromJSON)),
        'groupType': !exists(json, 'groupType') ? undefined : NotificationGroupTypeEnumFromJSON(json['groupType']),
    };
}

export function ScheduledNotificationDtoToJSON(value?: ScheduledNotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'title': value.title,
        'description': value.description,
        'sendDate': (value.sendDate.toISOString()),
        'personelId': value.personelId,
        'userId': value.userId,
        'driverId': value.driverId,
        'sendAllPersonel': value.sendAllPersonel,
        'sendAllUser': value.sendAllUser,
        'sendAllDriver': value.sendAllDriver,
        'personelDepartmentId': value.personelDepartmentId,
        'unitGroupId': value.unitGroupId,
        'notifierTypeEnums': value.notifierTypeEnums === undefined ? undefined : (value.notifierTypeEnums === null ? null : (value.notifierTypeEnums as Array<any>).map(NotifierTypeEnumToJSON)),
        'groupType': NotificationGroupTypeEnumToJSON(value.groupType),
    };
}

