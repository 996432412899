import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
    GetTicketRequest,
    GetTicketsPaginationRequest,
    GetTicketsRequest,
    GetTicketStatusCountRequest,
    TicketDto,
    TicketListDto,
    TicketListDtoOffsetPaginationResult,
    TicketStatusCountDto,
    TicketUpdateDto,
    useTicketApi,
} from "api"
import { StoreUseQueryOptions } from "types"

export const ticketKeys = {
    all: ["ticket"] as const,
    lists: () => [...ticketKeys.all, "list"] as const,
    list: (param: GetTicketsRequest) => [...ticketKeys.lists(), param] as const,
    details: () => [...ticketKeys.all, "detail"] as const,
    detail: (param: GetTicketRequest) => [...ticketKeys.details(), param] as const,

    ticketPaginationLists: () => [...ticketKeys.all, "ticketPaginationList"] as const,
    ticketPaginationList: (param: GetTicketsPaginationRequest) =>
        [...ticketKeys.ticketPaginationLists(), param] as const,

    ticketStatusCounts: () => [...ticketKeys.all, "ticketStatusCounts"] as const,
    ticketStatusCount: (param: GetTicketStatusCountRequest) => [...ticketKeys.ticketStatusCounts(), param] as const,
}

export const useTicketStatusCount = (
    param: GetTicketStatusCountRequest,
    options: StoreUseQueryOptions<TicketStatusCountDto> = {
        initialData: { waiting: 0, closed: 0, accepted: 0, completed: 0, declined: 0, working: 0 },
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const api = useTicketApi()

    return useQuery({
        queryKey: ticketKeys.ticketStatusCount(param),
        queryFn: () => api.getTicketStatusCount(param),
        ...options,
    })
}

export const useTicketPaginationList = (
    param: GetTicketsPaginationRequest,
    options: StoreUseQueryOptions<TicketListDtoOffsetPaginationResult> = {
        placeholderData: keepPreviousData,
    }
) => {
    const api = useTicketApi()

    return useQuery({
        queryKey: ticketKeys.ticketPaginationList(param),
        queryFn: () => api.getTicketsPagination(param),
        ...options,
    })
}
export const useTicket = (
    id: number,
    options: StoreUseQueryOptions<TicketListDto> = {
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const api = useTicketApi()

    const param: GetTicketRequest = { id }
    return useQuery({
        queryKey: ticketKeys.detail(param),
        queryFn: () => api.getTicket(param),
        ...options,
    })
}
export const useTicketList = (param: GetTicketsRequest, options?: StoreUseQueryOptions<TicketListDto[]>) => {
    const api = useTicketApi()

    return useQuery({
        queryKey: ticketKeys.list(param),
        queryFn: () => api.getTickets(param),
        ...options,
    })
}

export const useAddTicketMutation = () => {
    const api = useTicketApi()
    const queryClient = useQueryClient()

    return useMutation<TicketListDto, any, TicketDto, any>({
        mutationFn: (d) => api.postTicket({ ticketDto: d }),

        onSettled: (_data, error, _variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: ticketKeys.lists() })
                queryClient.invalidateQueries({ queryKey: ticketKeys.ticketPaginationLists() })
                queryClient.invalidateQueries({ queryKey: ticketKeys.ticketStatusCounts() })
            }
        },
    })
}

export const useUpdateTicketMutation = () => {
    const api = useTicketApi()
    const queryClient = useQueryClient()

    return useMutation<void, any, TicketUpdateDto, any>({
        mutationFn: (d) => api.putTicket({ id: d.id!, ticketUpdateDto: d }),

        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: ticketKeys.detail({ id: variables.id! }) })
                queryClient.invalidateQueries({ queryKey: ticketKeys.lists() })
                queryClient.invalidateQueries({ queryKey: ticketKeys.ticketPaginationLists() })
                queryClient.invalidateQueries({ queryKey: ticketKeys.ticketStatusCounts() })
            }
        },
    })
}

export const useDeleteTicketMutation = () => {
    const api = useTicketApi()
    const queryClient = useQueryClient()

    return useMutation<TicketListDto, any, number, any>({
        mutationFn: (d: number) => api.deleteTicket({ id: d }),

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ticketKeys.lists() })
            queryClient.invalidateQueries({ queryKey: ticketKeys.ticketPaginationLists() })
            queryClient.invalidateQueries({ queryKey: ticketKeys.ticketStatusCounts() })
        },
    })
}
