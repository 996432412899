import config from "config"
import { authApi, localForageApi } from "./ApiStore"

import { queryClient } from "App"
import { create } from "zustand"

export interface LoginDto {
    /**
     *
     * @type {string}
     * @memberof LoginDto
     */
    userName: string

    /**
     *
     * @type {string}
     * @memberof LoginDto
     */
    password: string
}

type IAuthStore = {
    auth: boolean
    rememberMe: boolean
    token: string
}

export const useAuthStore = create<IAuthStore>(() => {
    const oldToken = localStorage.getItem(config.tokenKey)
    return {
        rememberMe: false,
        auth: oldToken && oldToken !== "----" ? true : false,
        token: oldToken ? oldToken! : "--",
    }
})

export const setToken = (newToken: string) =>
    useAuthStore.setState((state) => {
        if (state.token !== newToken) {
            queryClient.clear()
        }
        return {
            token: newToken,
        }
    })

export const loginRawNoCheck = (newToken: string) => {
    localStorage.setItem(config.tokenKey, newToken)

    useAuthStore.setState((state) => {
        if (state.token !== newToken) {
            queryClient.clear()
            console.log("token diff")
        }
        return {
            token: newToken,
            auth: true,
        }
    })
    return false
}

export const loginRaw = async (newToken: string) => {
    queryClient.clear()
    useAuthStore.setState((state) => ({
        token: newToken,
        auth: true,
    }))
    localStorage.setItem(config.tokenKey, newToken)

    return false

}

export const login = async (params: LoginDto) => {
    try {
        let token = await authApi.login({
            loginDto: {
                email: params.userName!,
                password: params.password!,
            },
        })
        return loginRaw(token.token!)
    } catch (error: any) {
        useAuthStore.setState((state) => ({
            token: "----",
            auth: false,
        }))
        throw error
    }
}

export const logout = (redirect = true) => {
    localStorage.setItem("debtIsShowed", JSON.stringify(false));
    localForageApi.removeItem(config.tokenKey + "-claims")
    localStorage.removeItem(config.tokenKey)
    useAuthStore.setState((state) => ({
        token: "----",
        auth: false,
    }))
    queryClient.clear()
    if (redirect) {
        if (window.location.href.includes("mobile")) window.location.replace("/mobile/login")
        else if (window.location.href.includes("app") || window.location.href === "/") window.location.replace("/login")
        else window.location.replace("/relogin")
    }
}
