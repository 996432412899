/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface OwnNotificationStateCountDto
 */
export interface OwnNotificationStateCountDto {
    /**
     * 
     * @type {number}
     * @memberof OwnNotificationStateCountDto
     */
    read?: number;
    /**
     * 
     * @type {number}
     * @memberof OwnNotificationStateCountDto
     */
    unRead?: number;
}

/**
 * Check if a given object implements the OwnNotificationStateCountDto interface.
 */
export function instanceOfOwnNotificationStateCountDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OwnNotificationStateCountDtoFromJSON(json: any): OwnNotificationStateCountDto {
    return OwnNotificationStateCountDtoFromJSONTyped(json, false);
}

export function OwnNotificationStateCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnNotificationStateCountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'read': !exists(json, 'read') ? undefined : json['read'],
        'unRead': !exists(json, 'unRead') ? undefined : json['unRead'],
    };
}

export function OwnNotificationStateCountDtoToJSON(value?: OwnNotificationStateCountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'read': value.read,
        'unRead': value.unRead,
    };
}

