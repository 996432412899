import { CssBaseline } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import React, { PropsWithChildren } from "react"
import { useUiStore } from "stores/UiStore"
import themes from "themes"

export const DispatchContext = React.createContext(() => {
    throw new Error("Forgot to wrap component in `ThemeProvider`")
})

if (import.meta.env.NODE_ENV !== 'production') {
    DispatchContext.displayName = 'ThemeDispatchContext';
}

const ThemeContext = ({ children }: PropsWithChildren) => {
    const theme = useUiStore((state) => themes[state.theme])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default ThemeContext
