import { createTheme, responsiveFontSizes } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { trTRCore, trTRPickers, trTRgrid } from "components/DataGrid/locale"
import baseTheme from "./base"

const lightTheme = createTheme(
    deepmerge(baseTheme, {
        palette: {
            background: {
                default: "#fff",
            },
            interactive: {
                main: "#3f913f",
            },
            personel: {
                main: "#776bbe",
                contrastText: "#fff",
            },
            unit: {
                main: "#2a8564",
                contrastText: "#fff",
            },
            driver: {
                main: "#cf3074",
                contrastText: "#fff",
            },
            fleet: {
                main: "#d87700",
                contrastText: "#fff",
            },
            fuel: {
                main: "#d52727",
                contrastText: "#fff",
            },
            tech: {
                main: "#45b9d6",
                contrastText: "#fff",
            },
            building: {
                main: "#b95a3c",
                contrastText: "#fff",
            },
            primary: {
                main: "#660066",
                light: "#843384",
                dark: "#470047",
                contrastText: "#FFFFFF",
            },
            secondary: {
                main: "#10B981",
                light: "#3FC79A",
                dark: "#0B815A",
                contrastText: "#FFFFFF",
            },
            success: {
                main: "#14B8A6",
                light: "#43C6B7",
                dark: "#0E8074",
                contrastText: "#FFFFFF",
            },
            info: {
                main: "#2196F3",
                light: "#64B6F7",
                dark: "#0B79D0",
                contrastText: "#FFFFFF",
            },
            warning: {
                main: "#FFB020",
                light: "#FFBF4C",
                dark: "#B27B16",
                contrastText: "#FFFFFF",
            },
            error: {
                main: "#D14343",
                light: "#DA6868",
                dark: "#922E2E",
                contrastText: "#FFFFFF",
            },
            text: {
                primary: "#121828",
                secondary: "#65748B",
                disabled: "rgba(55, 65, 81, 0.48)",
            },
        },
    }),
    trTRgrid, // x-data-grid translations
    trTRPickers, // x-data-grid translations
    trTRCore // core translations
)

export default responsiveFontSizes(lightTheme)
