import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
    GetUserListRequest,
    GetUserNameListRequest,
    GetUserRequest,
    TenantWithFeaturesDto,
    useAuthApi,
    UserDTO,
    UserListDto,
    UserNameListDto,
    UserUpdateDto,
    useUserApi,
} from "api"
import { StoreUseQueryOptions } from "types"

export const userKeys = {
    all: ["user"] as const,
    lists: () => [...userKeys.all, "list"] as const,
    list: (param: GetUserListRequest) => [...userKeys.lists(), param] as const,
    details: () => [...userKeys.all, "detail"] as const,
    detail: (param: GetUserRequest) => [...userKeys.details(), param] as const,
}

export const useUser = (
    id: number,
    options: StoreUseQueryOptions<UserListDto> = {
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const api = useUserApi()

    const param: GetUserRequest = { id }
    return useQuery({ queryKey: userKeys.detail(param), queryFn: () => api.getUser(param), ...options })
}
export const useUserList = (param: GetUserListRequest, options?: StoreUseQueryOptions<UserListDto[]>) => {
    const api = useUserApi()

    return useQuery({ queryKey: userKeys.list(param), queryFn: () => api.getUserList(param), ...options })
}

export const useAddUserMutation = () => {
    const api = useUserApi()
    const queryClient = useQueryClient()

    return useMutation<UserListDto, any, UserDTO, any>({
        mutationFn: (d) => api.postUser({ userAddDto: d }),
        onSettled: (_data, error, _variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: userKeys.lists() })
            }
        },
    })
}

export const useUpdateTicketMutation = () => {
    const api = useUserApi()
    const queryClient = useQueryClient()

    return useMutation<void, any, UserUpdateDto, any>({
        mutationFn: (d) => api.putUser({ id: d.id!, userUpdateDto: d }),
        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: userKeys.detail({ id: variables.id! }) })
                queryClient.invalidateQueries({ queryKey: userKeys.lists() })
            }
        },
    })
}

export const useDeleteTicketMutation = () => {
    const api = useUserApi()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (d: number) => api.deleteUser({ id: d }),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: userKeys.lists() })
        },
    })
}

export const useUserNameList = (
    param: GetUserNameListRequest,
    options: StoreUseQueryOptions<UserNameListDto[]> = {
        placeholderData: [],
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()

    return useQuery({
        queryKey: ["userNames", param],
        queryFn: ({ queryKey }) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_key, params] = queryKey
            return userApi.getUserNameList(params as GetUserNameListRequest)
        },
        ...options,
    })
}
/**
 * Kullanıcı bilgisi
 * default olarak 15dk cachede tutuluyor staleTime ile değiştirilebilir
 * @param options
 * @returns
 */
export const useOwnUser = (
    options: StoreUseQueryOptions<UserDTO> = {
        placeholderData: {},
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
        staleTime: 1000 * 60 * 15,
    },
    withMiddleware = true
) => {
    const userApi = useAuthApi(withMiddleware)

    return useQuery({
        queryKey: ["ownUser"],
        queryFn: ({ queryKey }) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_key] = queryKey
            return userApi.getOwnUser()
        },
        ...options,
    })
}

export const useOwnTenantWithFeatures = (
    options: StoreUseQueryOptions<TenantWithFeaturesDto> = {
        placeholderData: {},
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    },
    withMiddleware = true
) => {
    const userApi = useAuthApi(withMiddleware)

    return useQuery({
        queryKey: ["ownUserCustomerWithFeatures"],
        queryFn: ({ queryKey }) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_key] = queryKey
            return userApi.getOwnTenantWithFeatures()
        },
        ...options,
    })
}
