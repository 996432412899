import * as Sentry from "@sentry/react"
import React, { ReactNode, useEffect } from "react"
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { loginRawNoCheck, useAuthStore } from "stores/AuthStore"

interface RequireAuthProps {
    children: ReactNode
    redirect?: boolean
}

const RequireAuth: React.FC<RequireAuthProps> = function RequireAuth({ children, redirect = true }) {
    const auth = useAuthStore((state) => state.auth)
    let location = useLocation()
    const navigate = useNavigate()

    let [searchParams] = useSearchParams()

    useEffect(() => {
        let to = "/relogin"
        if (location.pathname.includes("app") || location.pathname === "/") to = "/login"
        try {
            if (searchParams.get("token")) {
                Sentry.addBreadcrumb({
                    category: "auth",
                    message: "login with token query param",
                    level: "info",
                })
                loginRawNoCheck(searchParams.get("token")!)
            } else if (!auth) {
                Sentry.addBreadcrumb({
                    category: "auth",
                    message: "no login",
                    level: "info",
                })
                navigate({ pathname: to, search: createSearchParams({ from: location.pathname }).toString() })
            }
        } catch (error) {
            Sentry.captureException(error)
            console.log("error From route", error)
            navigate({ pathname: to, search: createSearchParams({ from: location.pathname }).toString() })
        }
    }, [auth, searchParams, navigate, location.pathname])
    if (auth) return <>{children}</>
    else return <></>
}
export default RequireAuth
