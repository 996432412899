/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface KokpitFeatureListDto
 */
export interface KokpitFeatureListDto {
    /**
     * 
     * @type {number}
     * @memberof KokpitFeatureListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof KokpitFeatureListDto
     */
    id?: number;
    /**
     * Ekleyenin Tam İsmi
     * @type {string}
     * @memberof KokpitFeatureListDto
     */
    createdByFullName?: string | null;
    /**
     * Güncelleyenin Tam İsmi
     * @type {string}
     * @memberof KokpitFeatureListDto
     */
    modifiedByFullName?: string | null;
    /**
     * Eklenme Tarihi
     * @type {Date}
     * @memberof KokpitFeatureListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * Güncelleme Tarihi
     * @type {Date}
     * @memberof KokpitFeatureListDto
     */
    modifiedAt?: dayjs.Dayjs;
    /**
     * 
     * @type {string}
     * @memberof KokpitFeatureListDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KokpitFeatureListDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KokpitFeatureListDto
     */
    featureKey?: string | null;
    /**
     * 
     * @type {Array<KokpitFeatureListDto>}
     * @memberof KokpitFeatureListDto
     */
    dependencies?: Array<KokpitFeatureListDto> | null;
}

/**
 * Check if a given object implements the KokpitFeatureListDto interface.
 */
export function instanceOfKokpitFeatureListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KokpitFeatureListDtoFromJSON(json: any): KokpitFeatureListDto {
    return KokpitFeatureListDtoFromJSONTyped(json, false);
}

export function KokpitFeatureListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KokpitFeatureListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdByFullName': !exists(json, 'createdByFullName') ? undefined : json['createdByFullName'],
        'modifiedByFullName': !exists(json, 'modifiedByFullName') ? undefined : json['modifiedByFullName'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'featureKey': !exists(json, 'featureKey') ? undefined : json['featureKey'],
        'dependencies': !exists(json, 'dependencies') ? undefined : (json['dependencies'] === null ? null : (json['dependencies'] as Array<any>).map(KokpitFeatureListDtoFromJSON)),
    };
}

export function KokpitFeatureListDtoToJSON(value?: KokpitFeatureListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'id': value.id,
        'createdByFullName': value.createdByFullName,
        'modifiedByFullName': value.modifiedByFullName,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'name': value.name,
        'description': value.description,
        'featureKey': value.featureKey,
        'dependencies': value.dependencies === undefined ? undefined : (value.dependencies === null ? null : (value.dependencies as Array<any>).map(KokpitFeatureListDtoToJSON)),
    };
}

