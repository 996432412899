/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationDevice {
    Unkown = 'Unkown',
    Mobile = 'Mobile',
    Desktop = 'Desktop'
}


export function NotificationDeviceFromJSON(json: any): NotificationDevice {
    return NotificationDeviceFromJSONTyped(json, false);
}

export function NotificationDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDevice {
    return json as NotificationDevice;
}

export function NotificationDeviceToJSON(value?: NotificationDevice | null): any {
    return value as any;
}

