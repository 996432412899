/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { KokpitFeatureListDto } from './KokpitFeatureListDto';
import {
    KokpitFeatureListDtoFromJSON,
    KokpitFeatureListDtoFromJSONTyped,
    KokpitFeatureListDtoToJSON,
} from './KokpitFeatureListDto';

/**
 * 
 * @export
 * @interface KokpitFeatureDto
 */
export interface KokpitFeatureDto {
    /**
     * 
     * @type {number}
     * @memberof KokpitFeatureDto
     */
    tenantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KokpitFeatureDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KokpitFeatureDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KokpitFeatureDto
     */
    featureKey?: string | null;
    /**
     * 
     * @type {Array<KokpitFeatureListDto>}
     * @memberof KokpitFeatureDto
     */
    dependencies?: Array<KokpitFeatureListDto> | null;
}

/**
 * Check if a given object implements the KokpitFeatureDto interface.
 */
export function instanceOfKokpitFeatureDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KokpitFeatureDtoFromJSON(json: any): KokpitFeatureDto {
    return KokpitFeatureDtoFromJSONTyped(json, false);
}

export function KokpitFeatureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KokpitFeatureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'featureKey': !exists(json, 'featureKey') ? undefined : json['featureKey'],
        'dependencies': !exists(json, 'dependencies') ? undefined : (json['dependencies'] === null ? null : (json['dependencies'] as Array<any>).map(KokpitFeatureListDtoFromJSON)),
    };
}

export function KokpitFeatureDtoToJSON(value?: KokpitFeatureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'name': value.name,
        'description': value.description,
        'featureKey': value.featureKey,
        'dependencies': value.dependencies === undefined ? undefined : (value.dependencies === null ? null : (value.dependencies as Array<any>).map(KokpitFeatureListDtoToJSON)),
    };
}

