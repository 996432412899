import AppContent from "components/AppContent"
import TicketForm from "../ticket/TicketForm"

const TicketCard = () => {

    return (
        <AppContent maxWidth="md">
            <TicketForm />
        </AppContent>
    )
}
export default TicketCard
