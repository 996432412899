import { ErrorBoundaryRoute } from "ErrorBoundary"
import Spinner from "components/Spinner/Spinner"
import RequireAuth from "components/router/RequireAuth"
import authRoutes from "modules/auth/routes"
import baseRoutes from "modules/base/routes"
import notificationRoutes from "modules/notification/routes"
import ticketRoutes from "modules/ticketSystem/routes"
import { lazy, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { logout, useAuthStore } from "stores/AuthStore"
import DirectLogin from "views/auth/LoginRouter"
import i18n from "./i18n"
import { RouteExtension } from "./routeExtension"
import { RoutesGroupEnum } from "./types/RoutesGroupEnum"

const AppLayout = lazy(() => import("components/layout/LayoutHorizontal" /* webpackChunkName: 'BasePages' */))
const MainContainer = lazy(() => import("containers/MainContainer" /* webpackChunkName: 'BasePages' */))
const ReloadPage = lazy(() => import("containers/ReloadPage" /* webpackChunkName: 'BasePages' */))
const IndexPage = lazy(() => import("views/IndexPage" /* webpackChunkName: 'BasePages' */))
const AuthLayout = lazy(() => import("views/auth/AuthLayout" /* webpackChunkName: 'BasePages' */))
const UserRequestPassword = lazy(
    () => import("views/auth/userForgotPassword/UserRequestPassword" /* webpackChunkName: 'BasePages' */)
)
const UserResetPassword = lazy(
    () => import("views/auth/userForgotPassword/UserResetPassword" /* webpackChunkName: 'BasePages' */)
)
const Login = lazy(() => import("views/auth/Login" /* webpackChunkName: 'BasePages' */))
const Relogin = lazy(() => import("views/auth/Relogin" /* webpackChunkName: 'BasePages' */))
const Page404 = lazy(() => import("views/Page404" /* webpackChunkName: 'BasePages' */))
const Home = lazy(() => import("views/home/Home" /* webpackChunkName: 'BasePages' */))

const Logout = () => {
    logout(false)
    const navigate = useNavigate()
    navigate("/login")
    return <Spinner></Spinner>
}

export const getPath = (path: string) => {
    if (window.location.href.includes("/app")) {
        return "/app/" + path
    } else {
        return "/" + path
    }
}

const Root = () => {
    const auth = useAuthStore((state) => state.auth)

    const navigate = useNavigate()
    useEffect(() => {
        if (!auth) navigate("/login")
        /*if (import.meta.env.NODE_ENV === "development")*/ else navigate("/app/home")
    }, [auth, navigate])

    return <Spinner></Spinner>
}

export const internalRoutes: RouteExtension[] = [
    ...authRoutes,
    ...notificationRoutes,
    ...ticketRoutes,
    ...baseRoutes,

    {
        element: <ReloadPage />,
        path: "force",
        group: RoutesGroupEnum.Login,
        title: i18n.t("route:pageName.force"),
        description: i18n.t("route:description.force"),
    },
    {
        element: <AuthLayout />,
        children: [
            {
                element: <Login />,
                path: "Login",
            },
            {
                path: "userRequestPassword",

                element: <UserRequestPassword />,
            },
            {
                path: "userResetPassword",

                element: <UserResetPassword />,
            },
        ],
        group: RoutesGroupEnum.Login,
        title: i18n.t("common.signIn"),
        description: i18n.t("route:description.login"),
    },
    {
        path: "logout",
        group: RoutesGroupEnum.Login,
        title: i18n.t("route:pageName.logout"),
        description: i18n.t("route:description.logout"),
        element: <Logout />,
    },
    {
        element: <Relogin />,
        path: "relogin",
        group: RoutesGroupEnum.Login,
        title: i18n.t("common.reSignIn"),
        description: i18n.t("route:description.relogin"),
    },
    {
        path: "index",
        element: (
            <RequireAuth>
                <IndexPage />
            </RequireAuth>
        ),
        group: RoutesGroupEnum.Page,
        title: i18n.t("route:pageName.debug"),
        description: i18n.t("route:description.index"),
    },
    {
        path: "home",
        group: RoutesGroupEnum.Home,
        title: i18n.t("route:pageName.home"),
        description: i18n.t("route:description.home"),
        element: (
            <RequireAuth>
                <Home />
            </RequireAuth>
        ),
    },
    {
        path: "directLogin",
        group: RoutesGroupEnum.Home,
        title: i18n.t("route:pageName.home"),
        description: i18n.t("route:description.home"),
        element: (
            <RequireAuth>
                <DirectLogin />
            </RequireAuth>
        ),
    },
    {
        index: true,
        group: RoutesGroupEnum.Home,
        title: i18n.t("route:pageName.home"),
        description: i18n.t("route:description.home"),
        element: (
            <RequireAuth>
                <Home />
            </RequireAuth>
        ),
    },

    {
        element: <Page404 />,
        path: "*",
        group: RoutesGroupEnum.Login,
        title: i18n.t("route:pageName.404"),
        description: i18n.t("route:description.404"),
    },
]

const routes: RouteExtension[] = [

    {
        index: true,
        element: <Root />,
        errorElement: <Page404 />,
        group: RoutesGroupEnum.Login,
        title: i18n.t("route:pageName.login"),
        description: i18n.t("route:description.login"),
    },
    {
        title: i18n.t("route:pageName.index"),
        element: <MainContainer />,
        errorElement: <ErrorBoundaryRoute />,
        children: internalRoutes,
        path: "/",
    },
    {
        title: i18n.t("route:pageName.index"),
        element: <AppLayout />,
        errorElement: <ErrorBoundaryRoute />,
        children: internalRoutes,
        path: "/app",
    },
]

export default routes
