/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface UserStatusCountDto
 */
export interface UserStatusCountDto {
    /**
     * 
     * @type {number}
     * @memberof UserStatusCountDto
     */
    active?: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatusCountDto
     */
    passive?: number;
}

/**
 * Check if a given object implements the UserStatusCountDto interface.
 */
export function instanceOfUserStatusCountDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserStatusCountDtoFromJSON(json: any): UserStatusCountDto {
    return UserStatusCountDtoFromJSONTyped(json, false);
}

export function UserStatusCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusCountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'passive': !exists(json, 'passive') ? undefined : json['passive'],
    };
}

export function UserStatusCountDtoToJSON(value?: UserStatusCountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'passive': value.passive,
    };
}

