import { Button, ButtonProps, Tooltip } from "@mui/material"
import { useHasPermission } from "permissions/useHasPermission"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface IButtonWithPermissionProps extends ButtonProps {
    disabled?: boolean
    label?: string
    permission: string
    children: string
    component?: any
}
const ButtonWithPermission: React.FC<IButtonWithPermissionProps> = (props) => {
    const { t } = useTranslation()
    const { disabled = false, children, permission, component, ...other } = props
    const { result: hasPermission } = useHasPermission(permission)
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        if (!hasPermission) {
            setOpen(true)
        }
    }
    return (
        <Tooltip
            title={hasPermission ? children! : t("common.permission")}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
        >
            <span>
                {/* tooltip buton disabled olunca çalışmıyor */}
                <Button variant="contained" disabled={!hasPermission || disabled} component={component} {...other}>
                    {children}
                </Button>
            </span>
        </Tooltip>
    )
}

export default ButtonWithPermission
