import * as Sentry from "@sentry/browser"
import i18n from "../i18n"

import { Dayjs, updateLocale } from "utils/dayjs"
import { create } from "zustand"

interface WhitelabelConfig {
    name: string
    id: number
    logo?: string | null
    lastUpdateDate: Dayjs
    domain?: string | null
}

type UiStore = {
    theme: "light" | "dark"
    lng: string
    Whitelabel?: WhitelabelConfig
}

export const useUiStore = create<UiStore>(() => {
    let initialMode = "light"
    initialMode = localStorage.getItem("theme") || initialMode
    updateLocale()
    let whitelabelStr = localStorage.getItem("whitelabel")
    if (whitelabelStr) {
        try {
            let whitelabel = JSON.parse(whitelabelStr)
            Sentry.setTag("whitelabel", whitelabel.domain)
            return {
                theme: initialMode as "light" | "dark",
                lng: "tr",
                Whitelabel: {
                    name: whitelabel.name,
                    id: whitelabel.id,
                    logo: whitelabel.logo,
                    lastUpdateDate: whitelabel.lastUpdateDate,
                    domain: whitelabel.domain,
                },
            }
        } catch {
            // do nothing
        }
    }
    return {
        theme: initialMode as "light" | "dark",
        lng: "tr",
    }
})

export const setWhitelabel = (dto: WhitelabelConfig) =>
    useUiStore.setState((state) => {
        localStorage.setItem("whitelabel", JSON.stringify(dto))
        Sentry.setTag("whitelabel", dto.domain)
        if (state.Whitelabel?.id === dto.id && state.Whitelabel?.lastUpdateDate >= dto.lastUpdateDate) {
            return {}
        }
        return {
            Whitelabel: {
                name: dto.name,
                id: dto.id,
                logo: dto.logo,
                lastUpdateDate: dto.lastUpdateDate,
                domain: dto.domain,
            },
        }
    })

export const setLanguage = (lng: string) =>
    useUiStore.setState((state) => {
        if (state.lng !== lng) {
            updateLocale()
            i18n.changeLanguage(lng, (err, t) => {
                updateLocale()
                if (err) return console.error(err, "i18n changeLanguage")
            })
        }

        Sentry.setTag("page_locale", lng)
        return {
            lng: lng,
        }
    })

export const setTheme = (mode: "light" | "dark") =>
    useUiStore.setState((state) => {
        Sentry.setTag("theme", mode)
        localStorage.setItem("theme", mode)
        return {
            theme: mode,
        }
    })
