/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ScheduledNotificationStatusEnum {
    Waiting = 'Waiting',
    Send = 'Send',
    Cancel = 'Cancel'
}


export function ScheduledNotificationStatusEnumFromJSON(json: any): ScheduledNotificationStatusEnum {
    return ScheduledNotificationStatusEnumFromJSONTyped(json, false);
}

export function ScheduledNotificationStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledNotificationStatusEnum {
    return json as ScheduledNotificationStatusEnum;
}

export function ScheduledNotificationStatusEnumToJSON(value?: ScheduledNotificationStatusEnum | null): any {
    return value as any;
}

