/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotifierTypeEnum {
    Notification = 'Notification',
    Sms = 'SMS',
    Email = 'Email'
}


export function NotifierTypeEnumFromJSON(json: any): NotifierTypeEnum {
    return NotifierTypeEnumFromJSONTyped(json, false);
}

export function NotifierTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifierTypeEnum {
    return json as NotifierTypeEnum;
}

export function NotifierTypeEnumToJSON(value?: NotifierTypeEnum | null): any {
    return value as any;
}

