/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { KokpitEnvironmentListDto } from './KokpitEnvironmentListDto';
import {
    KokpitEnvironmentListDtoFromJSON,
    KokpitEnvironmentListDtoFromJSONTyped,
    KokpitEnvironmentListDtoToJSON,
} from './KokpitEnvironmentListDto';

/**
 * 
 * @export
 * @interface KokpitEnvironmentListDtoOffsetPaginationResult
 */
export interface KokpitEnvironmentListDtoOffsetPaginationResult {
    /**
     * 
     * @type {Array<KokpitEnvironmentListDto>}
     * @memberof KokpitEnvironmentListDtoOffsetPaginationResult
     */
    data?: Array<KokpitEnvironmentListDto> | null;
    /**
     * 
     * @type {number}
     * @memberof KokpitEnvironmentListDtoOffsetPaginationResult
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KokpitEnvironmentListDtoOffsetPaginationResult
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof KokpitEnvironmentListDtoOffsetPaginationResult
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof KokpitEnvironmentListDtoOffsetPaginationResult
     */
    pageCount?: number;
}

/**
 * Check if a given object implements the KokpitEnvironmentListDtoOffsetPaginationResult interface.
 */
export function instanceOfKokpitEnvironmentListDtoOffsetPaginationResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KokpitEnvironmentListDtoOffsetPaginationResultFromJSON(json: any): KokpitEnvironmentListDtoOffsetPaginationResult {
    return KokpitEnvironmentListDtoOffsetPaginationResultFromJSONTyped(json, false);
}

export function KokpitEnvironmentListDtoOffsetPaginationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KokpitEnvironmentListDtoOffsetPaginationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(KokpitEnvironmentListDtoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
    };
}

export function KokpitEnvironmentListDtoOffsetPaginationResultToJSON(value?: KokpitEnvironmentListDtoOffsetPaginationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(KokpitEnvironmentListDtoToJSON)),
        'totalCount': value.totalCount,
        'pageSize': value.pageSize,
        'page': value.page,
        'pageCount': value.pageCount,
    };
}

