/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  OwnUserPasswordUpdateDto,
  OwnUserSettingsUpdateDto,
  PermissionDto,
  TokenLogListDtoOffsetPaginationResult,
  UploadResultDTO,
  UserAddDto,
  UserListDto,
  UserNameListDto,
  UserRequestPasswordDto,
  UserResetPasswordDto,
  UserStatus,
  UserStatusCountDto,
  UserUpdateDto,
  ValidationProblemDetails,
} from '../models';
import {
    OwnUserPasswordUpdateDtoFromJSON,
    OwnUserPasswordUpdateDtoToJSON,
    OwnUserSettingsUpdateDtoFromJSON,
    OwnUserSettingsUpdateDtoToJSON,
    PermissionDtoFromJSON,
    PermissionDtoToJSON,
    TokenLogListDtoOffsetPaginationResultFromJSON,
    TokenLogListDtoOffsetPaginationResultToJSON,
    UploadResultDTOFromJSON,
    UploadResultDTOToJSON,
    UserAddDtoFromJSON,
    UserAddDtoToJSON,
    UserListDtoFromJSON,
    UserListDtoToJSON,
    UserNameListDtoFromJSON,
    UserNameListDtoToJSON,
    UserRequestPasswordDtoFromJSON,
    UserRequestPasswordDtoToJSON,
    UserResetPasswordDtoFromJSON,
    UserResetPasswordDtoToJSON,
    UserStatusFromJSON,
    UserStatusToJSON,
    UserStatusCountDtoFromJSON,
    UserStatusCountDtoToJSON,
    UserUpdateDtoFromJSON,
    UserUpdateDtoToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface BlockUserRequest {
    id: number;
}

export interface DeleteUserRequest {
    id: number;
}

export interface GetIsGrantedRequest {
    id: number;
    permission: string;
}

export interface GetOwnTokenLogsRequest {
    userId?: number;
    pageSize?: number;
    page?: number;
}

export interface GetRoleNamesRequest {
    tenantId?: number;
}

export interface GetUserRequest {
    id: number;
}

export interface GetUserListRequest {
    tenantId?: number;
    userStatus?: UserStatus;
}

export interface GetUserNameListRequest {
    customerId?: number;
    driverless?: boolean;
    personnelless?: boolean;
    hasTalepKarsilama?: boolean;
}

export interface GetUserStatusCountRequest {
    customerId?: number;
}

export interface PostForgotPasswordRequest {
    userRequestPasswordDto?: UserRequestPasswordDto;
}

export interface PostResetPasswordWithPinRequest {
    userResetPasswordDto?: UserResetPasswordDto;
}

export interface PostUserRequest {
    userAddDto?: UserAddDto;
}

export interface PutOwnPasswordRequest {
    ownUserPasswordUpdateDto?: OwnUserPasswordUpdateDto;
}

export interface PutOwnUserSettingsRequest {
    ownUserSettingsUpdateDto?: OwnUserSettingsUpdateDto;
}

export interface PutUserRequest {
    id: number;
    userUpdateDto?: UserUpdateDto;
}

export interface SendUserPasswordRequest {
    id: number;
}

export interface UpdateUserAvatarRequest {
    id: number;
    file?: Blob;
}

export interface UploadUserPictureRequest {
    id?: number;
    file?: Blob;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * 
     * @summary User Bloke Etme (Login AuthType: UserUpdateOthers)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    blockUserRaw(requestParameters: BlockUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>>;

    /**
     * User Bloke Etme (Login AuthType: UserUpdateOthers)
     */
    blockUser(requestParameters: BlockUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto>;

    /**
     * 
     * @summary User silme (Login AuthType: UserDeleteOthers)
     * @param {number} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>>;

    /**
     * User silme (Login AuthType: UserDeleteOthers)
     */
    deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto>;

    /**
     * 
     * @summary Kullanıcının verilen yetkisi var mı kontrol edet (Login)
     * @param {number} id User id
     * @param {string} permission Kontrol edilecek yetki
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getIsGrantedRaw(requestParameters: GetIsGrantedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDto>>;

    /**
     * Kullanıcının verilen yetkisi var mı kontrol edet (Login)
     */
    getIsGranted(requestParameters: GetIsGrantedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDto>;

    /**
     * 
     * @summary TokenLog Detay (\"\")  <param name=\"userId\"></param><param name=\"pageSize\"></param> (Login AuthType: UserList)
     * @param {number} [userId] 
     * @param {number} [pageSize] 
     * @param {number} [page] The page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getOwnTokenLogsRaw(requestParameters: GetOwnTokenLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenLogListDtoOffsetPaginationResult>>;

    /**
     * TokenLog Detay (\"\")  <param name=\"userId\"></param><param name=\"pageSize\"></param> (Login AuthType: UserList)
     */
    getOwnTokenLogs(requestParameters: GetOwnTokenLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenLogListDtoOffsetPaginationResult>;

    /**
     * 
     * @summary User Detay (\"\") (Login AuthType: UserGet)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getOwnUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>>;

    /**
     * User Detay (\"\") (Login AuthType: UserGet)
     */
    getOwnUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto>;

    /**
     * 
     * @summary Rollerin isimlerini Listeleme (\"\") (Login AuthType: UserGet)
     * @param {number} [tenantId] Girilmezse tokendaki customerId alınıyor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getRoleNamesRaw(requestParameters: GetRoleNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     * Rollerin isimlerini Listeleme (\"\") (Login AuthType: UserGet)
     */
    getRoleNames(requestParameters: GetRoleNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>>;

    /**
     * 
     * @summary User Detay (\"\") (Login AuthType: UserGet)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>>;

    /**
     * User Detay (\"\") (Login AuthType: UserGet)
     */
    getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto>;

    /**
     * 
     * @summary User Listeleme (\"\") (Login AuthType: UserList)
     * @param {number} [tenantId] Girilmezse tokendaki customerId alınıyor
     * @param {UserStatus} [userStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserListRaw(requestParameters: GetUserListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserListDto>>>;

    /**
     * User Listeleme (\"\") (Login AuthType: UserList)
     */
    getUserList(requestParameters: GetUserListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserListDto>>;

    /**
     * 
     * @summary User Name Listeleme (\"UserNameList\") (Login AuthType: UserGet)
     * @param {number} [customerId] Girilmezse tokendaki customerId alınıyor
     * @param {boolean} [driverless] Sürücülü sürücüsüz
     * @param {boolean} [personnelless] Sürücülü sürücüsüz
     * @param {boolean} [hasTalepKarsilama] Talep Karsilama biti var/yok
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserNameListRaw(requestParameters: GetUserNameListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserNameListDto>>>;

    /**
     * User Name Listeleme (\"UserNameList\") (Login AuthType: UserGet)
     */
    getUserNameList(requestParameters: GetUserNameListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserNameListDto>>;

    /**
     * 
     * @summary User status sayısı (\"\") (Login AuthType: UserGet)
     * @param {number} [customerId] Girilmezse tokendaki customerId alınıyor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserStatusCountRaw(requestParameters: GetUserStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserStatusCountDto>>;

    /**
     * User status sayısı (\"\") (Login AuthType: UserGet)
     */
    getUserStatusCount(requestParameters: GetUserStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserStatusCountDto>;

    /**
     * 
     * @summary Şifresini unutan kullanıcının mail hesabına doğrulama kodu atar.
     * @param {UserRequestPasswordDto} [userRequestPasswordDto] dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postForgotPasswordRaw(requestParameters: PostForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Şifresini unutan kullanıcının mail hesabına doğrulama kodu atar.
     */
    postForgotPassword(requestParameters: PostForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary User şifre resetleme
     * @param {UserResetPasswordDto} [userResetPasswordDto] dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postResetPasswordWithPinRaw(requestParameters: PostResetPasswordWithPinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * User şifre resetleme
     */
    postResetPasswordWithPin(requestParameters: PostResetPasswordWithPinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary User Ekleme (Login AuthType: UserAdd)
     * @param {UserAddDto} [userAddDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserRaw(requestParameters: PostUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>>;

    /**
     * User Ekleme (Login AuthType: UserAdd)
     */
    postUser(requestParameters: PostUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto>;

    /**
     * 
     * @summary User kendi parolasını Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     * @param {OwnUserPasswordUpdateDto} [ownUserPasswordUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putOwnPasswordRaw(requestParameters: PutOwnPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * User kendi parolasını Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     */
    putOwnPassword(requestParameters: PutOwnPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary User kendi bilgilerini Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     * @param {OwnUserSettingsUpdateDto} [ownUserSettingsUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putOwnUserSettingsRaw(requestParameters: PutOwnUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * User kendi bilgilerini Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     */
    putOwnUserSettings(requestParameters: PutOwnUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary User Güncelleme (Login AuthType: UserUpdateOthers)
     * @param {number} id 
     * @param {UserUpdateDto} [userUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putUserRaw(requestParameters: PutUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * User Güncelleme (Login AuthType: UserUpdateOthers)
     */
    putUser(requestParameters: PutUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Kullanıcıya şifresini mail atar (Login)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    sendUserPasswordRaw(requestParameters: SendUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Kullanıcıya şifresini mail atar (Login)
     */
    sendUserPassword(requestParameters: SendUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye  tablosuna eklenir
     * @summary User için verilen resimleri ekler (Login AuthType: UserUpdateOthers)
     * @param {number} id sürücü id
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateUserAvatarRaw(requestParameters: UpdateUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResultDTO>>;

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye  tablosuna eklenir
     * User için verilen resimleri ekler (Login AuthType: UserUpdateOthers)
     */
    updateUserAvatar(requestParameters: UpdateUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResultDTO>;

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye user tablosuna eklenir
     * @summary Verilen user için resim ekler (\"UserUpdate\") (Login AuthType: UserUpdateOthers)
     * @param {number} [id] user ID
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    uploadUserPictureRaw(requestParameters: UploadUserPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye user tablosuna eklenir
     * Verilen user için resim ekler (\"UserUpdate\") (Login AuthType: UserUpdateOthers)
     */
    uploadUserPicture(requestParameters: UploadUserPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * User Bloke Etme (Login AuthType: UserUpdateOthers)
     */
    async blockUserRaw(requestParameters: BlockUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling blockUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/{id}/BlockUser`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListDtoFromJSON(jsonValue));
    }

    /**
     * User Bloke Etme (Login AuthType: UserUpdateOthers)
     */
    async blockUser(requestParameters: BlockUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto> {
        const response = await this.blockUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User silme (Login AuthType: UserDeleteOthers)
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListDtoFromJSON(jsonValue));
    }

    /**
     * User silme (Login AuthType: UserDeleteOthers)
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto> {
        const response = await this.deleteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Kullanıcının verilen yetkisi var mı kontrol edet (Login)
     */
    async getIsGrantedRaw(requestParameters: GetIsGrantedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getIsGranted.');
        }

        if (requestParameters.permission === null || requestParameters.permission === undefined) {
            throw new runtime.RequiredError('permission','Required parameter requestParameters.permission was null or undefined when calling getIsGranted.');
        }

        const queryParameters: any = {};

        if (requestParameters.permission !== undefined) {
            queryParameters['permission'] = requestParameters.permission;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/{id}/isGranted`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     * Kullanıcının verilen yetkisi var mı kontrol edet (Login)
     */
    async getIsGranted(requestParameters: GetIsGrantedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDto> {
        const response = await this.getIsGrantedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * TokenLog Detay (\"\")  <param name=\"userId\"></param><param name=\"pageSize\"></param> (Login AuthType: UserList)
     */
    async getOwnTokenLogsRaw(requestParameters: GetOwnTokenLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenLogListDtoOffsetPaginationResult>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/GetOwnTokenLog`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenLogListDtoOffsetPaginationResultFromJSON(jsonValue));
    }

    /**
     * TokenLog Detay (\"\")  <param name=\"userId\"></param><param name=\"pageSize\"></param> (Login AuthType: UserList)
     */
    async getOwnTokenLogs(requestParameters: GetOwnTokenLogsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenLogListDtoOffsetPaginationResult> {
        const response = await this.getOwnTokenLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User Detay (\"\") (Login AuthType: UserGet)
     */
    async getOwnUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/GetOwnUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListDtoFromJSON(jsonValue));
    }

    /**
     * User Detay (\"\") (Login AuthType: UserGet)
     */
    async getOwnUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto> {
        const response = await this.getOwnUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Rollerin isimlerini Listeleme (\"\") (Login AuthType: UserGet)
     */
    async getRoleNamesRaw(requestParameters: GetRoleNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['TenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/RoleNames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Rollerin isimlerini Listeleme (\"\") (Login AuthType: UserGet)
     */
    async getRoleNames(requestParameters: GetRoleNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getRoleNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User Detay (\"\") (Login AuthType: UserGet)
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListDtoFromJSON(jsonValue));
    }

    /**
     * User Detay (\"\") (Login AuthType: UserGet)
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User Listeleme (\"\") (Login AuthType: UserList)
     */
    async getUserListRaw(requestParameters: GetUserListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['TenantId'] = requestParameters.tenantId;
        }

        if (requestParameters.userStatus !== undefined) {
            queryParameters['UserStatus'] = requestParameters.userStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserListDtoFromJSON));
    }

    /**
     * User Listeleme (\"\") (Login AuthType: UserList)
     */
    async getUserList(requestParameters: GetUserListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserListDto>> {
        const response = await this.getUserListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User Name Listeleme (\"UserNameList\") (Login AuthType: UserGet)
     */
    async getUserNameListRaw(requestParameters: GetUserNameListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserNameListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.driverless !== undefined) {
            queryParameters['driverless'] = requestParameters.driverless;
        }

        if (requestParameters.personnelless !== undefined) {
            queryParameters['personnelless'] = requestParameters.personnelless;
        }

        if (requestParameters.hasTalepKarsilama !== undefined) {
            queryParameters['hasTalepKarsilama'] = requestParameters.hasTalepKarsilama;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/UserNameList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserNameListDtoFromJSON));
    }

    /**
     * User Name Listeleme (\"UserNameList\") (Login AuthType: UserGet)
     */
    async getUserNameList(requestParameters: GetUserNameListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserNameListDto>> {
        const response = await this.getUserNameListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User status sayısı (\"\") (Login AuthType: UserGet)
     */
    async getUserStatusCountRaw(requestParameters: GetUserStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserStatusCountDto>> {
        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/UserStatusCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserStatusCountDtoFromJSON(jsonValue));
    }

    /**
     * User status sayısı (\"\") (Login AuthType: UserGet)
     */
    async getUserStatusCount(requestParameters: GetUserStatusCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserStatusCountDto> {
        const response = await this.getUserStatusCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Şifresini unutan kullanıcının mail hesabına doğrulama kodu atar.
     */
    async postForgotPasswordRaw(requestParameters: PostForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/ForgotPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRequestPasswordDtoToJSON(requestParameters.userRequestPasswordDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Şifresini unutan kullanıcının mail hesabına doğrulama kodu atar.
     */
    async postForgotPassword(requestParameters: PostForgotPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postForgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * User şifre resetleme
     */
    async postResetPasswordWithPinRaw(requestParameters: PostResetPasswordWithPinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/ResetPasswordWithPin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserResetPasswordDtoToJSON(requestParameters.userResetPasswordDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User şifre resetleme
     */
    async postResetPasswordWithPin(requestParameters: PostResetPasswordWithPinRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postResetPasswordWithPinRaw(requestParameters, initOverrides);
    }

    /**
     * User Ekleme (Login AuthType: UserAdd)
     */
    async postUserRaw(requestParameters: PostUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAddDtoToJSON(requestParameters.userAddDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListDtoFromJSON(jsonValue));
    }

    /**
     * User Ekleme (Login AuthType: UserAdd)
     */
    async postUser(requestParameters: PostUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDto> {
        const response = await this.postUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User kendi parolasını Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     */
    async putOwnPasswordRaw(requestParameters: PutOwnPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/OwnPassword`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OwnUserPasswordUpdateDtoToJSON(requestParameters.ownUserPasswordUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User kendi parolasını Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     */
    async putOwnPassword(requestParameters: PutOwnPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putOwnPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * User kendi bilgilerini Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     */
    async putOwnUserSettingsRaw(requestParameters: PutOwnUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OwnUserSettingsUpdateDtoToJSON(requestParameters.ownUserSettingsUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User kendi bilgilerini Güncelleme (\"\") (Login AuthType: UserUpdateOthers)
     */
    async putOwnUserSettings(requestParameters: PutOwnUserSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putOwnUserSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * User Güncelleme (Login AuthType: UserUpdateOthers)
     */
    async putUserRaw(requestParameters: PutUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateDtoToJSON(requestParameters.userUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User Güncelleme (Login AuthType: UserUpdateOthers)
     */
    async putUser(requestParameters: PutUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putUserRaw(requestParameters, initOverrides);
    }

    /**
     * Kullanıcıya şifresini mail atar (Login)
     */
    async sendUserPasswordRaw(requestParameters: SendUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendUserPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/auth/User/{id}/SendUserPassword`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Kullanıcıya şifresini mail atar (Login)
     */
    async sendUserPassword(requestParameters: SendUserPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendUserPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye  tablosuna eklenir
     * User için verilen resimleri ekler (Login AuthType: UserUpdateOthers)
     */
    async updateUserAvatarRaw(requestParameters: UpdateUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResultDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserAvatar.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/auth/User/{id}/Avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResultDTOFromJSON(jsonValue));
    }

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye  tablosuna eklenir
     * User için verilen resimleri ekler (Login AuthType: UserUpdateOthers)
     */
    async updateUserAvatar(requestParameters: UpdateUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResultDTO> {
        const response = await this.updateUserAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye user tablosuna eklenir
     * Verilen user için resim ekler (\"UserUpdate\") (Login AuthType: UserUpdateOthers)
     */
    async uploadUserPictureRaw(requestParameters: UploadUserPictureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/auth/User/uploadPicture`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verilen dosya sunucu dizinine kayıt edilir. Dosyanın relative pathı DB ye user tablosuna eklenir
     * Verilen user için resim ekler (\"UserUpdate\") (Login AuthType: UserUpdateOthers)
     */
    async uploadUserPicture(requestParameters: UploadUserPictureRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadUserPictureRaw(requestParameters, initOverrides);
    }

}


export function useUserApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new UserApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new UserApi(apiConfig["ETL-API"])
}
