import RequireAuth from "components/router/RequireAuth"
import i18n from "i18n"
import { lazy } from "react"
import { RouteExtension } from "routeExtension"
import { RoutesGroupEnum } from "types/RoutesGroupEnum"
import TicketCard from "./views/ticketCard/TicketCard"


const Ticket = lazy(() => import("./views/ticket/Ticket" /* webpackChunkName: 'Ticket' */))
const TicketBoard = lazy(() => import("./views/ticketBoard/TicketBoard" /* webpackChunkName: 'Ticket' */))

const ticketRoutes: RouteExtension[] = [
    {
        group: RoutesGroupEnum.TicketSystem,
        title: i18n.t("route:pageName.ticket"),
        breadcrumbDisable: true,
        children: [
            {
                path: "ticket",
                group: RoutesGroupEnum.TicketSystem,
                title: i18n.t("route:pageName.ticket"),
                description: i18n.t("route:description.ticket"),
                element: (
                    <RequireAuth>
                        <Ticket />
                    </RequireAuth>
                ),

            },
            {
                path: "ticketBoard",
                group: RoutesGroupEnum.TicketSystem,
                title: i18n.t("route:pageName.ticketBoard"),
                description: i18n.t("route:description.ticketBoard"),
                element: (
                    <RequireAuth>
                        <TicketBoard />
                    </RequireAuth>
                ),

            },
            {
                path: "ticketCard",
                group: RoutesGroupEnum.TicketSystem,
                title: i18n.t("route:pageName.ticketCard"),
                description: i18n.t("route:description.ticketCard"),
                element: (
                    <RequireAuth>
                        <TicketCard />
                    </RequireAuth>
                ),

            },

        ],
    },
]
export default ticketRoutes
