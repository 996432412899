/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { KokpitFeatureListDto } from './KokpitFeatureListDto';
import {
    KokpitFeatureListDtoFromJSON,
    KokpitFeatureListDtoFromJSONTyped,
    KokpitFeatureListDtoToJSON,
} from './KokpitFeatureListDto';

/**
 * 
 * @export
 * @interface KokpitPackageDto
 */
export interface KokpitPackageDto {
    /**
     * 
     * @type {number}
     * @memberof KokpitPackageDto
     */
    tenantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KokpitPackageDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KokpitPackageDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<KokpitFeatureListDto>}
     * @memberof KokpitPackageDto
     */
    features?: Array<KokpitFeatureListDto> | null;
}

/**
 * Check if a given object implements the KokpitPackageDto interface.
 */
export function instanceOfKokpitPackageDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KokpitPackageDtoFromJSON(json: any): KokpitPackageDto {
    return KokpitPackageDtoFromJSONTyped(json, false);
}

export function KokpitPackageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KokpitPackageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'features': !exists(json, 'features') ? undefined : (json['features'] === null ? null : (json['features'] as Array<any>).map(KokpitFeatureListDtoFromJSON)),
    };
}

export function KokpitPackageDtoToJSON(value?: KokpitPackageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'name': value.name,
        'description': value.description,
        'features': value.features === undefined ? undefined : (value.features === null ? null : (value.features as Array<any>).map(KokpitFeatureListDtoToJSON)),
    };
}

