/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface NameDTO
 */
export interface NameDTO {
    /**
     * 
     * @type {number}
     * @memberof NameDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NameDTO
     */
    name?: string | null;
}

/**
 * Check if a given object implements the NameDTO interface.
 */
export function instanceOfNameDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NameDTOFromJSON(json: any): NameDTO {
    return NameDTOFromJSONTyped(json, false);
}

export function NameDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function NameDTOToJSON(value?: NameDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}

