import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"

interface Iprops {
    delay?: number
    height?: string | number
}

const Spinner = (props: Iprops) => {
    const { delay = 500, height } = props

    const [display, setDisplay] = useState(false)

    const enableMessage = () => {
        setDisplay(true)
    }

    useEffect(() => {
        const timer = setTimeout(enableMessage, delay)

        return () => {
            clearTimeout(timer)
        }
    }, [delay])

    return display ? (
        <div
            style={{
                height: height || "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <CircularProgress sx={(theme) => ({ color: theme.palette.primary.main })} />
        </div>
    ) : null
}

export default Spinner
