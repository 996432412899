/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface TicketStatusCountDto
 */
export interface TicketStatusCountDto {
    /**
     * 
     * @type {number}
     * @memberof TicketStatusCountDto
     */
    waiting?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketStatusCountDto
     */
    accepted?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketStatusCountDto
     */
    declined?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketStatusCountDto
     */
    working?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketStatusCountDto
     */
    completed?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketStatusCountDto
     */
    closed?: number;
}

/**
 * Check if a given object implements the TicketStatusCountDto interface.
 */
export function instanceOfTicketStatusCountDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TicketStatusCountDtoFromJSON(json: any): TicketStatusCountDto {
    return TicketStatusCountDtoFromJSONTyped(json, false);
}

export function TicketStatusCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketStatusCountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'waiting': !exists(json, 'waiting') ? undefined : json['waiting'],
        'accepted': !exists(json, 'accepted') ? undefined : json['accepted'],
        'declined': !exists(json, 'declined') ? undefined : json['declined'],
        'working': !exists(json, 'working') ? undefined : json['working'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
    };
}

export function TicketStatusCountDtoToJSON(value?: TicketStatusCountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'waiting': value.waiting,
        'accepted': value.accepted,
        'declined': value.declined,
        'working': value.working,
        'completed': value.completed,
        'closed': value.closed,
    };
}

