import { Grid } from "@mui/material"
import { ContentSaveOutline } from "mdi-material-ui"
import { useTranslation } from "react-i18next"
import ButtonWithPermission from "./ButtonWithPermission"

interface ISubmitButtonProps {
    disabled?: boolean
    label?: string
    permissions: string
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
}
const SubmitButton = (props: ISubmitButtonProps) => {
    const { t } = useTranslation()
    const {
        disabled = false,
        label = t("common.submit"),
        permissions,
        startIcon = <ContentSaveOutline />,
        endIcon,
    } = props

    return (
        <Grid item>
            <ButtonWithPermission
                permission={permissions}
                variant="contained"
                color="primary"
                type="submit"
                disabled={disabled}
                startIcon={startIcon}
                endIcon={endIcon}
            >
                {label!}
            </ButtonWithPermission>
        </Grid>
    )
}

export default SubmitButton
