/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { NotificationGroupTypeEnum } from './NotificationGroupTypeEnum';
import {
    NotificationGroupTypeEnumFromJSON,
    NotificationGroupTypeEnumFromJSONTyped,
    NotificationGroupTypeEnumToJSON,
} from './NotificationGroupTypeEnum';
import type { NotifierTypeEnum } from './NotifierTypeEnum';
import {
    NotifierTypeEnumFromJSON,
    NotifierTypeEnumFromJSONTyped,
    NotifierTypeEnumToJSON,
} from './NotifierTypeEnum';
import type { ScheduledNotificationStatusEnum } from './ScheduledNotificationStatusEnum';
import {
    ScheduledNotificationStatusEnumFromJSON,
    ScheduledNotificationStatusEnumFromJSONTyped,
    ScheduledNotificationStatusEnumToJSON,
} from './ScheduledNotificationStatusEnum';

/**
 * 
 * @export
 * @interface ScheduledNotificationListDto
 */
export interface ScheduledNotificationListDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    createdById?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    modifiedById?: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledNotificationListDto
     */
    createdAt?: dayjs.Dayjs;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledNotificationListDto
     */
    modifiedAt?: dayjs.Dayjs;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledNotificationListDto
     */
    sendDate?: dayjs.Dayjs;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    personelId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    driverId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationListDto
     */
    sendAllPersonel?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationListDto
     */
    sendAllUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledNotificationListDto
     */
    sendAllDriver?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    personelDepartmentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationListDto
     */
    unitGroupId?: number | null;
    /**
     * 
     * @type {Array<NotifierTypeEnum>}
     * @memberof ScheduledNotificationListDto
     */
    notifierTypeEnums?: Array<NotifierTypeEnum> | null;
    /**
     * 
     * @type {NotificationGroupTypeEnum}
     * @memberof ScheduledNotificationListDto
     */
    groupType?: NotificationGroupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    notifierTypes?: string | null;
    /**
     * 
     * @type {ScheduledNotificationStatusEnum}
     * @memberof ScheduledNotificationListDto
     */
    status?: ScheduledNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    unitPlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    unitType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledNotificationListDto
     */
    unitLocationLastLocationDate?: dayjs.Dayjs | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    personelResim?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    personelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    personelPersonelDepartmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    userFullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    userImg?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    unitGroupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    personelDepartmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    createdByFullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledNotificationListDto
     */
    modifiedByFullName?: string | null;
}

/**
 * Check if a given object implements the ScheduledNotificationListDto interface.
 */
export function instanceOfScheduledNotificationListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScheduledNotificationListDtoFromJSON(json: any): ScheduledNotificationListDto {
    return ScheduledNotificationListDtoFromJSONTyped(json, false);
}

export function ScheduledNotificationListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledNotificationListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'modifiedById': !exists(json, 'modifiedById') ? undefined : json['modifiedById'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (dayjs(json['createdAt'])),

        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (dayjs(json['modifiedAt'])),

        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sendDate': !exists(json, 'sendDate') ? undefined : (dayjs(json['sendDate'])),

        'personelId': !exists(json, 'personelId') ? undefined : json['personelId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'sendAllPersonel': !exists(json, 'sendAllPersonel') ? undefined : json['sendAllPersonel'],
        'sendAllUser': !exists(json, 'sendAllUser') ? undefined : json['sendAllUser'],
        'sendAllDriver': !exists(json, 'sendAllDriver') ? undefined : json['sendAllDriver'],
        'personelDepartmentId': !exists(json, 'personelDepartmentId') ? undefined : json['personelDepartmentId'],
        'unitGroupId': !exists(json, 'unitGroupId') ? undefined : json['unitGroupId'],
        'notifierTypeEnums': !exists(json, 'notifierTypeEnums') ? undefined : (json['notifierTypeEnums'] === null ? null : (json['notifierTypeEnums'] as Array<any>).map(NotifierTypeEnumFromJSON)),
        'groupType': !exists(json, 'groupType') ? undefined : NotificationGroupTypeEnumFromJSON(json['groupType']),
        'notifierTypes': !exists(json, 'notifierTypes') ? undefined : json['notifierTypes'],
        'status': !exists(json, 'status') ? undefined : ScheduledNotificationStatusEnumFromJSON(json['status']),
        'unitPlate': !exists(json, 'unitPlate') ? undefined : json['unitPlate'],
        'unitType': !exists(json, 'unitType') ? undefined : json['unitType'],
        'unitLocationLastLocationDate': !exists(json, 'unitLocationLastLocationDate') ? undefined : (json['unitLocationLastLocationDate'] === null ? null : dayjs(json['unitLocationLastLocationDate'])),

        'personelResim': !exists(json, 'personelResim') ? undefined : json['personelResim'],
        'personelName': !exists(json, 'personelName') ? undefined : json['personelName'],
        'personelPersonelDepartmentName': !exists(json, 'personelPersonelDepartmentName') ? undefined : json['personelPersonelDepartmentName'],
        'userFullName': !exists(json, 'userFullName') ? undefined : json['userFullName'],
        'userImg': !exists(json, 'userImg') ? undefined : json['userImg'],
        'unitGroupName': !exists(json, 'unitGroupName') ? undefined : json['unitGroupName'],
        'personelDepartmentName': !exists(json, 'personelDepartmentName') ? undefined : json['personelDepartmentName'],
        'createdByFullName': !exists(json, 'createdByFullName') ? undefined : json['createdByFullName'],
        'modifiedByFullName': !exists(json, 'modifiedByFullName') ? undefined : json['modifiedByFullName'],
    };
}

export function ScheduledNotificationListDtoToJSON(value?: ScheduledNotificationListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'createdById': value.createdById,
        'modifiedById': value.modifiedById,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'title': value.title,
        'description': value.description,
        'sendDate': value.sendDate === undefined ? undefined : (value.sendDate.toISOString()),
        'personelId': value.personelId,
        'userId': value.userId,
        'driverId': value.driverId,
        'sendAllPersonel': value.sendAllPersonel,
        'sendAllUser': value.sendAllUser,
        'sendAllDriver': value.sendAllDriver,
        'personelDepartmentId': value.personelDepartmentId,
        'unitGroupId': value.unitGroupId,
        'notifierTypeEnums': value.notifierTypeEnums === undefined ? undefined : (value.notifierTypeEnums === null ? null : (value.notifierTypeEnums as Array<any>).map(NotifierTypeEnumToJSON)),
        'groupType': NotificationGroupTypeEnumToJSON(value.groupType),
        'notifierTypes': value.notifierTypes,
        'status': ScheduledNotificationStatusEnumToJSON(value.status),
        'unitPlate': value.unitPlate,
        'unitType': value.unitType,
        'unitLocationLastLocationDate': value.unitLocationLastLocationDate === undefined ? undefined : (value.unitLocationLastLocationDate === null ? null : value.unitLocationLastLocationDate.toISOString()),
        'personelResim': value.personelResim,
        'personelName': value.personelName,
        'personelPersonelDepartmentName': value.personelPersonelDepartmentName,
        'userFullName': value.userFullName,
        'userImg': value.userImg,
        'unitGroupName': value.unitGroupName,
        'personelDepartmentName': value.personelDepartmentName,
        'createdByFullName': value.createdByFullName,
        'modifiedByFullName': value.modifiedByFullName,
    };
}

