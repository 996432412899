import { Skeleton, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

interface AuditDetailsProps {
    data: any
    isLoading?: boolean
}
const AuditDetails = (props: AuditDetailsProps) => {
    const { t } = useTranslation()
    const { data, isLoading = false } = props

    return (
        <Typography color="text.secondary" variant="subtitle2">
            {isLoading ? (
                <Skeleton variant="text" />
            ) : (
                data && (
                    <>
                        <b>{t("common.lastUpdate") + ": "}</b>
                        <span style={{ alignItems: "right" }}>
                            {data.modifiedByFullName +
                                " " +
                                data.modifiedAt?.format("L LT") +
                                " (" +
                                data.modifiedAt?.fromNow() +
                                ")"}
                        </span>
                    </>
                )
            )}
        </Typography>
    )
}

export default AuditDetails
