/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import dayjs from "utils/dayjs";
import { apiConfig, apiMiddleware } from "stores/ApiStore";
import type {
  CustomerDto,
  CustomerListDto,
  CustomerStatus,
  CustomerStatusCountDto,
  CustomerUpdateDto,
  NameDTO,
  UserListDto,
  ValidationProblemDetails,
} from '../models';
import {
    CustomerDtoFromJSON,
    CustomerDtoToJSON,
    CustomerListDtoFromJSON,
    CustomerListDtoToJSON,
    CustomerStatusFromJSON,
    CustomerStatusToJSON,
    CustomerStatusCountDtoFromJSON,
    CustomerStatusCountDtoToJSON,
    CustomerUpdateDtoFromJSON,
    CustomerUpdateDtoToJSON,
    NameDTOFromJSON,
    NameDTOToJSON,
    UserListDtoFromJSON,
    UserListDtoToJSON,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface AssignRepresentativesRequest {
    id?: number;
    representativeIds?: Array<number>;
}

export interface GetCustomerRequest {
    id: number;
}

export interface GetCustomerNamesRequest {
    status?: CustomerStatus;
    tenantId?: number;
}

export interface GetCustomerRepresentativesRequest {
    id?: number;
}

export interface GetCustomerStatusCountRequest {
    tenantId?: number;
}

export interface GetCustomersRequest {
    status?: CustomerStatus;
    tenantId?: number;
    kokpitEnvironmentId?: number;
    customerChannelId?: number;
}

export interface PostCustomerRequest {
    customerDto?: CustomerDto;
}

export interface PutCustomerRequest {
    id: number;
    customerUpdateDto?: CustomerUpdateDto;
}

export interface UnassignRepresentativeRequest {
    id?: number;
    representativeId?: number;
}

/**
 * CustomerApi - interface
 * 
 * @export
 * @interface CustomerApiInterface
 */
export interface CustomerApiInterface {
    /**
     * 
     * @summary Müşteriye temsilci ataması yapar. (Login AuthType: CustomerUpdate)
     * @param {number} [id] 
     * @param {Array<number>} [representativeIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    assignRepresentativesRaw(requestParameters: AssignRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Müşteriye temsilci ataması yapar. (Login AuthType: CustomerUpdate)
     */
    assignRepresentatives(requestParameters: AssignRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Customer Detay (Login AuthType: CustomerGet)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    getCustomerRaw(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerListDto>>;

    /**
     * Customer Detay (Login AuthType: CustomerGet)
     */
    getCustomer(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerListDto>;

    /**
     * 
     * @summary Customer isimlerini Listeleme (Login)
     * @param {CustomerStatus} [status] Durum
     * @param {number} [tenantId] opsiyonel alt müşteri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    getCustomerNamesRaw(requestParameters: GetCustomerNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>>;

    /**
     * Customer isimlerini Listeleme (Login)
     */
    getCustomerNames(requestParameters: GetCustomerNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>>;

    /**
     * 
     * @summary Customer Temsilcilerini Listeleme (Login AuthType: CustomerList)
     * @param {number} [id] CustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    getCustomerRepresentativesRaw(requestParameters: GetCustomerRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserListDto>>>;

    /**
     * Customer Temsilcilerini Listeleme (Login AuthType: CustomerList)
     */
    getCustomerRepresentatives(requestParameters: GetCustomerRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserListDto>>;

    /**
     * 
     * @summary Customer Status Count (Login AuthType: CustomerGet)
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    getCustomerStatusCountRaw(requestParameters: GetCustomerStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerStatusCountDto>>;

    /**
     * Customer Status Count (Login AuthType: CustomerGet)
     */
    getCustomerStatusCount(requestParameters: GetCustomerStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerStatusCountDto>;

    /**
     * 
     * @summary Customer Listeleme (Login AuthType: CustomerList)
     * @param {CustomerStatus} [status] Durum
     * @param {number} [tenantId] Tenant Id
     * @param {number} [kokpitEnvironmentId] Ortam Id
     * @param {number} [customerChannelId] Müşteri Kanalı Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    getCustomersRaw(requestParameters: GetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerListDto>>>;

    /**
     * Customer Listeleme (Login AuthType: CustomerList)
     */
    getCustomers(requestParameters: GetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerListDto>>;

    /**
     * 
     * @summary Customer Ekleme (Login AuthType: CustomerAdd)
     * @param {CustomerDto} [customerDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    postCustomerRaw(requestParameters: PostCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerListDto>>;

    /**
     * Customer Ekleme (Login AuthType: CustomerAdd)
     */
    postCustomer(requestParameters: PostCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerListDto>;

    /**
     * 
     * @summary Customer güncelleme (Login AuthType: CustomerUpdate)
     * @param {number} id 
     * @param {CustomerUpdateDto} [customerUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    putCustomerRaw(requestParameters: PutCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Customer güncelleme (Login AuthType: CustomerUpdate)
     */
    putCustomer(requestParameters: PutCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Müşteriye yapılan temsilci atamasını kaldırır (Login AuthType: CustomerUpdate)
     * @param {number} [id] 
     * @param {number} [representativeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApiInterface
     */
    unassignRepresentativeRaw(requestParameters: UnassignRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Müşteriye yapılan temsilci atamasını kaldırır (Login AuthType: CustomerUpdate)
     */
    unassignRepresentative(requestParameters: UnassignRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class CustomerApi extends runtime.BaseAPI implements CustomerApiInterface {

    /**
     * Müşteriye temsilci ataması yapar. (Login AuthType: CustomerUpdate)
     */
    async assignRepresentativesRaw(requestParameters: AssignRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.representativeIds) {
            queryParameters['representativeIds'] = requestParameters.representativeIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer/AssignRepresentatives`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Müşteriye temsilci ataması yapar. (Login AuthType: CustomerUpdate)
     */
    async assignRepresentatives(requestParameters: AssignRepresentativesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignRepresentativesRaw(requestParameters, initOverrides);
    }

    /**
     * Customer Detay (Login AuthType: CustomerGet)
     */
    async getCustomerRaw(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerListDtoFromJSON(jsonValue));
    }

    /**
     * Customer Detay (Login AuthType: CustomerGet)
     */
    async getCustomer(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerListDto> {
        const response = await this.getCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer isimlerini Listeleme (Login)
     */
    async getCustomerNamesRaw(requestParameters: GetCustomerNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NameDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer/NameList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NameDTOFromJSON));
    }

    /**
     * Customer isimlerini Listeleme (Login)
     */
    async getCustomerNames(requestParameters: GetCustomerNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NameDTO>> {
        const response = await this.getCustomerNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer Temsilcilerini Listeleme (Login AuthType: CustomerList)
     */
    async getCustomerRepresentativesRaw(requestParameters: GetCustomerRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer/CustomerRepresentatives`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserListDtoFromJSON));
    }

    /**
     * Customer Temsilcilerini Listeleme (Login AuthType: CustomerList)
     */
    async getCustomerRepresentatives(requestParameters: GetCustomerRepresentativesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserListDto>> {
        const response = await this.getCustomerRepresentativesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer Status Count (Login AuthType: CustomerGet)
     */
    async getCustomerStatusCountRaw(requestParameters: GetCustomerStatusCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerStatusCountDto>> {
        const queryParameters: any = {};

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer/StatusCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerStatusCountDtoFromJSON(jsonValue));
    }

    /**
     * Customer Status Count (Login AuthType: CustomerGet)
     */
    async getCustomerStatusCount(requestParameters: GetCustomerStatusCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerStatusCountDto> {
        const response = await this.getCustomerStatusCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer Listeleme (Login AuthType: CustomerList)
     */
    async getCustomersRaw(requestParameters: GetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['TenantId'] = requestParameters.tenantId;
        }

        if (requestParameters.kokpitEnvironmentId !== undefined) {
            queryParameters['KokpitEnvironmentId'] = requestParameters.kokpitEnvironmentId;
        }

        if (requestParameters.customerChannelId !== undefined) {
            queryParameters['CustomerChannelId'] = requestParameters.customerChannelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerListDtoFromJSON));
    }

    /**
     * Customer Listeleme (Login AuthType: CustomerList)
     */
    async getCustomers(requestParameters: GetCustomersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerListDto>> {
        const response = await this.getCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer Ekleme (Login AuthType: CustomerAdd)
     */
    async postCustomerRaw(requestParameters: PostCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerDtoToJSON(requestParameters.customerDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerListDtoFromJSON(jsonValue));
    }

    /**
     * Customer Ekleme (Login AuthType: CustomerAdd)
     */
    async postCustomer(requestParameters: PostCustomerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerListDto> {
        const response = await this.postCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer güncelleme (Login AuthType: CustomerUpdate)
     */
    async putCustomerRaw(requestParameters: PutCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerUpdateDtoToJSON(requestParameters.customerUpdateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Customer güncelleme (Login AuthType: CustomerUpdate)
     */
    async putCustomer(requestParameters: PutCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putCustomerRaw(requestParameters, initOverrides);
    }

    /**
     * Müşteriye yapılan temsilci atamasını kaldırır (Login AuthType: CustomerUpdate)
     */
    async unassignRepresentativeRaw(requestParameters: UnassignRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.representativeId !== undefined) {
            queryParameters['representativeId'] = requestParameters.representativeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/base/Customer/UnassignRepresentative`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Müşteriye yapılan temsilci atamasını kaldırır (Login AuthType: CustomerUpdate)
     */
    async unassignRepresentative(requestParameters: UnassignRepresentativeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unassignRepresentativeRaw(requestParameters, initOverrides);
    }

}


export function useCustomerApi(withMiddleware = true) {
    if(withMiddleware)
        //@ts-ignore
        return new CustomerApi(apiConfig["ETL-API"]).withMiddleware(apiMiddleware)
    else
        return new CustomerApi(apiConfig["ETL-API"])
}
