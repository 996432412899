import { Localization as MuiLocalization } from "@mui/material/locale"
import { GridLocaleText } from "@mui/x-data-grid"
// eslint-disable-next-line no-restricted-imports
import { getGridLocalization, Localization } from "@mui/x-data-grid/utils/getGridLocalization"
import { PickersLocaleText } from "@mui/x-date-pickers/locales"
// eslint-disable-next-line no-restricted-imports
import { getPickersLocalization } from "@mui/x-date-pickers/locales/utils/getPickersLocalization"

export const trTRCore: MuiLocalization = {
    components: {
        MuiBreadcrumbs: {
            defaultProps: {
                expandText: "Yolu göster",
            },
        },
        MuiTablePagination: {
            defaultProps: {
                getItemAriaLabel: (type) => {
                    if (type === "first") {
                        return "İlk sayfaya git"
                    }
                    if (type === "last") {
                        return "Son sayfaya git"
                    }
                    if (type === "next") {
                        return "Sonraki sayfaya git"
                    }
                    //if (type === 'previous') {
                    return "Önceki sayfaya git"
                },
                labelRowsPerPage: "Sayfa başına satır:",
                labelDisplayedRows: ({ from, to, count }) =>
                    `${count !== -1 ? count : `${to}+`} tanesinden ${from}–${to}`,
            },
        },
        MuiRating: {
            defaultProps: {
                getLabelText: (value) => `${value} Yıldız`,
                emptyLabelText: "Boş",
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                clearText: "Temizle",
                closeText: "Kapat",
                loadingText: "Yükleniyor…",
                noOptionsText: "Seçenek yok",
                openText: "Aç",
            },
        },
        MuiAlert: {
            defaultProps: {
                closeText: "Kapat",
            },
        },
        MuiPagination: {
            defaultProps: {
                "aria-label": "Sayfa navigasyonu",
                getItemAriaLabel: (type, page, selected) => {
                    if (type === "page") {
                        return `${page}. ${selected ? "sayfa" : "sayfaya git"}`
                    }
                    if (type === "first") {
                        return "İlk sayfaya git"
                    }
                    if (type === "last") {
                        return "Son sayfaya git"
                    }
                    if (type === "next") {
                        return "Sonraki sayfaya git"
                    }
                    // if (type === 'previous') {
                    return "Önceki sayfaya git"
                },
            },
        },
    },
}

// This object is not Partial<PickersLocaleText> because it is the default values
const _trTRPickers: Partial<PickersLocaleText<any>> = {
    // Calendar navigation
    previousMonth: "Önceki ay",
    nextMonth: "Sonraki ay",

    // View navigation
    openPreviousView: "sonraki görünüm",
    openNextView: "önceki görünüm",
    // calendarViewSwitchingButtonAriaLabel: (view: CalendarPickerView) => view === 'year' ? 'year view is open, switch to calendar view' : 'calendar view is open, switch to year view',

    // DateRange placeholders
    start: "Başlangıç",
    end: "Bitiş",

    // Action bar
    cancelButtonLabel: "iptal",
    clearButtonLabel: "Temizle",
    okButtonLabel: "Tamam",
    todayButtonLabel: "Bugün",

    // Clock labels
    // clockLabelText: (view, time, adapter) => `Select ${view}. ${time === null ? 'No time selected' : `Selected time is ${adapter.format(time, 'fullTime')}`}`,
    // hoursClockNumberText: hours => `${hours} hours`,
    // minutesClockNumberText: minutes => `${minutes} minutes`,
    // secondsClockNumberText: seconds => `${seconds} seconds`,

    // Open picker labels
    // openDatePickerDialogue: (rawValue, utils) => rawValue && utils.isValid(utils.date(rawValue)) ? `Choose date, selected date is ${utils.format(utils.date(rawValue)!, 'fullDate')}` : 'Choose date',
    // openTimePickerDialogue: (rawValue, utils) => rawValue && utils.isValid(utils.date(rawValue)) ? `Choose time, selected time is ${utils.format(utils.date(rawValue)!, 'fullTime')}` : 'Choose time',

    // Table labels
    // timeTableLabel: 'pick time',
    // dateTableLabel: 'pick date',
}

export const trTRPickers = getPickersLocalization(_trTRPickers)

const trTRGrid: Partial<GridLocaleText> = {
    // Root
    noRowsLabel: "Satır yok",
    noResultsOverlayLabel: "Sonuç bulunamadı.",

    // Density selector toolbar button text
    toolbarDensity: "Yoğunluk",
    toolbarDensityLabel: "Yoğunluk",
    toolbarDensityCompact: "Sıkı",
    toolbarDensityStandard: "Standart",
    toolbarDensityComfortable: "Rahat",

    // Columns selector toolbar button text
    toolbarColumns: "Sütunlar",
    toolbarColumnsLabel: "Sütun seç",

    // Filters toolbar button text
    toolbarFilters: "Filtreler",
    toolbarFiltersLabel: "Filtreleri göster",
    toolbarFiltersTooltipHide: "Filtreleri gizle",
    toolbarFiltersTooltipShow: "Filtreleri göster",
    toolbarFiltersTooltipActive: (count) => `${count} aktif filtre`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: "Ara…",
    toolbarQuickFilterLabel: "Ara",
    toolbarQuickFilterDeleteIconLabel: "Temizle",

    // Export selector toolbar button text
    toolbarExport: "Dışa aktar",
    toolbarExportLabel: "Dışa aktar",
    toolbarExportCSV: "CSV olarak aktar",
    toolbarExportPrint: "Yazdır",
    toolbarExportExcel: "Excel olarak aktar",

    // Columns management text
    // columnsManagementSearchTitle: 'Search',
    // columnsManagementNoColumns: 'No columns',
    // columnsManagementShowHideAllText: 'Show/Hide All',
    // columnsManagementReset: 'Reset',

    // Filter panel text
    filterPanelAddFilter: "Filtre Ekle",
    filterPanelRemoveAll: "Hepsini kaldır",
    filterPanelDeleteIconLabel: "Kaldır",
    filterPanelLogicOperator: "Mantıksal operatörler",
    filterPanelOperator: "Operatör",
    filterPanelOperatorAnd: "Ve",
    filterPanelOperatorOr: "Veya",
    filterPanelColumns: "Sütunlar",
    filterPanelInputLabel: "Değer",
    filterPanelInputPlaceholder: "Filtre değeri",

    // Filter operators text
    filterOperatorContains: "içerir",
    filterOperatorEquals: "eşittir",
    filterOperatorStartsWith: "ile başlar",
    filterOperatorEndsWith: "ile biter",
    filterOperatorIs: "eşittir",
    filterOperatorNot: "eşit değildir",
    filterOperatorAfter: "büyük",
    filterOperatorOnOrAfter: "büyük eşit",
    filterOperatorBefore: "küçük",
    filterOperatorOnOrBefore: "küçük eşit",
    filterOperatorIsEmpty: "boş",
    filterOperatorIsNotEmpty: "dolu",
    filterOperatorIsAnyOf: "herhangi biri",
    "filterOperator=": "=",
    "filterOperator!=": "!=",
    "filterOperator>": ">",
    "filterOperator>=": ">=",
    "filterOperator<": "<",
    "filterOperator<=": "<=",

    // Header filter operators text
    headerFilterOperatorContains: "Şunu içerir",
    headerFilterOperatorEquals: "Şuna eşittir",
    headerFilterOperatorStartsWith: "Şununla başlar",
    headerFilterOperatorEndsWith: "Şununla biter",
    // headerFilterOperatorIs: 'Is',
    // headerFilterOperatorNot: 'Is not',
    // headerFilterOperatorAfter: 'Is after',
    // headerFilterOperatorOnOrAfter: 'Is on or after',
    // headerFilterOperatorBefore: 'Is before',
    // headerFilterOperatorOnOrBefore: 'Is on or before',
    // headerFilterOperatorIsEmpty: 'Is empty',
    // headerFilterOperatorIsNotEmpty: 'Is not empty',
    // headerFilterOperatorIsAnyOf: 'Is any of',
    // 'headerFilterOperator=': 'Equals',
    // 'headerFilterOperator!=': 'Not equals',
    // 'headerFilterOperator>': 'Greater than',
    // 'headerFilterOperator>=': 'Greater than or equal to',
    // 'headerFilterOperator<': 'Less than',
    // 'headerFilterOperator<=': 'Less than or equal to',

    // Filter values text
    filterValueAny: "herhangi",
    filterValueTrue: "doğru",
    filterValueFalse: "yanlış",

    // Column menu text
    columnMenuLabel: "Menü",
    columnMenuShowColumns: "Sütunları göster",
    columnMenuManageColumns: "Sütunları yönet",
    columnMenuFilter: "Filtre Ekle",
    columnMenuHideColumn: "Gizle",
    columnMenuUnsort: "Varsayılan Sıralama",
    columnMenuSortAsc: "Sırala - Artan",
    columnMenuSortDesc: "Sırala - Azalan",

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count} filtre aktif`,
    columnHeaderFiltersLabel: "Filtreleri göster",
    columnHeaderSortIconLabel: "Sırala",

    // Rows selected footer text
    footerRowSelected: (count) => `${count.toLocaleString()} satır seçildi`,

    // Total row amount footer text
    footerTotalRows: "Toplam Satır:",

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: "Seçim",
    checkboxSelectionSelectAllRows: "Tüm satırları seç",
    checkboxSelectionUnselectAllRows: "Tüm satırların seçimini kaldır",
    checkboxSelectionSelectRow: "Satırı seç",
    checkboxSelectionUnselectRow: "Satır seçimini bırak",

    // Boolean cell text
    booleanCellTrueLabel: "Evet",
    booleanCellFalseLabel: "Hayır",

    // Actions cell more text
    actionsCellMore: "daha fazla",

    // Column pinning text
    pinToLeft: "Sola sabitle",
    pinToRight: "Sağa sabitle",
    unpin: "Sabitlemeyi kaldır",

    // Tree Data
    treeDataGroupingHeaderName: "Grup",
    treeDataExpand: "göster",
    treeDataCollapse: "gizle",

    // Grouping columns
    groupingColumnHeaderName: "Grup",
    groupColumn: (name) => `${name} için grupla`,
    unGroupColumn: (name) => `${name} için gruplamayı kaldır`,

    // Master/detail
    detailPanelToggle: "Detay görünümüne geçiş",
    expandDetailPanel: "Genişlet",
    collapseDetailPanel: "Gizle",

    // Row reordering text
    rowReorderingHeaderName: "Satırı yeniden sırala",

    // Aggregation
    aggregationMenuItemHeader: "Toplama",
    aggregationFunctionLabelSum: "top",
    aggregationFunctionLabelAvg: "ort",
    aggregationFunctionLabelMin: "min",
    aggregationFunctionLabelMax: "maks",
    aggregationFunctionLabelSize: "boyut",
}

export const trTRgrid: Localization = getGridLocalization(trTRGrid, trTRCore)
