/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationGroupTypeEnum {
    Personel = 'Personel',
    User = 'User',
    Driver = 'Driver'
}


export function NotificationGroupTypeEnumFromJSON(json: any): NotificationGroupTypeEnum {
    return NotificationGroupTypeEnumFromJSONTyped(json, false);
}

export function NotificationGroupTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationGroupTypeEnum {
    return json as NotificationGroupTypeEnum;
}

export function NotificationGroupTypeEnumToJSON(value?: NotificationGroupTypeEnum | null): any {
    return value as any;
}

