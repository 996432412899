/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
import type { UserStatus } from './UserStatus';
import {
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './UserStatus';

/**
 * 
 * @export
 * @interface UserUpdateDto
 */
export interface UserUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    oldPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    newPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserUpdateDto
     */
    status?: UserStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateDto
     */
    roleNames?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    tenantId?: number | null;
}

/**
 * Check if a given object implements the UserUpdateDto interface.
 */
export function instanceOfUserUpdateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserUpdateDtoFromJSON(json: any): UserUpdateDto {
    return UserUpdateDtoFromJSONTyped(json, false);
}

export function UserUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'oldPassword': !exists(json, 'oldPassword') ? undefined : json['oldPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'status': !exists(json, 'status') ? undefined : UserStatusFromJSON(json['status']),
        'roleNames': !exists(json, 'roleNames') ? undefined : json['roleNames'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function UserUpdateDtoToJSON(value?: UserUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'oldPassword': value.oldPassword,
        'newPassword': value.newPassword,
        'fullName': value.fullName,
        'status': UserStatusToJSON(value.status),
        'roleNames': value.roleNames,
        'tenantId': value.tenantId,
    };
}

