/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TicketStatus {
    Waiting = 'Waiting',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Working = 'Working',
    Completed = 'Completed',
    Closed = 'Closed'
}


export function TicketStatusFromJSON(json: any): TicketStatus {
    return TicketStatusFromJSONTyped(json, false);
}

export function TicketStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketStatus {
    return json as TicketStatus;
}

export function TicketStatusToJSON(value?: TicketStatus | null): any {
    return value as any;
}

