/* tslint:disable */
/* eslint-disable */
/**
 * ETL-API
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import dayjs from "utils/dayjs";
/**
 * 
 * @export
 * @interface ScheduledNotificationStatusCountDto
 */
export interface ScheduledNotificationStatusCountDto {
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationStatusCountDto
     */
    waiting?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationStatusCountDto
     */
    send?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledNotificationStatusCountDto
     */
    cancel?: number;
}

/**
 * Check if a given object implements the ScheduledNotificationStatusCountDto interface.
 */
export function instanceOfScheduledNotificationStatusCountDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScheduledNotificationStatusCountDtoFromJSON(json: any): ScheduledNotificationStatusCountDto {
    return ScheduledNotificationStatusCountDtoFromJSONTyped(json, false);
}

export function ScheduledNotificationStatusCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledNotificationStatusCountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'waiting': !exists(json, 'waiting') ? undefined : json['waiting'],
        'send': !exists(json, 'send') ? undefined : json['send'],
        'cancel': !exists(json, 'cancel') ? undefined : json['cancel'],
    };
}

export function ScheduledNotificationStatusCountDtoToJSON(value?: ScheduledNotificationStatusCountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'waiting': value.waiting,
        'send': value.send,
        'cancel': value.cancel,
    };
}

